<template>
      <s3-layer 
        class="layerBox"
        v-model="visibleBox" 
        :title="title" 
        area="350px" 
        btnAlign="c"
        :btn="['保存数据', '取消编辑']"
        @yes="btnYes"
        @btn2="btnCancel"
    >
        
        <div class="w-full  p-2">
            <div class="text-ms text-right text-slate-400" v-show="show.tipAccount">
                {{ msg.tipAccount }}
            </div>
            <div class="text-ms text-right text-red-700" v-show="show.errAccount">
                {{ msg.errAccount }}
            </div>
            <div class="flex">
                <button class="w-[150px] text-right z-10  px-4 py-1 text-ms text-zinc-700 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    帐号
                </button>
                <input
                    name="title"
                    type="text"
                    class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border-zinc-300 dark:border-zinc-300 dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-400 dark:text-zinc-700"
                    :placeholder="account"
                    v-model="account"
                    disabled
                />
            </div>
        </div>

        <div class="w-full  p-2">
            <div class="text-ms text-right text-slate-400" v-show="show.tipPosition">
                {{ msg.tipPosition }}
            </div>
            <div class="text-ms text-right text-red-700" v-show="show.errPosition">
                {{ msg.errPosition }}
            </div>
            <div class="flex">
                <button class="w-[150px] text-right z-10  px-4 py-1 text-ms text-zinc-700 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    职务
                </button>
                <label for="states" class="sr-only">全部状态</label>
                <select id="states" v-model="role" class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border-zinc-300 dark:border-zinc-300 dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-400 dark:text-zinc-700">
                    <option value="" selected disabled hidden>{{ $t('filed.admin.position') }}</option>
                    <option v-for="(value, key) in positions" v-bind:key="key" :value="value.position_id"  class="text-ms">
                        {{  value.title   }} 
                    </option>
                </select>
            </div>
        </div>

    </s3-layer>
</template>

<script>
import axios from 'axios'
import { useI18n } from 'vue-i18n'
import { layer } from "vue3-layer"

import getAllPositions from '@/assets/js/getAllPositions'
import { toRaw } from '@vue/reactivity'

export default {
    data() {
        return {
            lang: '',
            title: '编辑管理帐号',
            visibleBox: false,
            id: 0,
            account: '',
            positions: [],
            role: 0,
            show: {
                tipAccount: true,
                errAccount: false,
                tipPosition: true,
                errPosition: false,
            },
            msg: {
                tipAccount: '要编辑的管理帐号不可以修改',
                errAccount: '',
                tipPosition: '请选择要切换的职务',
                errPosition: '',
            }
        }
    },
    inject: ["fatherGetData"],
    mounted() {
        const { locale } = useI18n()
        this.lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-cn'
        locale.value = this.lang
        return {}
    },
    methods: {
        switchShow(id, account, role) {
            this.visibleBox = true
            this.id = id
            this.account = account
            this.role = role

            const mAPI = getAllPositions(this.lang, localStorage.getItem('token'))
            // 将异步获取的数据导入 VM 数据
            mAPI.data.then(res =>{
                
                const list = toRaw(res)
                // let oJson = JSON.parse(JSON.stringify(res))
                this.positions = list.data.position
            })
        },
        btnYes()
        {
            this.deoxidationTip()
            axios.post(
                '/api/x/set/admin/position', 
                {
                    id: this.id,
                    position_id: this.role
                },
                {
                    headers: {
                        // 'Content-Type': 'multipart/form-data'
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': this.lang,
                        'token': localStorage.getItem('token')
                    }
                }
            )
            .then((response) =>  {
                // console.log(response)
                if (0===response.data.code) {
                    this.visibleBox = false
                    layer.msg(response.data.msg)
                    // 创建成功，可能还要创建，所以先清空。
                    this.deoxidation()
                    // 创建成功，父组件要重新获取数据。
                    this.fatherGetData()
                } else {
                    const word = response.data.data.id
                    const err = 'msg.err' + word.charAt(0).toUpperCase() + word.slice(1)
                    const tip = 'show.err' + word.charAt(0).toUpperCase() + word.slice(1)
                    // 转成国际化 后端转提示语 前端转表单名称
                    this[err] = this.$t('filed.position.'+response.data.data.id) + ' ' + response.data.msg 
                    this[tip] = true
                }
            })
            .catch(function (error) {
                console.log(error)
            })
        },
        btnCancel()
        {
            this.visibleBox = false
        },
        // 提交后还原提交的表单信息
        deoxidation() {
            this.id = 0
            this.account = ''
            this.positions = []
            this.role = 0
        },
        // 提前时清空错误提示
        deoxidationTip() {
            this.show.tipAccount = true
            this.show.errAccount = false
            this.show.tipPosition = true
            this.show.errPosition = false
            this.msg.errAccount = ''
            this.msg.errPosition = ''
        }
    }
}
</script>

<style>

</style>