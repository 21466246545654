<template>
    <!--  Main -->
    <div class="w-screen h-screen flex">

        <!-- Side bar 侧边栏 -->
        <!-- 侧边栏 w-[200px] 总固定宽度 200 px  -->
        <SideSar ref="refSideSar" />
        <!-- /End Side bar -->

        <!-- Main -->
        <div :class="css.main">

            <!-- Main Header -->
            <MainHeader ref="refMainHeader" />
            <!--  /End Main Header -->

            <div class="h-[calc(100vh-80px)] bg-white z-0">

                <!-- Row -->
                <div class="w-full h-[40px] bg-current">
                    <div class="grid grid-cols-2 gap-0 pb-2">
                        <!-- Row Left -->
                        <div class="left text-left">

                        </div>
                        <!-- Row Right -->
                        <div class="right text-right">
                            
                        </div>
                    </div>
                </div><!-- /End Row -->


                <!-- search -->
                <div class="w-full text-left mt-4 mx-4 z-0">
                    <!-- search date -->
                    <div class="w-[200px] inline-block p-2">
                        <div class="flex">
                            <button id="states-button"  class="flex-shrink-0 z-0 inline-flex items-center border-r-0 bg-zinc-200 dark:bg-zinc-200 px-4 py-1 text-ms font-medium text-center text-zinc-700 bg-transparent border border-zinc-300 dark:border-zinc-300  hover:bg-transparent  dark:bg-transparent dark:hover:bg-transparent  dark:text-zinc-700 dark:border-gray-600" type="button">
                                下单时间 
                            </button>
                            <vue-tailwind-datepicker :formatter="formatter" v-model="dateValue" i18n="zh-cn"/>
                        </div>
                    </div><!-- /End search date -->

                    <div class="w-[200px] inline-block p-2">
                        <div class="flex">
                            <button id="states-button"  class="flex-shrink-0 z-10 inline-flex items-center border-r-0 bg-zinc-200 dark:bg-zinc-200 px-4 py-1 text-ms font-medium text-center text-zinc-700 bg-transparent border border-zinc-300 dark:border-zinc-300  hover:bg-transparent  dark:bg-transparent dark:hover:bg-transparent  dark:text-zinc-700 dark:border-gray-600" type="button">
                                用户名
                            </button>
                            <input
                                name="title"
                                type="text"
                                class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border-zinc-300 dark:border-zinc-300 dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-400 dark:text-zinc-700"
                                placeholder=""
                                v-model="username"
                            />
                        </div>
                    </div>

                    <div class="w-[200px] inline-block p-2">
                        <div class="flex">
                            <button id="states-button"  class="flex-shrink-0 z-10 inline-flex items-center border-r-0 bg-zinc-200 dark:bg-zinc-200 px-4 py-1 text-ms font-medium text-center text-zinc-700 bg-transparent border border-zinc-300 dark:border-zinc-300  hover:bg-transparent  dark:bg-transparent dark:hover:bg-transparent  dark:text-zinc-700 dark:border-gray-600" type="button">
                                订单号
                            </button>
                            <input
                                name="title"
                                type="text"
                                class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border-zinc-300 dark:border-zinc-300 dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-400 dark:text-zinc-700"
                                placeholder=""
                                v-model="number"
                            />
                        </div>
                    </div>

                    <div class="w-[200px] inline-block p-2">
                        <div class="flex">
                            <button id="states-button"  class="flex-shrink-0 z-10 inline-flex items-center border-r-0 bg-zinc-200 dark:bg-zinc-200 px-4 py-1 text-ms font-medium text-center text-zinc-700 bg-transparent border border-zinc-300 dark:border-zinc-300  hover:bg-transparent  dark:bg-transparent dark:hover:bg-transparent  dark:text-zinc-700 dark:border-gray-600" type="button">
                                状态 
                            </button>
                            <select v-model="states"  class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border-zinc-300 dark:border-zinc-300 dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-400 dark:text-zinc-700">
                                <option value="" selected>全部状态</option>
                                <option value="0">待支付</option>
                                <option value="1">支付成功</option>
                                <option value="2">支付失败</option>
                            </select>
                        </div>
                    </div>

                    <div class="w-[200px] inline-block p-2">
                        <div class="flex">
                            <button @click="search"  class="flex-shrink-0 z-0 inline-flex items-center  bg-zinc-200 dark:bg-zinc-200 px-4 py-1 text-ms font-medium text-center text-zinc-700 bg-transparent border border-zinc-300 dark:border-zinc-300  hover:bg-transparent  dark:bg-transparent dark:hover:bg-transparent  dark:text-zinc-700 dark:border-gray-600" type="button">
                                搜索
                            </button>
                        </div>
                    </div>
                </div><!-- /End search -->

                <!-- 数据 -->
                <div class="relative overflow-x-auto h-[calc(100vh-230px)] overflow-y-scroll p-2 mx-4">
                    <table class="text-ms w-screen text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr class="table-fixed whitespace-nowrap bg-[#f2f2f2] text-[#666] border border-[#e6e6e6] dark:border-[#e6e6e6]">
                                <th class="px-6 py-3 border-r border-[#e6e6e6]">
                                    订单号
                                </th>
                                <th class="px-6 py-3 border-r border-[#e6e6e6]">
                                    用户名
                                </th>
                                <th class="px-6 py-3 border-r border-[#e6e6e6]">
                                    商品名称
                                </th>
                                <th class="px-6 py-3 border-r border-[#e6e6e6]">
                                    今天单次
                                </th>
                                <th class="px-6 py-3 border-r border-[#e6e6e6]">
                                    当前余额
                                </th>
                                <th class="px-6 py-3 border-r border-[#e6e6e6]">
                                    商品价格
                                </th>
                                <th class="px-6 py-3 border-r border-[#e6e6e6]">
                                    佣金
                                </th>
                                <th class="px-6 py-3 border-r border-[#e6e6e6]">
                                    下单时间
                                </th>
                                <th class="px-6 py-3 border-r border-[#e6e6e6]">
                                    付款时间
                                </th>
                                <th class="px-6 py-3 border-r border-[#e6e6e6]">
                                    处理时间
                                </th>
                                <th class="px-6 py-3 border-r border-[#e6e6e6]">
                                    操作人
                                </th>
                                <th class="px-6 py-3 border-r border-[#e6e6e6]">
                                    状态
                                </th>
                                <th class="px-6 py-3 text-right ">
                                    管理操作
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in pageData" :key="item.orders_id" class="bg-white border border-[#e6e6e6] dark:border-[#e6e6e6] text-stone-600  whitespace-nowrap dark:text-stone-600 dark:bg-white hover:bg-[#f2f2f2] dark:hover:bg-[#f2f2f2]">
                                <td class="px-6 py-4 border-r border-[#e6e6e6] ">
                                    {{ item.number }}
                                </td>
                                <td class="px-6 py-4 border-r border-[#e6e6e6]">
                                    {{ item.user['username'] }}
                                </td>
                                <td class="px-6 py-4 border-r border-[#e6e6e6]">
                                    商品名称
                                </td>
                                <td class="px-6 py-4 border-r border-[#e6e6e6]">
                                    {{ item.completed }}
                                </td>
                                <td class="px-6 py-4 border-r border-[#e6e6e6]">
                                    {{ item.balance }}
                                </td>
                                <td class="px-6 py-4 border-r border-[#e6e6e6]">
                                    {{ item.amount }}
                                </td>
                                <td class="px-6 py-4 border-r border-[#e6e6e6]">
                                    {{ item.commission }}
                                </td>
                                <td class="px-6 py-4 border-r border-[#e6e6e6]">
                                    {{ date(item.created_at) }} &nbsp; {{ time(item.created_at) }}
                                </td>
                                <td class="px-6 py-4 border-r border-[#e6e6e6]">
                                    <span v-show="item.pay_at">
                                        {{ date(item.updated_at) }} &nbsp; {{ time(item.updated_at) }}
                                    </span>
                                </td>
                                <td class="px-6 py-4 border-r border-[#e6e6e6]">
                                    <span v-show="item.updated_at">
                                    {{ date(item.updated_at) }} &nbsp; {{ time(item.updated_at) }}
                                    </span>
                                </td>
                                <td class="px-6 py-4 border-r border-[#e6e6e6]">
                                    <span v-if="item.admin_id">
                                        {{ item.admin['account'] }}
                                    </span>
                                </td>
                                <td class="px-6 py-4 border-r border-[#e6e6e6]">
                                    <span v-show="0===item.status" class="text-[#fb1c19]">待派单</span>
                                    <span v-show="1===item.status" class="text-rose-700">已取消</span>
                                    <span v-show="2===item.status" class="text-rose-700">待付款</span>
                                    <span v-show="3===item.status"  class="text-green-500">订单冻结</span>
                                </td>
                                <td class="flex py-4  px-6  float-right">
                                    <span v-show="0===item.status">
                                        <a v-show="!item.status && item.butDesignate" href="#" @click="designate(item.orders_id, item.user_id)" :class="item.status ? css.butCss  : css.butCss">
                                            立即派单
                                        </a>
                                        <a v-show="!item.status && item.butCancel" href="#" @click="orderCancel(item.orders_id, index)" :class="item.status ? css.butCss2  : css.butCss2">
                                            取消订单
                                        </a>
                                    </span>

                                    <span v-show="2===item.status">
                                        <a v-show="2==item.status && item.butPay" href="#" @click="pay(item.orders_id, item.user_id, index)" :class="item.status ? css.butCss  : css.butCss">
                                            强制付款
                                        </a>
                                        <a v-show="2==item.status && item.butCancel" href="#" @click="orderCancel(item.orders_id, index)" :class="item.status ? css.butCss2  : css.butCss2">
                                            取消订单
                                        </a>
                                    </span>

                                    <span v-show="3===item.status">
                                        <a v-show="3==item.status && item.butThaw" href="#" @click="thaw(item.orders_id, index)" :class="item.status ? css.butCss  : css.butCss">
                                            手动解冻
                                        </a>
                                    </span>

                                    
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div><!-- /End 数据-->

                <!-- Row 分页条 -->
                <div class="grid grid-cols-2 gap-0 pb-2 text-ms py-2 px-8">
                    <!-- Row Left -->
                    <div class="left text-left">
                        <span>{{ pageInfo(pageTotal, ps, p) }}</span>
                    </div>
                    <!-- Row Right -->
                    <div class="right text-right" v-show="showPageBtn">
                        <button @click="p=backPage(p);getData();">上一页</button>
                        <button
                            class="px-1"
                                v-for="item in pageLinks"
                                :key="item"
                                @click="() => {p=item;getData();}"
                            >
                            {{ item }}
                        </button>
                        <button @click="p=nextPage(p,pt);getData();">下一页</button>
                    </div>
                </div><!-- /End Row 分页条 -->


                <CreateBank ref="refCreateBank" />
                <EditAmount ref="refEditAmount" />
                <EditUser ref="refEditUser" />
                <EditBankCard ref="refEditBankCard" />
                <EditPass ref="refEditPass" />
            </div>

            <!-- <div class="h-[100px] p-10"></div> -->

            <!-- Main Footer -->
            <MainFooter />
            <!--  /End Main Footer -->

        </div>
    
    </div><!-- /End Main -->
</template>

<script>
import axios from 'axios'
import { toRaw } from '@vue/reactivity'
import { layer } from "vue3-layer"
import SideSar from "@/components/SideSar.vue"
import MainHeader from "@/components/MainHeader.vue"
import MainFooter from "@/components/MainFooter.vue"
import CreateBank from '@/components/CreateBank.vue'
import EditAmount from '@/components/EditAmount.vue'
import EditUser from '@/components/EditUser.vue'
import EditPass from '@/components/EditPass.vue'
import EditBankCard from '@/components/EditBankCard.vue'


// page.a
import handlePagination from "@/assets/js/handlePagination"
import postPages from "@/assets/js/postPages"
import format from '@/assets/js/formatDate'

// 日期选择件
// code.admin/node_modules/vue-tailwind-datepicker/dist/vue-tailwind-datepicker.js 编辑 删除掉 py-2.5 rounded-lg 加上 py-1 outline-none dark:outline-none
// 删除 focus:ring focus:ring-vtd-primary-500 focus:ring-opacity-10 dark:focus:ring-opacity-20
import { ref } from "vue"
import VueTailwindDatepicker from "vue-tailwind-datepicker"
const dateValue = ref([])
const formatter = ref({
  date: "YYYY-MM-DD",
  month: "MMM",
})


export default {
    components: {
        SideSar,
        MainHeader,
        MainFooter,
        layer,
        CreateBank,
        VueTailwindDatepicker,
        EditAmount,
        EditUser,
        EditPass,
        EditBankCard,
    },
    setup() {
        // page.b
        const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-cn'
        const handlePaginationValue = handlePagination()
        const formatFunc = format()
        return { ...handlePaginationValue, ...formatFunc, lang }
    },
    data() {
        return {
            showDropDown: false,
            showSideBar: true,
            visibleBox: false,
            // page.c
            showPageBtn: false,
            pageData: [],
            pageTotal: 0,
            ps: 15,
            p: 1, // 当前请求第几页的数据
            pt: 0, // 总页数
            pageLinks: [],
            
            // 数据搜索条件
            dateValue,
            formatter,
            start: '',
            end: '',
            username: '',
            states: '',
            phone: '',
            number: '',
            searchParams: '',

            banks: [],
            css: {
                main: 'h-full w-[calc(100vw-200px)] bg-gray-400',
                butCss: ' bg-[#1a9589] hover:bg-[#1a9589] px-2 rounded-sm transition-all text-white  inline-block text-xs p-1 font-semibold relative before:absolute before:bg-gradient-to-tr before:from-yellow-600 before:via-transparent before:to-green-700 before:inset-0 before:scale-110 before:rounded before:blur-md before:-z-10 ms-3',
                butCss2: ' bg-[#fdb938] hover:bg-[#fdb938] px-2 rounded-sm transition-all text-white  inline-block text-xs p-1 font-semibold relative before:absolute before:bg-gradient-to-tr before:from-yellow-600 before:via-transparent before:to-green-700 before:inset-0 before:scale-110 before:rounded before:blur-md before:-z-10 ms-3',
                actionTeal: 'bg-teal-500 hover:bg-teal-600 rounded-sm transition-all text-white  inline-block text-xs p-1 font-semibold relative before:absolute before:bg-gradient-to-tr before:from-yellow-600 before:via-transparent before:to-green-700 before:inset-0 before:scale-110 before:rounded before:blur-md before:-z-10 ms-3',
                actionRed: 'bg-red-500 hover:bg-red-600 rounded-sm transition-all text-white  inline-block text-xs p-1 font-semibold relative before:absolute before:bg-gradient-to-tr before:from-yellow-600 before:via-transparent before:to-green-700 before:inset-0 before:scale-110 before:rounded before:blur-md before:-z-10 ms-3',
                actionGreen: 'bg-green-700 hover:bg-green-800 rounded-sm transition-all text-white  inline-block text-xs p-1 font-semibold relative before:absolute before:bg-gradient-to-tr before:from-yellow-600 before:via-transparent before:to-green-700 before:inset-0 before:scale-110 before:rounded before:blur-md before:-z-10 ms-3',
            },
            show: {
                butPass: true,
                butReject: true,
            }
            
        }
    },
    provide() {
        return {
            fatherGetToggleSideBar: this.toggleSideBar,
            fatherGetData: this.getData,
        }
    },
    mounted() {
        // layer.msg("用户快捷菜单")
        // 获取数据，并构建分页
        this.getData()
        // this.update()
        return {}
    },
    methods: {
        // hide show side bar 
        toggleSideBar() {
            // console.warn('侧边栏开关')
            // 如果 this.showSideBar 是真就取假，如果 this.showSideBar 是假就取真
            // this.showSideBar = !this.showSideBar
            // 这个方法已经移到子组件中了，直接调用子组件来完成。前提是在模板中使用组件时，在组件中，要先为组件声明 ref 才可以使用，比如这里声明为  refSideSar 通过 refSideSar 直接调用子组件中的方法。
            let res = this.$refs.refSideSar.switchSideBar()
            // 这里要怎么切换 Main 区的宽度大小呢？
            // console.log( ' 这里要怎么切换 Main 区的宽度大小呢？ ' )
            // console.log( res )
            this.showSideBar = res
            switch(res) {
                case true:
                    this.css.main = 'h-full w-[calc(100vw-200px)] bg-gray-400'
                    break
                case false:
                    this.css.main = 'h-full w-full bg-gray-400'
                    break
            }
        },
        // toggle user
        toggleDrop() {
            // console.info('用户快捷菜单')
            // 如果 this.showDropDown 是真就取假，如果 this.showDropDown 是假就取真
            this.showDropDown = !this.showDropDown
        },
  
        search()
        {
            // 如何打包搜索参数？  &p=${p}
            if (undefined!=this.dateValue[0] && ''!=this.dateValue[0] && 2===this.dateValue.length) this.start = this.dateValue[0]
            else this.start = ''
            if (undefined!=this.dateValue[1] && ''!=this.dateValue[1] && 2===this.dateValue.length) this.end = this.dateValue[1]
            else this.end = ''
            this.searchParams = `&start=${this.start}&end=${this.end}&account=${this.username}&status=${this.states}&phone=${this.phone}&number=${this.number}`
            this.getData()
        },
        getData()
        {
            // 发起请求时 要先隐藏分页按钮
            this.showPageBtn = false
            // positionPage 方法是通过 JS 文件导入的
            const page = postPages(this.lang, localStorage.getItem('token'), '/api/x/page/order', this.ps, this.p, this.searchParams)
            // 将异步获取的数据导入 VM 数据
            page.data.then(res =>{
                if (res && 0===res.code) {
                    // 回圈 为每一Row创建管理按钮的show属性
                    // 网速慢时 控制一个管理按钮 只能点击一次的效果
                    res.data.items.forEach((element) => {
                        element.butThaw = true
                        element.butCancel = true
                        element.butPay = true
                        element.butDesignate = true // 派单按钮
                    })

                    this.pageData = res.data.items
                    this.pageTotal = res.data.total
                    // 这是另一个文件导入的方法 在 setup() 中初始化的分页助手 帮我快速生成分页工具的函数 
                    this.pageInfo(this.pageTotal, this.ps, this.p)
                    this.pageLinks = this.pageLink(this.pageTotal, this.ps, this.p)
                    this.pt = Math.ceil(this.pageTotal/this.ps)
                    // 请求结果已经回来 才可以显示分页按钮
                    this.showPageBtn = true
                    this.show.butReject = true
                    this.show.butPass = true
                } 
                if (res && 1<=res.code) {
                    layer.msg(res.msg)
                }
                
            })
        },
        // 定时更新数据 只需要在 getData() 中调用一次本方法即可 
        update() {
            setTimeout(() => {
                // 为什么不使用原来的 getData()
                // 因为 如果有别的事件触发了  getData()
                // 而延时 5 秒后再次更新数据的函数 在 getData() 中执行
                // 就会有更多的更新时种被创建 
                // 而这个  getData() 在所有管理按钮按下之后 都会现次调用执行
                // 所以每 5 秒一次的更新 必须是独立的存在
                this.update5()
            }, 5000)
        },
        update5() {
            const page = postPages(this.lang, localStorage.getItem('token'), '/api/x/page/order', this.ps, this.p, this.searchParams)
            // 将异步获取的数据导入 VM 数据
            page.data.then(res =>{
                if (res && 0===res.code) {
                    // 这个数据更新 只更新数据 原来的按钮 不要更新
                    // 因为当有一记录行 有管理操作时 操作完成 数据更新 之前在处理中被隐藏的按钮就会出现
                    res.data.items.forEach((element, index) => {
                        element.butThaw = undefined === this.pageData[index].butThaw ? this.pageData[index].butThaw : true
                        element.butCancel = undefined === this.pageData[index].butCancel ? this.pageData[index].butCancel : true
                        element.butPay = undefined === this.pageData[index].butPay ? this.pageData[index].butPay : true
                        element.butDesignate = undefined === this.pageData[index].butDesignate ? this.pageData[index].butDesignate : true // 派单按钮
                    })
                    this.pageData = res.data.items
                    this.pageTotal = res.data.total
                    // 这是另一个文件导入的方法 在 setup() 中初始化的分页助手 帮我快速生成分页工具的函数 
                    this.pageInfo(this.pageTotal, this.ps, this.p)
                    this.pageLinks = this.pageLink(this.pageTotal, this.ps, this.p)
                    this.pt = Math.ceil(this.pageTotal/this.ps)
                } 
                // 因为是更新数据 是隐式的 所以出错时 不需要提示
                // if (res && 1<=res.code) {
                //     layer.msg(res.msg)
                // }
                if (res) {
                    this.update()
                }
            })
        },
        // 取销订单
        orderCancel(id, i)
        {
            layer.confirm(
                "您确定要取消该订单 ？",
                {
                    btn: ["确定取消", "放弃"],
                },
                () => {
                    this.pageData[i].butCancel = false
                    axios.post(
                        '/api/x/cancel/order', 
                        {
                            orders_id: id,
                        },
                        {
                            headers: {
                                // 'Content-Type': 'multipart/form-data'
                                'Content-Type': 'application/x-www-form-urlencoded',
                                'Accept-Language': this.lang,
                                'token': localStorage.getItem('token')
                            }
                        }
                    )
                    .then((response) =>  {
                        // console.log(response)
                        if (0===response.data.code) {
                            layer.msg(response.data.msg)
                            this.getData()
                        } else {
                            layer.msg(response.data.msg)
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                },
                () => {
                    // 放弃后什么也不做
                    // console.log('我要看看我操作的 是哪一个项目 我要控制这一行的管理铵钮 什么时候隐藏')
                    // console.log( this.pageData[i] )
                }
            )
        }, // End orderCancel()
        designate(oid, uid) {
            this.$router.push({ path: '/system/order/designate', query: { oid: oid, uid: uid }})
        },
        pay(oid, uid, i) {
            this.pageData[i].butPay = false
            axios.post(
                '/api/x/pay/order', 
                {
                    orders_id: oid,
                    user_id: uid,
                },
                {
                    headers: {
                        // 'Content-Type': 'multipart/form-data'
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': this.lang,
                        'token': localStorage.getItem('token')
                    }
                }
            )
            .then((response) =>  {
                if (0===response.data.code) {
                    layer.msg(response.data.msg)
                } else {
                    layer.msg(response.data.msg)
                }
                this.getData()
            })
            .catch(function (error) {
                // console.log(error)
            })

        }, // End pay()
        // 订单解冻 
        thaw(oid, i) {
            this.pageData[i].butThaw = false
            axios.post(
                '/api/x/thaw/order', 
                {
                    orders_id: oid
                },
                {
                    headers: {
                        // 'Content-Type': 'multipart/form-data'
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': this.lang,
                        'token': localStorage.getItem('token')
                    }
                }
            )
            .then((response) =>  {
                if (0===response.data.code) {
                    layer.msg(response.data.msg)
                } else {
                    layer.msg(response.data.msg)
                }
                this.getData()
            })
            .catch(function (error) {
                // console.log(error)
            })
        }, // End thaw()
    }
}
</script>

<style>

</style>