import { ref, computed } from "vue"

export default function handlePagination() {

  const nextPage = (p, pt) => {
    if (p>=pt) return p
    return p = parseInt(p) + 1
  }

  const backPage = (p) => {
    if (1===p) return p
    return p = parseInt(p) - 1
  }

  const pageInfo = (total, ps, p) => {
    let pt = Math.ceil(total/ps)
    return `共 ${total} 条记录，每页显示 ${ps} 条，共 ${pt} 页当前显示第  ${p} 页`
  }

  /** 
   * 创建出一个数组
   *    中间是每一个可以点击的页数
   */ 
  const pageLink = (total, ps, p) => {
    let pt = Math.ceil(total/ps)
    if (6>=pt) {
        return Array.from(Array(pt).keys()).map((item) => {
            return `${item+1}`
        })
    } else {
        let arr = [
            p-2, p-1, p, p+1, p+2, '...', pt-1, pt
        ]
        let res = []
        arr.forEach((element) => {
            if (0<element && pt>=element) res.push(element)
            if ('...'===element) res.push(element)
        })
        return res
    }

    

  }

  return { nextPage, backPage, pageInfo, pageLink }
}
