<template>
    <s3-layer 
        class="layerBox"
        v-model="visibleBox" 
        :title="title" 
        area="450px" 
        btnAlign="c"
        :btn="['保存数据', '取消编辑']"
        @yes="btnYes"
        @btn2="btnCancel"
    >
      
    

        <!-- username -->
        <div class="w-full  p-2">
            <div class="text-ms text-right text-red-700"  v-show="show.bank_user">
                {{ err.bank_user }}
            </div>
            <div class="flex">
                <button class="w-[150px] text-right z-10  px-4 py-1 text-ms text-emerald-600 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    开户人
                </button>
                <input
                    type="text"
                    class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border border-zinc-200"
                    v-model="input.bank_user"
                />
                <!-- disabled -->
            </div>
        </div><!-- /End username -->


        <!-- /End bank -->
        <div class="w-full p-2">
            <div class="text-ms text-right text-red-700" v-show="show.bank_id">
                {{ err.bank_id }}
            </div>
            <div class="flex">
                <button class="w-[140px] text-right z-10  px-4 py-1 text-ms text-emerald-600 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    开户银行
                </button>
                <select
                        v-model="input.bank_id"
                        class="w-11/12  text-ms focus:outline-none focus:text-gray-600 py-2 pl-2 pr-6 border border-zinc-200"
                    >
                    <option v-for="(value, key) in banks" v-bind:key="key" :value="value.bank_id" class="text-ms">
                        {{ value.name }}
                    </option>
                </select>
            </div>
        </div><!-- /End bank -->


        <!-- phone -->
        <div class="w-full  p-2">
            <div class="text-ms text-right text-red-700"  v-show="show.bank_phone">
                {{ err.bank_phone }}
            </div>
            <div class="flex">
                <button class="w-[150px] text-right z-10  px-4 py-1 text-ms text-emerald-600 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    开户电话
                </button>
                <input
                    type="text"
                    class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border border-zinc-200"
                    v-model="input.bank_phone"
                />
                <!-- disabled -->
            </div>
        </div><!-- /End phone -->

        <!-- card -->
        <div class="w-full  p-2">
            <div class="text-ms text-right text-red-700"  v-show="show.bank_card">
                {{ err.bank_card }}
            </div>
            <div class="flex">
                <button class="w-[150px] text-right z-10  px-4 py-1 text-ms text-emerald-600 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    银行卡号
                </button>
                <input
                    type="text"
                    class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border border-zinc-200"
                    v-model="input.bank_card"
                />
                <!-- disabled -->
            </div>
        </div><!-- /End card -->


        <!-- addr -->
        <div class="w-full  p-2">
            <div class="text-ms text-right text-red-700"  v-show="show.bank_addr">
                {{ err.bank_addr }}
            </div>
            <div class="flex">
                <button class="w-[150px] text-right z-10  px-4 py-1 text-ms text-emerald-600 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    开户地址
                </button>
                <input
                    type="text"
                    class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border border-zinc-200"
                    v-model="input.bank_addr"
                />
                <!-- disabled -->
            </div>
        </div><!-- /End addr -->
        

    </s3-layer>
</template>

<script>
import axios from 'axios'
import { useI18n } from 'vue-i18n'
import { layer } from "vue3-layer"
import postPages from "@/assets/js/postPages"
import { toRaw } from '@vue/reactivity'

export default {
    data() {
        return {
            lang: '',
            title: '编辑银行卡信息',
            visibleBox: false,
            id: '',
            show: {
                bank_user: false,
                bank_id: false,
                bank_phone: false,
                bank_card: false,
                bank_addr: false,
            },
            err: {
                bank_user: '',
                bank_id: '',
                bank_phone: '',
                bank_card: '',
                bank_addr: '',
            },
            input: {
                user_id: '',
                bank_user: '',
                bank_id: '',
                bank_phone: '',
                bank_card: '',
                bank_addr: '',
            },
            banks: [],
        }
    },
    inject: ["fatherGetData"],
    mounted() {
        const { locale } = useI18n()
        this.lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-cn'
        locale.value = this.lang
        return {}
    },
    methods: {
        getSelect()
        {
            // 获取供选择的数据项
            // 如何打包搜索参数？  &p=${p}
            this.searchParams = `&commodity_id=${this.lang}`
            const page = postPages(this.lang, localStorage.getItem('token'), '/api/x/all/bank', this.ps, this.p, this.searchParams)
            // 将异步获取的数据导入 VM 数据
            page.data.then(res =>{
                // const data = toRaw(res)
                this.banks = res.data.list
            })
        },
        selectFile(event) {
            this.form.icon = event.target.files[0];
        },
        switchShow(info) {
            this.visibleBox = true
            this.input = info
            this.getSelect()
            this.deoxidation()
        },
        btnYes()
        {
            this.deoxidationErr()
            axios.post(
                '/api/x/set/user/bank', 
                {
                    user_id: this.input.user_id,
                    bank_user: this.input.bank_user,
                    bank_id: this.input.bank_id,
                    bank_phone: this.input.bank_phone,
                    bank_card: this.input.bank_card,
                    bank_addr: this.input.bank_addr,
                },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        //   'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': this.lang,
                        'token': localStorage.getItem('token')
                    }
                }
            )
            .then((response) =>  {
                // console.log(response)
                if (0===response.data.code) {
                    this.visibleBox = false
                    layer.msg(response.data.msg)
                    // 创建成功，可能还要创建，所以先清空。
                    this.deoxidation()
                    // 创建成功，父组件要重新获取数据。
                    this.fatherGetData()
                } else if (2===response.data.code) {
                    layer.msg(response.data.msg)
                } else {
                    this.show[response.data.data.id] = true
                    // 转成国际化 后端转提示语 前端转表单名称
                    this.err[response.data.data.id] = this.$t('filed.position.'+response.data.data.id) + ' ' + response.data.msg 
                }
            })
            .catch(function (error) {
                console.log(error)
            })
        },
        btnCancel()
        {
            this.visibleBox = false
            
        },
        // 提交后还原提交的表单信息
        deoxidation() {
            // console.log( this.input )
            // let oJson = JSON.parse(JSON.stringify( this.input ))
            // Reflect.ownKeys(oJson).forEach(function(key){
            //     console.log(key, oJson[key])
            // })
        },
        // 提交前所有错误提示先关闭
        deoxidationErr() {
            let oJson = JSON.parse(JSON.stringify( this.show ))
            Reflect.ownKeys(oJson).forEach(function(key){
                // console.log(key, oJson[key])
                oJson[key] = false
            })
            this.show = oJson
        }
    }
}
</script>

<style>

</style>