<template>

    <s3-layer 
        class="layerBox"
        v-model="visibleBox" 
        :title="title" 
        area="500px" 
        btnAlign="c"
        :btn="['保存数据', '取消编辑']"
        @yes="yesOnSubmit"
        @btn2="btn2OnClick"
    >

        <div class="md:w-2/3 max-w-sm mx-auto">

            <div class="text-ms ml-[58px] text-red-700" v-show="showTipTitle">
                {{ tipTitle }}
            </div>
            <div class="w-full inline-flex border">
                <div class="pt-1 w-1/12 bg-gray-100 bg-opacity-50 ml-3 mr-3">
                    <svg fill="#666666" class="w-6 text-gray-400 mx-auto" stroke="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm-11.3 240.2c-2.9 4.8-8.1 7.8-13.7 7.8h-.3c-4.2 0-8.3 1.7-11.3 4.7l-5.7 5.7c-3.1 3.1-3.1 8.2 0 11.3l5.7 5.7c3 3 4.7 7.1 4.7 11.3V304c0 8.8-7.2 16-16 16h-6.1c-6.1 0-11.6-3.4-14.3-8.9l-22.6-45.2c-2.4-4.9-9-5.9-12.8-2.1l-19.5 19.5c-3 3-7.1 4.7-11.3 4.7H50.8C49.1 277.6 48 266.9 48 256c0-110.3 89.7-200 200-200 21.5 0 42.2 3.5 61.6 9.8l-50.2 38.5c-5.1 3.4-4.6 11.1 .9 13.8l10.8 5.4c5.4 2.7 8.8 8.3 8.8 14.3V216c0 4.4-3.6 8-8 8h-3.1c-3 0-5.8-1.7-7.2-4.4-1.6-3.1-6-3.3-7.8-.3l-17.4 29zM408 358.4c0 4.2-1.7 8.3-4.7 11.3l-9.6 9.6c-3 3-7.1 4.7-11.3 4.7h-15.2c-4.2 0-8.3-1.7-11.3-4.7l-13-13a26.8 26.8 0 0 0 -25.4-7l-21.3 5.3c-1.3 .3-2.6 .5-3.9 .5h-10.3c-4.2 0-8.3-1.7-11.3-4.7l-11.9-11.9a8 8 0 0 1 -2.3-5.7v-10.2c0-3.3 2-6.2 5-7.4l39.3-15.7c2-.8 3.9-1.8 5.6-3.1l23.7-16.9a8 8 0 0 1 4.6-1.5h12.1c3.2 0 6.2 1.9 7.4 4.9l5.4 12.9a4 4 0 0 0 3.7 2.5h3.8c1.8 0 3.4-1.2 3.8-2.9l4.2-14.5c.5-1.7 2.1-2.9 3.8-2.9h6.1c2.2 0 4 1.8 4 4v12.9c0 2.1 .8 4.2 2.3 5.7l11.9 11.9c3 3 4.7 7.1 4.7 11.3v24.6z"/>
                    </svg>
                </div>
                <input
                    name="title"
                    type="text"
                    class="w-11/12 text-ms focus:outline-none focus:text-gray-600 p-1 pl-2"
                    :placeholder="txt.title"
                    v-model="form.title"
                />
            </div>
            
            <div class="text-ms ml-[58px] text-red-700" v-show="showTipTitle_vi">
                {{ tipTitle_vi }}
            </div>
            <div class="w-full inline-flex border">
                <div class="pt-1 w-1/12 bg-gray-100 bg-opacity-50 ml-3 mr-3">
                    <svg fill="#666666" class="w-6 text-gray-400 mx-auto" stroke="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm-11.3 240.2c-2.9 4.8-8.1 7.8-13.7 7.8h-.3c-4.2 0-8.3 1.7-11.3 4.7l-5.7 5.7c-3.1 3.1-3.1 8.2 0 11.3l5.7 5.7c3 3 4.7 7.1 4.7 11.3V304c0 8.8-7.2 16-16 16h-6.1c-6.1 0-11.6-3.4-14.3-8.9l-22.6-45.2c-2.4-4.9-9-5.9-12.8-2.1l-19.5 19.5c-3 3-7.1 4.7-11.3 4.7H50.8C49.1 277.6 48 266.9 48 256c0-110.3 89.7-200 200-200 21.5 0 42.2 3.5 61.6 9.8l-50.2 38.5c-5.1 3.4-4.6 11.1 .9 13.8l10.8 5.4c5.4 2.7 8.8 8.3 8.8 14.3V216c0 4.4-3.6 8-8 8h-3.1c-3 0-5.8-1.7-7.2-4.4-1.6-3.1-6-3.3-7.8-.3l-17.4 29zM408 358.4c0 4.2-1.7 8.3-4.7 11.3l-9.6 9.6c-3 3-7.1 4.7-11.3 4.7h-15.2c-4.2 0-8.3-1.7-11.3-4.7l-13-13a26.8 26.8 0 0 0 -25.4-7l-21.3 5.3c-1.3 .3-2.6 .5-3.9 .5h-10.3c-4.2 0-8.3-1.7-11.3-4.7l-11.9-11.9a8 8 0 0 1 -2.3-5.7v-10.2c0-3.3 2-6.2 5-7.4l39.3-15.7c2-.8 3.9-1.8 5.6-3.1l23.7-16.9a8 8 0 0 1 4.6-1.5h12.1c3.2 0 6.2 1.9 7.4 4.9l5.4 12.9a4 4 0 0 0 3.7 2.5h3.8c1.8 0 3.4-1.2 3.8-2.9l4.2-14.5c.5-1.7 2.1-2.9 3.8-2.9h6.1c2.2 0 4 1.8 4 4v12.9c0 2.1 .8 4.2 2.3 5.7l11.9 11.9c3 3 4.7 7.1 4.7 11.3v24.6z"/>
                    </svg>
                </div>
                <input
                    name="title_vi"
                    type="text"
                    class="w-11/12 text-ms  focus:outline-none focus:text-gray-600 p-1 pl-2"
                    :placeholder="txt.title_vi"
                    v-model="form.title_vi"
                />
            </div>
            

            <div class="text-ms ml-[58px] text-red-700" v-show="showTipTitle_en">
                {{ tipTitle_en }}
            </div>
            <div class="w-full inline-flex border">
                <div class="pt-1 w-1/12 bg-gray-100 bg-opacity-50 ml-3 mr-3">
                    <svg fill="#666666" class="w-6 text-gray-400 mx-auto" stroke="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">
                        <path  stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm82.3 357.6c-3.9 3.9-8 8-11.3 11.3-3 3-5.1 6.7-6.2 10.7-1.5 5.7-2.7 11.4-4.8 16.9l-17.4 46.9c-13.8 3-28 4.7-42.7 4.7v-27.4c1.7-12.6-7.6-36.3-22.6-51.3-6-6-9.4-14.1-9.4-22.6v-32c0-11.6-6.3-22.3-16.5-28-14.4-8-34.8-19.1-48.8-26.1-11.5-5.8-22.1-13.1-31.7-21.8l-.8-.7a114.8 114.8 0 0 1 -18.1-20.7c-9.4-13.8-24.7-36.4-34.6-51.1 20.5-45.5 57.4-82 103.2-101.9l24 12C203.5 89.7 216 82 216 70.1v-11.3c8-1.3 16.1-2.1 24.4-2.4l28.3 28.3c6.3 6.3 6.3 16.4 0 22.6L264 112l-10.3 10.3c-3.1 3.1-3.1 8.2 0 11.3l4.7 4.7c3.1 3.1 3.1 8.2 0 11.3l-8 8a8 8 0 0 1 -5.7 2.3h-9c-2.1 0-4.1 .8-5.6 2.3l-9.9 9.7a8 8 0 0 0 -1.6 9.3l15.6 31.2c2.7 5.3-1.2 11.6-7.2 11.6h-5.6c-1.9 0-3.8-.7-5.2-2l-9.3-8.1a16 16 0 0 0 -15.6-3.1l-31.2 10.4a12 12 0 0 0 -8.2 11.3c0 4.5 2.6 8.7 6.6 10.7l11.1 5.5c9.4 4.7 19.8 7.2 30.3 7.2s22.6 27.3 32 32h66.8c8.5 0 16.6 3.4 22.6 9.4l13.7 13.7a30.5 30.5 0 0 1 8.9 21.6 46.5 46.5 0 0 1 -13.7 33zM417 274.3c-5.8-1.5-10.8-5-14.2-10l-18-27a24 24 0 0 1 0-26.6l19.6-29.4c2.3-3.5 5.5-6.3 9.2-8.2l13-6.5C440.2 193.6 448 223.9 448 256c0 8.7-.7 17.2-1.8 25.5L417 274.3z"/>
                    </svg>
                </div>
                <input
                    name="title_en"
                    type="text"
                    class="w-11/12 text-ms  focus:outline-none focus:text-gray-600 p-1 pl-2"
                    :placeholder="txt.title_en"
                    v-model="form.title_en"
                />
            </div>

            
            <div class="text-ms ml-[58px] text-red-700" v-show="showTipQueue">
                {{ tipQueue }}
            </div>
            <div class="w-full inline-flex border">
                <div class="pt-1 w-1/12 bg-gray-100 bg-opacity-50 ml-3 mr-3">
                    <svg fill="#666666" class="w-6 text-gray-400 mx-auto" stroke="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"  d="M256 112a56 56 0 1 0 -56-56 56 56 0 0 0 56 56zm176 336H80a16 16 0 0 0 -16 16v32a16 16 0 0 0 16 16h352a16 16 0 0 0 16-16v-32a16 16 0 0 0 -16-16zm72.9-263.8l-28.5-15.9c-7.4-5-16.9-2.5-22.3 4.7a47.6 47.6 0 0 1 -47.2 18.2C383.7 186.9 368 164.9 368 141.4a13.4 13.4 0 0 0 -13.4-13.4h-38.8c-6 0-11.6 4-12.9 9.9a48 48 0 0 1 -93.9 0c-1.3-5.9-6.8-9.9-12.9-9.9H157.4a13.4 13.4 0 0 0 -13.4 13.4c0 25.7-19 48.8-44.7 50.5a47.5 47.5 0 0 1 -41.5-19.2c-5.3-7.1-14.7-9.5-22.1-4.5l-28.6 16a16 16 0 0 0 -5.4 20.5L104.2 416h303.5l102.6-211.4a16 16 0 0 0 -5.4-20.5z"/>
                    </svg>
                </div>
                <input
                    name="queue"
                    type="text"
                    class="w-11/12 text-ms  focus:outline-none focus:text-gray-600 p-1 pl-2"
                    placeholder="顺序 数值越小越靠前"
                    v-model="form.queue"
                />
            </div>

           

        </div>        
    </s3-layer>
</template>

<script>
import { useI18n } from 'vue-i18n'
import axios from 'axios'
import { layer } from "vue3-layer"
export default {
    components: {
        
    },
    data() {
        return {
            lang: '',
            title: '增设职务',
            visibleBox: false,
            txt: {
                title: '职务名称(中文)',
                title_vi: '职务名称(越南文)',
                title_en: '职务名称(英文)',
            },
            form: {
                title: '',
                title_vi: '',
                title_en: '',
                route: '',
                team_id: '',
                queue: '',
                icon : '',
                is_show: 0
            },
            showTipTitle: false,
            tipTitle: '',
            showTipTitle_vi: false,
            tipTitle_vi: '',
            showTipTitle_en: false,
            tipTitle_en: '',
            showTipRoute: false,
            tipRoute: '',
            showTipTeam_id: false,
            tipTeam_id: '',
            showTipQueue: false,
            tipQueue: '',
            showTipIcon: false,
            tipIcon: '',
            teams: [],
        }
    },
    inject: ["fatherGetData"],
    mounted() {
        const { locale } = useI18n()
        this.lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-cn'
        locale.value = this.lang
        return {}
    },
    methods: {
        switchShow() {
            this.visibleBox = true
            
        },
        yesOnSubmit() {
            this.deoxidationTip()
            axios.post(
                '/api/x/add/position', 
                {
                    title: this.form.title,
                    title_vi: this.form.title_vi,
                    title_en: this.form.title_en,
                    route: this.form.route,
                    // 因为表单如果有默认值 placeholder 做为表单 label 时就无效了，所以默认值在提交前设置。
                    queue: this.form.queue ? this.form.queue : 0,
                    team_id: this.form.team_id,
                    icon : this.form.icon ? this.form.icon : 0,
                    is_show: this.form.is_show
                },
                {
                    headers: {
                        // 'Content-Type': 'multipart/form-data'
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': this.lang,
                        'token': localStorage.getItem('token')
                    }
                }
            )
            .then((response) =>  {
                // console.log(response)
                if (0===response.data.code) {
                    layer.msg(response.data.msg)
                    // 创建成功，可通还要创建，所以先清空。
                    this.deoxidation()
                    this.fatherGetData()
                } else {
                    const word = response.data.data.id
                    const capitalized = 'showTip' + word.charAt(0).toUpperCase() + word.slice(1)
                    const tip = 'tip' + word.charAt(0).toUpperCase() + word.slice(1)
                    // 转成国际化 后端转提示语 前端转表单名称
                    this[tip] = this.$t('filed.position.'+response.data.data.id) + ' ' + response.data.msg 
                    this[capitalized] = true
                }
            })
            .catch(function (error) {
                console.log(error)
            })
            // layer.msg("按钮被点击了")
            // this.visibleBox = false
        },
        btn2OnClick(index, layero){
            this.deoxidationTip()
            this.deoxidation()
            this.visibleBox = false
        },
        upIcon() {
            // 我要选择 icon 
            // 多打开一个层
            // this.$refs.refIconLayer.switchShow()
            // 打开多个层，只能是方法模式下，组件模式下，只能打开一个层。所以上会这行失效。
            const vm = this
            layer.open({
                type: 2,
                title: "选择图标",
                shadeClose: false,
                shade: [0.2, "#393D49"],
                maxmin: true, //开启最大化最小化按钮
                area: ["800px", "450px"],
                content: '/system/select/icon', // iframe 的 url，no 代表不显示滚动条
                btn: ["确定", "关闭"],
                yes: function (index, layero) {
                    // 确定按钮回调
                    let form = layero.find("iframe").contents().find("#idSearch")[0]
                    vm.form.icon = form.value
                    layer.close(index)
                },
                cancel: function (index, layero) {
                    // 取消按钮回调
                    layer.close(index)
                },
            });

        },
        // 提交后还原提交的表单信息
        deoxidation() {
            this.form.title = ''
            this.form.title_vi = ''
            this.form.title_en = ''
            this.form.queue = ''
            this.form.route = ''
            this.form.team_id = ''
            this.form.icon = ''
            this.form.is_show = 0
        },
        // 提前时清空错误提示
        deoxidationTip() {
            this.showTipTitle = false
            this.tipTitle = ''
            this.showTipTitle_vi = false
            this.tipTitle_vi = ''
            this.showTipTitle_en = false
            this.tipTitle_en = ''
            this.showTipRoute = false
            this.tipRoute = ''
            this.showTipTeam = false
            this.tipTeam = ''
            this.showTipQueue = false
            this.tipQueue = ''
            this.showTipIcon = false
            this.tipIcon = ''
        }
    }
}
</script>

<style>

</style>