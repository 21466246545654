<template>
    <s3-layer 
        class="layerBox"
        v-model="visibleBox" 
        :title="title" 
        area="450px" 
        btnAlign="c"
        :btn="['保存数据', '取消编辑']"
        @yes="btnYes"
        @btn2="btnCancel"
    >
      

        <!-- /End grade -->
        <div class="w-full p-2">
            <div class="text-ms text-right text-red-700" v-show="show.grade">
                {{ err.grade }}
            </div>
            <div class="flex">
                <button class="w-[140px] text-right z-10  px-4 py-1 text-ms text-emerald-600 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    类型
                </button>
                <select
                        v-model="input.grade"
                        class="w-11/12  text-ms focus:outline-none focus:text-gray-600 py-2 pl-2 pr-6 border border-zinc-200"
                    >
                    <option value="3" class="text-ms">
                        赠送
                    </option>
                    <option value="1" class="text-ms">
                        充值
                    </option>
                    <option value="0" class="text-ms">
                        扣除
                    </option>
                </select>
            </div>
        </div><!-- /End grade -->

        <!-- amount -->
        <div class="w-full  p-2">
            <div class="text-ms text-right text-red-700" v-show="show.amount">
                {{ err.amount }}
            </div>
            <div class="flex">
                <button class="w-[150px] text-right z-10  px-4 py-1 text-ms text-emerald-600 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    金额
                </button>
                <input
                    type="text"
                    class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border border-zinc-200"
                    :placeholder="tip.amount"
                    v-model="input.amount"
                />
                <!-- disabled -->
            </div>
        </div><!-- /End amount -->


        <!-- remark -->
        <div class="w-full  p-2">
            <div class="text-ms text-right text-red-700" v-show="show.remark">
                {{ err.remark }}
            </div>
            <div class="flex">
                <button class="w-[150px] text-right z-10  px-4 py-1 text-ms text-emerald-600 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    备注
                </button>
                <input
                    type="text"
                    class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border border-zinc-200"
                    :placeholder="tip.remark"
                    v-model="input.remark"
                />
                <!-- disabled -->
            </div>
        </div><!-- /End amount -->



    </s3-layer>
</template>

<script>
import { useI18n } from 'vue-i18n'
import axios from 'axios'
import { layer } from "vue3-layer"

import postPages from "@/assets/js/postPages"
import { toRaw } from '@vue/reactivity'

export default {
    data() {
        return {
            lang: '',
            title: '金额调整',
            visibleBox: false,

            levels: [],
            // 是否显示错误提示
            show: {
                grade: false,
                amount: false,
                remark: false,
            },
            // 错误提示信息
            err: {
                grade: '',
                amount: '',
                remark: '',
            },
            // 表单提示
            tip: {
                grade: '请选择类型',
                amount: '请输入金额',
                remark: '请输入备注',
            },
            // 表单数据
            input: {
                grade: 1,
                amount: '',
                remark: '',
            },
            api: {
                commodity: [],
                grade: [],
            }
        }
    },
    inject: ["fatherGetData"],
    mounted() {
        const { locale } = useI18n()
        this.lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-cn'
        locale.value = this.lang
        return {}
    },
    methods: {
        switchShow( user_id ) {
            this.visibleBox = true
            this.input.user_id = user_id
            this.getSelect()
        },
        getSelect()
        {

        },
        btnYes()
        {
            this.deoxidationErr()
            axios.post(
                '/api/x/set/user/amount', 
                {
                    user_id: this.input.user_id,
                    grade: this.input.grade,
                    amount: this.input.amount,
                    remark: this.input.remark,
                },
                {
                    headers: {
                        // 'Content-Type': 'multipart/form-data'
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': this.lang,
                        'token': localStorage.getItem('token')
                    }
                }
            )
            .then((response) =>  {
                // console.log(response)
                if (0===response.data.code) {
                    layer.msg(response.data.msg)
                    // 创建成功，可通还要创建，所以先清空。
                    this.deoxidation()
                    this.fatherGetData()
                } else {
                    // this.visibleBox = true
                    // const word = response.data.data.id
                    // const showId = 'show' + word.charAt(0).toUpperCase() + word.slice(1)
                    this.show[response.data.data.id] = true
                    // const tipId = 'tip' + word.charAt(0).toUpperCase() + word.slice(1)
                    // 转成国际化 后端转提示语 前端转表单名称
                    this.err[response.data.data.id] = this.$t('filed.position.'+response.data.data.id) + ' ' + response.data.msg 
                    layer.msg(response.data.msg)
                }
            })
            .catch(function (error) {
                console.log(error)
            })
        },
        btnCancel()
        {
            this.visibleBox = false
        },

        // 提交后还原提交的表单信息
        deoxidation() {
            this.input.grade  = 1
            this.input.amount  = ''
            this.input.remark  = ''
        },
        // 提前时清空错误提示
        deoxidationTip() {

        },
        deoxidationErr() {
            this.show.grade = false
            this.show.amount = false
            this.show.remark = false
            this.err.grade = ''
            this.err.amount = ''
            this.err.remark = ''
        }

    } // End methods(...)
}
</script>

<style>

</style>