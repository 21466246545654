<template>
    <s3-layer 
        class="layerBox"
        v-model="visibleBox" 
        :title="title" 
        area="450px" 
        btnAlign="c"
        :btn="['保存数据', '取消编辑']"
        @yes="btnYes"
        @btn2="btnCancel"
    >
      
    

        <!-- pass_login -->
        <div class="w-full  p-2">
            <div class="text-ms text-right text-red-700"  v-show="show.pass_login">
                {{ err.pass_login }}
            </div>
            <div class="flex">
                <button class="w-[150px] text-right z-10  px-4 py-1 text-ms text-emerald-600 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    登录密码
                </button>
                <input
                    type="text"
                    class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border border-zinc-200"
                    v-model="input.pass_login"
                />
                <!-- disabled -->
            </div>
        </div><!-- /End pass_login -->

        <!-- pass_pay -->
        <div class="w-full  p-2">
            <div class="text-ms text-right text-red-700"  v-show="show.pass_pay">
                {{ err.pass_pay }}
            </div>
            <div class="flex">
                <button class="w-[150px] text-right z-10  px-4 py-1 text-ms text-emerald-600 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    交易密码
                </button>
                <input
                    type="text"
                    class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border border-zinc-200"
                    v-model="input.pass_pay"
                />
                <!-- disabled -->
            </div>
        </div><!-- /End pass_login -->


        

    </s3-layer>
</template>

<script>
import axios from 'axios'
import { useI18n } from 'vue-i18n'
import { layer } from "vue3-layer"
import postPages from "@/assets/js/postPages"
import { toRaw } from '@vue/reactivity'

export default {
    data() {
        return {
            lang: '',
            title: '编辑管理帐号',
            visibleBox: false,
            id: '',
            show: {
                pass_login: false,
                pass_pay: false,
            },
            err: {
                pass_login: '',
                pass_pay: '',
            },
            input: {
                id: '',
                pass_login: '',
                pass_pay: '',
            }
        }
    },
    inject: ["fatherGetData"],
    mounted() {
        const { locale } = useI18n()
        this.lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-cn'
        locale.value = this.lang
        return {}
    },
    methods: {
        
        selectFile(event) {
            this.form.icon = event.target.files[0];
        },
        switchShow(id) {
            this.visibleBox = true
            this.input.id = id
        },
        btnYes()
        {
            
            axios.post(
                '/api/x/set/user/password', 
                {
                    user_id: this.input.id,
                    pass_login: this.input.pass_login,
                    pass_pay: this.input.pass_pay,
                },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        //   'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': this.lang,
                        'token': localStorage.getItem('token')
                    }
                }
            )
            .then((response) =>  {
                // console.log(response)
                if (0===response.data.code) {
                    this.visibleBox = false
                    layer.msg(response.data.msg)
                    // 创建成功，可能还要创建，所以先清空。
                    this.deoxidation()
                } else if (2===response.data.code) {
                    layer.msg(response.data.msg)
                } else {
                    this.show[response.data.data.id] = true
                    // 转成国际化 后端转提示语 前端转表单名称
                    this.err[response.data.data.id] = this.$t('filed.position.'+response.data.data.id) + ' ' + response.data.msg 
                }
            })
            .catch(function (error) {
                console.log(error)
            })
        },
        btnCancel()
        {
            this.visibleBox = false
        },
        // 提交后还原提交的表单信息
        deoxidation() {
            this.input.id = ''
            this.input.pass_login = ''
            this.input.pass_pay = ''
        },
        // 提前时清空错误提示
        deoxidationTip() {

        }
    }
}
</script>

<style>

</style>