<template>
    <!--  Main -->
    <div class="w-screen h-screen flex">

        <!-- Side bar 侧边栏 -->
        <!-- 侧边栏 w-[200px] 总固定宽度 200 px  -->
        <SideSar ref="refSideSar" />
        <!-- /End Side bar -->

        <!-- Main -->
        <div :class="css.main">

            <!-- Main Header -->
            <MainHeader ref="refMainHeader" />
            <!--  /End Main Header -->

            <div class="h-[calc(100vh-80px)] bg-white z-0">

                <!-- Row -->
                <div class="w-full h-[40px] bg-current">
                    <div class="grid grid-cols-2 gap-0 pb-2">
                        <!-- Row Left -->
                        <div class="left text-left">
                            <router-link to="#" @click.native="$router.go(-2)" class="fixed text-white text-xs pl-[30px] pt-[15px]">
                                &#60; 返回上一页 
                            </router-link>
                        </div>
                        <!-- Row Right -->
                        <div class="right text-right">
                            <!-- 右侧内容 -->
                        </div>
                    </div>
                </div><!-- /End Row -->


                <!-- search -->
                <div class="w-full text-left mt-4 mx-4">

                    <!-- search date -->
                    <div class="w-[200px] inline-block p-2">
                        <div class="flex">
                            <button id="states-button"  class="flex-shrink-0 z-10 inline-flex items-center border-r-0 bg-zinc-200 dark:bg-zinc-200 px-4 py-1 text-ms font-medium text-center text-zinc-700 bg-transparent border border-zinc-300 dark:border-zinc-300  hover:bg-transparent  dark:bg-transparent dark:hover:bg-transparent  dark:text-zinc-700 dark:border-gray-600" type="button">
                                注册时间 
                            </button>
                            <vue-tailwind-datepicker :formatter="formatter" v-model="dateValue" i18n="zh-cn"/>
                        </div>
                    </div><!-- /End search date -->

                    <!-- search username -->
                    <div class="w-[200px] inline-block p-2">
                        <div class="flex">
                            <button id="states-button"  class="flex-shrink-0 z-10 inline-flex items-center border-r-0 bg-zinc-200 dark:bg-zinc-200 px-4 py-1 text-ms font-medium text-center text-zinc-700 bg-transparent border border-zinc-300 dark:border-zinc-300  hover:bg-transparent  dark:bg-transparent dark:hover:bg-transparent  dark:text-zinc-700 dark:border-gray-600" type="button">
                                用户名
                            </button>
                            <input
                                name="title"
                                type="text"
                                class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border-zinc-300 dark:border-zinc-300 dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-400 dark:text-zinc-700"
                                placeholder=""
                                v-model="search.title"
                            />
                        </div>
                    </div><!-- /End search username -->

                    <!-- search phone -->
                    <div class="w-[200px] inline-block p-2">
                        <div class="flex">
                            <button id="states-button"  class="flex-shrink-0 z-10 inline-flex items-center border-r-0 bg-zinc-200 dark:bg-zinc-200 px-4 py-1 text-ms font-medium text-center text-zinc-700 bg-transparent border border-zinc-300 dark:border-zinc-300  hover:bg-transparent  dark:bg-transparent dark:hover:bg-transparent  dark:text-zinc-700 dark:border-gray-600" type="button">
                                手机号码
                            </button>
                            <input
                                name="title"
                                type="text"
                                class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border-zinc-300 dark:border-zinc-300 dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-400 dark:text-zinc-700"
                                placeholder=""
                                v-model="search.title"
                            />
                        </div>
                    </div><!-- /End search phone -->

                    <div class="w-[200px] inline-block p-2">
                        <div class="flex">
                            <button @click="search" id="states-button"  class="flex-shrink-0 z-10 inline-flex items-center  bg-zinc-200 dark:bg-zinc-200 px-4 py-1 text-ms font-medium text-center text-zinc-700 bg-transparent border border-zinc-300 dark:border-zinc-300  hover:bg-transparent  dark:bg-transparent dark:hover:bg-transparent  dark:text-zinc-700 dark:border-gray-600" type="button">
                                搜索
                            </button>
                        </div>
                    </div>

                </div><!-- /End search -->

                <!-- 数据 shadow-md -->
                <div class="relative overflow-x-auto h-[calc(100vh-230px)] overflow-y-scroll  p-2 mx-4">
                    
  
                    <section class="flex flex-row flex-wrap">

                        <input id="tab-one" type="radio" name="tabs" class="peer/tab-one opacity-0 absolute" checked />
                        <label for="tab-one" class="tab-p peer-checked/tab-one:bg-gray-200">
                            一级会员
                        </label>

                        <input id="tab-two" type="radio" name="tabs" class="peer/tab-two opacity-0 absolute" />
                        <label for="tab-two" class="tab-p peer-checked/tab-two:bg-gray-200">
                            二级会员
                        </label>

                        <input id="tab-three" type="radio" name="tabs" class="peer/tab-three opacity-0 absolute" />
                        <label for="tab-three" class="tab-p peer-checked/tab-three:bg-gray-200">
                            三级会员
                        </label>

                        <input id="tab-four" type="radio" name="tabs" class="peer/tab-four opacity-0 absolute" />
                        <label for="tab-four" class="peer-checked/tab-four:bg-gray-200 tab-p">
                            四级会员
                        </label>

                        <input id="tab-five" type="radio" name="tabs" class="peer/tab-five opacity-0 absolute" />
                        <label for="tab-five" class="peer-checked/tab-five:bg-gray-200 tab-p">
                            五级会员
                        </label>

                        <div class="basis-full h-0"></div>

                        <!-- 第一个选项卡 -->
                        <div class="tab-box peer-checked/tab-one:block">
                            <table class="text-ms w-full text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                <thead class="text-xs text-gray-700  bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                    <tr class="bg-[#f2f2f2] bg-[#f2f2f2] text-[#666] border border-[#e6e6e6] dark:border-[#e6e6e6]">
                                        <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">帐号</th>
                                        <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">余额</th>
                                        <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">佣金</th>
                                        <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">充值</th>
                                        <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">提现</th>
                                        <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">上级用户</th>
                                        <th scope="col" class="px-6 py-3">注册时间</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in pageData" :key="item.user_id" v-show="6==item.grade" class="bg-white border border-[#e6e6e6] dark:border-[#e6e6e6] text-stone-600  whitespace-nowrap dark:text-stone-600 dark:bg-white hover:bg-[#f2f2f2] dark:hover:bg-[#f2f2f2]">
                                        <td class="px-6 py-4 border-r border-[#e6e6e6]">{{ item.username }}</td>
                                        <td class="px-6 py-4 border-r border-[#e6e6e6]">{{ item.balance }}</td>
                                        <td class="px-6 py-4 border-r border-[#e6e6e6]">{{ item.commission }}</td>
                                        <td class="px-6 py-4 border-r border-[#e6e6e6]">{{ item.recharge }}</td>
                                        <td class="px-6 py-4 border-r border-[#e6e6e6]">{{ item.withdraw }}</td>
                                        <td class="px-6 py-4 border-r border-[#e6e6e6]">{{  item.user['username'] }}</td>
                                        <td class="px-6 py-4">{{ date(item.created_at) }} &nbsp; {{ time(item.created_at) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div><!-- / End 第一个选项卡 -->
                        <!-- 第二个选项卡 -->
                        <div class="tab-box peer-checked/tab-two:block">
                            <table class="text-ms w-full text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                <thead class="text-xs text-gray-700  bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                    <tr  class="bg-[#f2f2f2] bg-[#f2f2f2] text-[#666] border border-[#e6e6e6] dark:border-[#e6e6e6]">
                                        <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">帐号</th>
                                        <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">余额</th>
                                        <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">佣金</th>
                                        <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">充值</th>
                                        <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">提现</th>
                                        <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">上级用户</th>
                                        <th scope="col" class="px-6 py-3">注册时间</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in pageData" :key="item.user_id" v-show="5==item.grade" class="bg-white border-b border-zinc-300 dark:border-zinc-300 text-stone-600 bg-white  dark:text-stone-600 dark:bg-white hover:bg-zinc-200 dark:hover:bg-zinc-200">
                                        <td class="px-6 py-4 border-r border-[#e6e6e6]">{{ item.username }}</td>
                                        <td class="px-6 py-4 border-r border-[#e6e6e6]">{{ item.balance }}</td>
                                        <td class="px-6 py-4 border-r border-[#e6e6e6]">{{ item.commission }}</td>
                                        <td class="px-6 py-4 border-r border-[#e6e6e6]">{{ item.recharge }}</td>
                                        <td class="px-6 py-4 border-r border-[#e6e6e6]">{{ item.withdraw }}</td>
                                        <td class="px-6 py-4 border-r border-[#e6e6e6]">{{  item.user['username'] }}</td>
                                        <td class="px-6 py-4">{{ date(item.created_at) }} &nbsp; {{ time(item.created_at) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div><!-- / End 第二个选项卡 -->
                        <!-- 第三个选项卡 -->
                        <div class="tab-box peer-checked/tab-three:block">
                            <table class="text-ms w-full text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                <thead class="text-xs text-gray-700  bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                    <tr  class="bg-[#f2f2f2] bg-[#f2f2f2] text-[#666] border border-[#e6e6e6] dark:border-[#e6e6e6]">
                                        <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">帐号</th>
                                        <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">余额</th>
                                        <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">佣金</th>
                                        <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">充值</th>
                                        <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">提现</th>
                                        <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">上级用户</th>
                                        <th scope="col" class="px-6 py-3">注册时间</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in pageData" :key="item.user_id" v-show="4==item.grade" class="bg-white border-b border-zinc-300 dark:border-zinc-300 text-stone-600 bg-white  dark:text-stone-600 dark:bg-white hover:bg-zinc-200 dark:hover:bg-zinc-200">
                                        <td class="px-6 py-4 border-r border-[#e6e6e6]">{{ item.username }}</td>
                                        <td class="px-6 py-4 border-r border-[#e6e6e6]">{{ item.balance }}</td>
                                        <td class="px-6 py-4 border-r border-[#e6e6e6]">{{ item.commission }}</td>
                                        <td class="px-6 py-4 border-r border-[#e6e6e6]">{{ item.recharge }}</td>
                                        <td class="px-6 py-4 border-r border-[#e6e6e6]">{{ item.withdraw }}</td>
                                        <td class="px-6 py-4 border-r border-[#e6e6e6]">{{  item.user['username'] }}</td>
                                        <td class="px-6 py-4">{{ date(item.created_at) }} &nbsp; {{ time(item.created_at) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div><!-- / End 第三个选项卡 -->
                        <!-- 第四个选项卡 -->
                        <div class="tab-box peer-checked/tab-four:block">
                            <table class="text-ms w-full text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                <thead class="text-xs text-gray-700  bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                    <tr  class="bg-[#f2f2f2] bg-[#f2f2f2] text-[#666] border border-[#e6e6e6] dark:border-[#e6e6e6]">
                                        <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">帐号</th>
                                        <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">余额</th>
                                        <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">佣金</th>
                                        <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">充值</th>
                                        <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">提现</th>
                                        <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">上级用户</th>
                                        <th scope="col" class="px-6 py-3">注册时间</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in pageData" :key="item.user_id" v-show="2==item.grade" class="bg-white border-b border-zinc-300 dark:border-zinc-300 text-stone-600 bg-white  dark:text-stone-600 dark:bg-white hover:bg-zinc-200 dark:hover:bg-zinc-200">
                                        <td class="px-6 py-4 border-r border-[#e6e6e6]">{{ item.username }}</td>
                                        <td class="px-6 py-4 border-r border-[#e6e6e6]">{{ item.balance }}</td>
                                        <td class="px-6 py-4 border-r border-[#e6e6e6]">{{ item.commission }}</td>
                                        <td class="px-6 py-4 border-r border-[#e6e6e6]">{{ item.recharge }}</td>
                                        <td class="px-6 py-4 border-r border-[#e6e6e6]">{{ item.withdraw }}</td>
                                        <td class="px-6 py-4 border-r border-[#e6e6e6]">{{  item.user['username'] }}</td>
                                        <td class="px-6 py-4">{{ date(item.created_at) }} &nbsp; {{ time(item.created_at) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div><!-- / End 第四个选项卡 -->
                        <!-- 第五个选项卡 -->
                        <div class="tab-box peer-checked/tab-five:block">
                            <table class="text-ms w-full text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                <thead class="text-xs text-gray-700  bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                    <tr  class="bg-[#f2f2f2] bg-[#f2f2f2] text-[#666] border border-[#e6e6e6] dark:border-[#e6e6e6]">
                                        <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">帐号</th>
                                        <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">余额</th>
                                        <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">佣金</th>
                                        <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">充值</th>
                                        <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">提现</th>
                                        <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">上级用户</th>
                                        <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">注册时间</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in pageData" :key="item.user_id" v-show="1==item.grade" class="bg-white border-b border-zinc-300 dark:border-zinc-300 text-stone-600 bg-white  dark:text-stone-600 dark:bg-white hover:bg-zinc-200 dark:hover:bg-zinc-200">
                                        <td class="px-6 py-4 border-r border-[#e6e6e6]">{{ item.username }}</td>
                                        <td class="px-6 py-4 border-r border-[#e6e6e6]">{{ item.balance }}</td>
                                        <td class="px-6 py-4 border-r border-[#e6e6e6]">{{ item.commission }}</td>
                                        <td class="px-6 py-4 border-r border-[#e6e6e6]">{{ item.recharge }}</td>
                                        <td class="px-6 py-4 border-r border-[#e6e6e6]">{{ item.withdraw }}</td>
                                        <td class="px-6 py-4 border-r border-[#e6e6e6]">{{  item.user['username'] }}</td>
                                        <td class="px-6 py-4">{{ date(item.created_at) }} &nbsp; {{ time(item.created_at) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div><!-- / End 第五个选项卡 -->

                    </section>



                    


                </div><!-- /End 数据-->
            </div>

            <!-- <div class="h-[100px] p-10"></div> -->

            <!-- Main Footer -->
            <MainFooter />
            <!--  /End Main Footer -->

        </div>
    
    </div><!-- /End Main -->
</template>

<script>
import axios from 'axios'
import { layer } from "vue3-layer"
import SideSar from "@/components/SideSar.vue"
import MainHeader from "@/components/MainHeader.vue"
import MainFooter from "@/components/MainFooter.vue"
import CreateUser from '@/components/CreateUser.vue'
import EditAmount from '@/components/EditAmount.vue'
import EditUser from '@/components/EditUser.vue'
import EditBankCard from '@/components/EditBankCard.vue'


// page.a
import handlePagination from "@/assets/js/handlePagination"
import postPages from "@/assets/js/postPages"
import format from '@/assets/js/formatDate'
import { useRoute } from 'vue-router'

// 日期选择件
// code.admin/node_modules/vue-tailwind-datepicker/dist/vue-tailwind-datepicker.js 编辑 删除掉 py-2.5 rounded-lg 加上 py-1 outline-none dark:outline-none
// 删除 focus:ring focus:ring-vtd-primary-500 focus:ring-opacity-10 dark:focus:ring-opacity-20
import { ref } from "vue"
import VueTailwindDatepicker from "vue-tailwind-datepicker"
const dateValue = ref([])
const formatter = ref({
  date: "YYYY-MM-DD",
  month: "MMM",
})


export default {
    components: {
        SideSar,
        MainHeader,
        MainFooter,
        layer,
        CreateUser,
        VueTailwindDatepicker,
        EditAmount,
        EditUser,
        EditBankCard,
    },
    setup() {

        // 首先在setup中定义
        const route = useRoute()
        // query
        let agent_id = route.query.id
        console.log( agent_id )

        // page.b
        const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-cn'
        const handlePaginationValue = handlePagination()
        const formatFunc = format()
        return { ...handlePaginationValue, ...formatFunc, lang, agent_id }
    },
    data() {
        return {
            showDropDown: false,
            showSideBar: true,
            visibleBox: false,
            // page.c
            showPageBtn: false,
            pageData: [],
            pageTotal: 0,
            ps: 15,
            p: 1, // 当前请求第几页的数据
            pt: 0, // 总页数
            pageLinks: [],
            searchParams: '',
            // 数据搜索条件
            search: {
                title: '',
                status: '',
            },
            dateValue,
            formatter,
            status: {
                0 : '假人',
                1 : '真人',
            },
            online: {
                0 : '离线',
                1 : '在线',
            },
            css: {
                main: 'h-full w-[calc(100vw-200px)] bg-gray-400',
                actionRed: 'bg-red-500 hover:bg-red-600 rounded-sm transition-all text-white  inline-block text-xs p-1 font-semibold relative before:absolute before:bg-gradient-to-tr before:from-yellow-600 before:via-transparent before:to-green-700 before:inset-0 before:scale-110 before:rounded before:blur-md before:-z-10 ms-3',
                actionGreen: 'bg-green-700 hover:bg-green-800 rounded-sm transition-all text-white  inline-block text-xs p-1 font-semibold relative before:absolute before:bg-gradient-to-tr before:from-yellow-600 before:via-transparent before:to-green-700 before:inset-0 before:scale-110 before:rounded before:blur-md before:-z-10 ms-3',
            }
            
        }
    },
    provide() {
        return {
            fatherGetToggleSideBar: this.toggleSideBar,
            fatherGetData: this.getData,
        }
    },
    mounted() {
        // layer.msg("用户快捷菜单")
        // 获取数据，并构建分页
        this.getData()
        return {}
    },
    methods: {
        // hide show side bar 
        toggleSideBar() {
            // console.warn('侧边栏开关')
            // 如果 this.showSideBar 是真就取假，如果 this.showSideBar 是假就取真
            // this.showSideBar = !this.showSideBar
            // 这个方法已经移到子组件中了，直接调用子组件来完成。前提是在模板中使用组件时，在组件中，要先为组件声明 ref 才可以使用，比如这里声明为  refSideSar 通过 refSideSar 直接调用子组件中的方法。
            let res = this.$refs.refSideSar.switchSideBar()
            // 这里要怎么切换 Main 区的宽度大小呢？
            // console.log( ' 这里要怎么切换 Main 区的宽度大小呢？ ' )
            // console.log( res )
            this.showSideBar = res
            switch(res) {
                case true:
                    this.css.main = 'h-full w-[calc(100vw-200px)] bg-gray-400'
                    break
                case false:
                    this.css.main = 'h-full w-full bg-gray-400'
                    break
            }
        },
        // toggle user
        toggleDrop() {
            // console.info('用户快捷菜单')
            // 如果 this.showDropDown 是真就取假，如果 this.showDropDown 是假就取真
            this.showDropDown = !this.showDropDown
        },
        switchAddUser() {
            this.$refs.refCreateUser.switchShow()
        },
        switchAmountAdjustment(uid)
        {
            this.$refs.refEditAmount.switchShow(uid)
        },
        switchEditUser(info)
        {
            this.$refs.refEditUser.switchShow(info)
        },
        switchEditBankCard(id)
        {
            this.$refs.refEditBankCard.switchShow(id)
        },
        getData()
        {
            axios.post(
                '/api/x/agent/all/member', 
                {
                    user_id: this.agent_id,
                },
                {
                    headers: {
                        // 'Content-Type': 'multipart/form-data'
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': this.lang,
                        'token': localStorage.getItem('token')
                    }
                }
            )
            .then((response) =>  {
                
                if (0===response.data.code) {
                    console.log(response.data.data)
                    this.pageData = response.data.data
                } else {
                    // layer.msg(response.data.msg)
                }
            })
            .catch(function (error) {
                console.log(error)
            })
        },
        status(id) 
        { 
            axios.post(
                '/api/x/status/level', 
                {
                    id: id,
                },
                {
                    headers: {
                        // 'Content-Type': 'multipart/form-data'
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': this.lang,
                        'token': localStorage.getItem('token')
                    }
                }
            )
            .then((response) =>  {
                // console.log(response)
                if (0===response.data.code) {
                    layer.msg(response.data.msg)
                } else {
                    layer.msg(response.data.msg)
                }
                this.getData()
            })
            .catch(function (error) {
                console.log(error)
            })

        },
        deleteLevel(id)
        {
            console.log( id )
            layer.confirm(
                "删除操作比较危险，您确定要删除？",
                {
                    btn: ["确定删除", "放弃删除"],
                },
                () => {
                    axios.post(
                        '/api/x/delete/level', 
                        {
                            id: id,
                        },
                        {
                            headers: {
                                // 'Content-Type': 'multipart/form-data'
                                'Content-Type': 'application/x-www-form-urlencoded',
                                'Accept-Language': this.lang,
                                'token': localStorage.getItem('token')
                            }
                        }
                    )
                    .then((response) =>  {
                        // console.log(response)
                        if (0===response.data.code) {
                            layer.msg(response.data.msg)
                            this.getData()
                        } else {
                            layer.msg(response.data.msg)
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                },
                () => {
                    // 放弃后什么也不做
                }
            )

            
        }, // End deleteLevel
    }
}
</script>

<style>
    /** tailwindcss 的样式汇总在里 */
    .tab-p {
        @apply bg-gray-50 hover:bg-gray-50 pl-6 pr-6 pt-2 pb-2 rounded-t-lg text-sm;
    }
    .tab-box {
        @apply bg-amber-200 hidden w-full;
    }
</style>