<template>
    <!--  Main -->
    <div class="w-screen h-screen flex">

        <!-- Side bar 侧边栏 -->
        <!-- 侧边栏 w-[200px] 总固定宽度 200 px  -->
        <SideSar ref="refSideSar" />
        <!-- /End Side bar -->

        <!-- Main -->
        <div class="w-full h-full bg-gray-400">

            <!-- Main Header -->
            <MainHeader ref="refMainHeader" />
            <!--  /End Main Header -->

            <div class="h-[calc(100vh-80px)]  overflow-y-scroll bg-white">

                <!-- Row -->
                <div class="w-full h-[40px] bg-current">
                    <div class="grid grid-cols-2 gap-0 pb-2">
                        <!-- Row Left -->
                        <div class="left text-left">

                        </div>
                        <!-- Row Right -->
                        <div class="right text-right">
                           
                        </div>
                    </div>
                </div><!-- /End Row -->


                <!-- search -->
                <div class="w-full text-left mt-4 mx-4">
                </div><!-- /End search -->

                <!-- 数据 -->
                <div class="relative overflow-x-auto  shadow-md p-2 mx-4">
                    <table class="w-full text-ms text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" class="px-6 py-3">
                                    ID 
                                </th>

                                <th scope="col" class="px-6 py-3">
                                    商品名称
                                </th>
                                
                                <th scope="col" class="px-6 py-3">
                                    店铺名称
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    价格
                                </th>
                               
                                <th scope="col" class="px-6 py-3">
                                    创建时间
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    管理操作
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in pageData" :key="item.commodity_id" class="bg-white border-b border-zinc-300 dark:border-zinc-300  text-zinc-500 bg-white whitespace-nowrap dark:text-zinc-500 dark:bg-white hover:bg-zinc-200 dark:hover:bg-zinc-200">
                                <td class="px-6 py-4">
                                    {{ item.commodity_id }}
                                </td>

                                <td class="px-6 py-4 font-medium">
                                    <span v-show="lang=='zh-cn'">{{ item.title }}</span>
                                    <span v-show="lang=='vi-vn'">{{ item.title_vi }}</span>
                                </td>
                                <td class="px-6 py-4 font-medium">
                                    <span v-show="lang=='zh-cn'">{{ item.shop }}</span>
                                    <span v-show="lang=='vi-vn'">{{ item.shop_vi }}</span>
                                </td>
                                <td class="px-6 py-4 font-medium">
                                    {{ item.price }}
                                </td>
                                
                                <td>
                                    {{ date(item.created_at) }}
                                    &nbsp;
                                    {{ time(item.created_at) }}
                                </td>
                                <td class="flex items-center px-6 py-4">
                                    <a v-show="item.but" href="#" @click="designate(item.commodity_id, index)" :class="item.status ? css.butCss  : css.butCss">
                                        人工派单
                                    </a>
                                    <a v-show="item.but" href="#" @click="customize(item.commodity_id, index)" :class="item.status ? css.butCss2  : css.butCss2">
                                        手动金额
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div><!-- /End 数据-->

                <!-- Row 分页条 -->
                <div class="grid grid-cols-2 gap-0 pb-2 text-ms py-2 px-4">
                    <!-- Row Left -->
                    <div class="left text-left">
                        <span>{{ pageInfo(pageTotal, ps, p) }}</span>
                    </div>
                    <!-- Row Right -->
                    <div class="right text-right" v-show="showPageBtn">
                        <button @click="p=backPage(p);getData();">上一页</button>
                        <button
                            class="px-1"
                                v-for="item in pageLinks"
                                :key="item"
                                @click="() => {p=item;getData();}"
                            >
                            {{ item }}
                        </button>
                        <button @click="p=nextPage(p,pt);getData();">下一页</button>
                    </div>
                </div><!-- /End Row 分页条 -->


                <CreateSort  ref="refSort" />
                <EditCommodity ref="refEdit" />
            </div>



        <!-- Main Footer -->
        <MainFooter />
        <!--  /End Main Footer -->

        </div>
    
    </div><!-- /End Main -->
</template>

<script>

import axios from 'axios'
import { layer } from "vue3-layer"
import { useRoute } from 'vue-router'
import SideSar from "@/components/SideSar.vue"
import MainHeader from "@/components/MainHeader.vue"
import MainFooter from "@/components/MainFooter.vue"
import EditCommodity from '@/components/EditCommodity.vue'

// page.a
import handlePagination from "@/assets/js/handlePagination"
import postPages from "@/assets/js/postPages"
import format from '@/assets/js/formatDate'

export default {
    components: {
        SideSar,
        MainHeader,
        MainFooter,
        layer,
        EditCommodity,
    },
    setup() {
        // page.b
        const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-cn'
        const handlePaginationValue = handlePagination()
        const formatFunc = format()
        const route = useRoute()
        let oid = route.query.oid
        let uid = route.query.uid

        return { ...handlePaginationValue, ...formatFunc, lang, oid, uid }
    },
    data() {
        return {
            showDropDown: false,
            showSideBar: true,
            visibleBox: false,
            // page.c
            showPageBtn: false,
            pageData: [],
            pageTotal: 0,
            ps: 10,
            p: 1, // 当前请求第几页的数据
            pt: 0, // 总页数
            pageLinks: [],
            searchParams: '',
            // 数据搜索条件
            search: {
                title: '',
                sort: '',
            },
            sorts: [],
            css: {
                main: 'h-full w-[calc(100vw-200px)] bg-gray-400',
                butCss: ' bg-[#2a9ffa] hover:bg-[#2a9ffa] px-2 rounded-sm transition-all text-white  inline-block text-xs p-1 font-semibold relative before:absolute before:bg-gradient-to-tr before:from-yellow-600 before:via-transparent before:to-green-700 before:inset-0 before:scale-110 before:rounded before:blur-md before:-z-10 ms-3',
                butCss2: ' bg-[#fc5c31] hover:bg-[#fc5c31] px-2 rounded-sm transition-all text-white  inline-block text-xs p-1 font-semibold relative before:absolute before:bg-gradient-to-tr before:from-yellow-600 before:via-transparent before:to-green-700 before:inset-0 before:scale-110 before:rounded before:blur-md before:-z-10 ms-3',
                actionTeal: 'bg-teal-500 hover:bg-teal-600 rounded-sm transition-all text-white  inline-block text-xs p-1 font-semibold relative before:absolute before:bg-gradient-to-tr before:from-yellow-600 before:via-transparent before:to-green-700 before:inset-0 before:scale-110 before:rounded before:blur-md before:-z-10 ms-3',
                actionRed: 'bg-red-500 hover:bg-red-600 rounded-sm transition-all text-white  inline-block text-xs p-1 font-semibold relative before:absolute before:bg-gradient-to-tr before:from-yellow-600 before:via-transparent before:to-green-700 before:inset-0 before:scale-110 before:rounded before:blur-md before:-z-10 ms-3',
                actionGreen: 'bg-green-700 hover:bg-green-800 rounded-sm transition-all text-white  inline-block text-xs p-1 font-semibold relative before:absolute before:bg-gradient-to-tr before:from-yellow-600 before:via-transparent before:to-green-700 before:inset-0 before:scale-110 before:rounded before:blur-md before:-z-10 ms-3',
            },
        }
    },
    provide() {
        return {
            fatherGetToggleSideBar: this.toggleSideBar,
            fatherGetData: this.getData,
        }
    },
    mounted() {
        this. getSort()
        this.getData()
        return {}
    },
    methods: {
        // hide show side bar 
        toggleSideBar() {
            // console.warn('侧边栏开关')
            // 如果 this.showSideBar 是真就取假，如果 this.showSideBar 是假就取真
            // this.showSideBar = !this.showSideBar
            // 这个方法已经移到子组件中了，直接调用子组件来完成。前提是在模板中使用组件时，在组件中，要先为组件声明 ref 才可以使用，比如这里声明为  refSideSar 通过 refSideSar 直接调用子组件中的方法。
            this.$refs.refSideSar.switchSideBar()
        },
        // toggle user
        toggleDrop() {
            // console.info('用户快捷菜单')
            // 如果 this.showDropDown 是真就取假，如果 this.showDropDown 是假就取真
            this.showDropDown = !this.showDropDown
        },
        switchBox() {
            // 用来确发子组件中的方法
            this.$refs.refSort.switchShow()
        },
        getData()
        {
            // 如何打包搜索参数？  &p=${p}
            this.searchParams = `&title=${this.search.title}&sort_id=${this.search.sort}`
            // 发起请求时 要先隐藏分页按钮
            this.showPageBtn = false
            // positionPage 方法是通过 JS 文件导入的
            const page = postPages(this.lang, localStorage.getItem('token'), '/api/x/get/commoditys', this.ps, this.p, this.searchParams)
            // 将异步获取的数据导入 VM 数据
            page.data.then(res =>{

                // 回圈 为每一Row创建管理按钮的show属性
                // 网速慢时 控制一个管理按钮 只能点击一次的效果
                res.data.items.forEach((element) => {
                    element.but = true
                })

                this.pageData = res.data.items
                this.pageTotal = res.data.total
                // 这是另一个文件导入的方法 在 setup() 中初始化的分页助手 帮我快速生成分页工具的函数 
                this.pageInfo(this.pageTotal, this.ps, this.p)
                this.pageLinks = this.pageLink(this.pageTotal, this.ps, this.p)
                this.pt = Math.ceil(this.pageTotal/this.ps)
                // 请求结果已经回来 才可以显示分页按钮
                this.showPageBtn = true
            })
        },
        getSort()
        {
            // 如何打包搜索参数？  &p=${p}
            this.searchParams = ``
            const page = postPages(this.lang, localStorage.getItem('token'), '/api/x/get/sorts', this.ps, this.p, this.searchParams)
            // 将异步获取的数据导入 VM 数据
            page.data.then(res =>{
                // console.log( res )
                this.sorts = res.items
            })
        },
        // 自定义金额
        customize(commodity_id, i)
        {
            this.pageData[i].but = false
            layer.prompt(
                {
                    title: "请输入抢单金额",
                    formType: 0, //输入框类型，支持0（文本）默认1（密码）2（多行文本）
                    value: "", // 初始时的值，默认空字符
                    maxlength: 140, //可输入文本的最大长度，默认500
                    area: ["400px", "270px"], //自定义文本域宽高
                    scrollbar: false,
                },
                (amount, index) => {
                    // console.log(`订单ID ${this.id}`)
                    // console.log(`商品ID ${commodity_id}`)
                    // console.log(`抢单金额 ${amount}`)
            
                    this.postCustomize(commodity_id, amount)
                    layer.close(index)
                },
                () => {
                    // 放弃后什么也不做
                }
            )
            // layer.confirm(
            //     "您确定要取消该订单 ？",
            //     {
            //         btn: ["确定取消", "放弃"],
            //     },
            //     () => {
                    
            //     },
            //     () => {
            //         // 放弃后什么也不做
            //     }
            // )
        }, // End customize()
        postCustomize(cid, amount)
        {
            // this.pageData[i].but = false
            axios.post(
                '/api/x/customize/order', 
                {
                    orders_id: this.oid,
                    user_id: this.uid,
                    commodity_id: cid,
                    amount: amount,
                },
                {
                    headers: {
                        // 'Content-Type': 'multipart/form-data'
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': this.lang,
                        'token': localStorage.getItem('token')
                    }
                }
            )
            .then((response) =>  {
                if (0===response.data.code) {
                    // layer.msg(response.data.msg)
                    this.$router.push({
                        path: '/trade/order', query: { }
                    })
                } else {
                    layer.msg(response.data.msg)
                }
                this.getData()
            })
            .catch(function (error) {
                // console.log(error)
            })
        },
        // 人工派单
        designate(cid, i) {
            this.pageData[i].but = false
            axios.post(
                '/api/x/designate/order', 
                {
                    orders_id: this.oid,
                    commodity_id: cid,
                },
                {
                    headers: {
                        // 'Content-Type': 'multipart/form-data'
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': this.lang,
                        'token': localStorage.getItem('token')
                    }
                }
            )
            .then((response) =>  {
                if (0===response.data.code) {
                    // layer.msg(response.data.msg)
                    this.$router.push({
                        path: '/trade/order', query: { }
                    })
                } else {
                    layer.msg(response.data.msg)
                }
                this.getData()
            })
            .catch(function (error) {
                // console.log(error)
            })
        }, // End designate() 人工派单
    }
}
</script>

<style>

</style>