<template>
    <!--  Main -->
    <div class="w-screen h-screen flex">

        <!-- Side bar 侧边栏 -->
        <!-- 侧边栏 w-[200px] 总固定宽度 200 px  -->
        <SideSar ref="refSideSar" />
        <!-- /End Side bar -->

        <!-- Main -->
        <div class="w-full h-full bg-gray-400">

            <!-- Main Header -->
            <MainHeader ref="refMainHeader" />
            <!--  /End Main Header -->

            <div class="h-[calc(100vh-80px)] bg-white">

                <!-- Row -->
                <div class="w-full h-[40px] bg-current">
                    <div class="grid grid-cols-2 gap-0 pb-2">
                        <!-- Row Left -->
                        <div class="left text-left">

                        </div>
                        <!-- Row Right -->
                        <div class="right text-right">
                            <button @click="switchBox" type="button" class="bg-white hover:bg-white hover:border-green-400  focus:ring-blue-300  border   font-medium text-ms text-gray-90 hover:text-lime-700 mt-3 mr-8 px-1 yt-1 text-center inline-flex items-center me-2    dark:bg-white dark:hover:border-green-400 dark:focus:border-green-400  dark:focus:border ">
                                <svg class="w-3.5 h-3.5 me-2" aria-hidden="true" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm144 276c0 6.6-5.4 12-12 12h-92v92c0 6.6-5.4 12-12 12h-56c-6.6 0-12-5.4-12-12v-92h-92c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h92v-92c0-6.6 5.4-12 12-12h56c6.6 0 12 5.4 12 12v92h92c6.6 0 12 5.4 12 12v56z"/>
                                </svg>
                                添加分类
                            </button>
                        </div>
                    </div>
                </div><!-- /End Row -->


                <!-- search -->
                <div class="w-full text-left mt-4 mx-4">
                </div><!-- /End search -->

                <!-- 数据 shadow-md  -->
                <div class="relative overflow-x-auto p-2 mx-4">
                    <table class="w-full text-ms text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr class="bg-[#f2f2f2] bg-[#f2f2f2] text-[#666] border border-[#e6e6e6] dark:border-[#e6e6e6]">
                                <!-- <th scope="col" class="p-4">
                                    <div class="flex items-center">
                                        <input id="checkbox-all-search" type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                        <label for="checkbox-all-search" class="sr-only">checkbox</label>
                                    </div>
                                </th> -->
                                <th scope="col" class="px-6 py-3  border-r border-[#e6e6e6]">
                                    分类名称
                                </th>
                                <th scope="col" class="px-6 py-3  border-r border-[#e6e6e6]">
                                    图片
                                </th>
                                <th scope="col" class="px-6 py-3  border-r border-[#e6e6e6]">
                                    简介
                                </th>
                                <th scope="col" class="px-6 py-3  border-r border-[#e6e6e6]">
                                    创建时间
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    管理操作
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in pageData" :key="item.sort_id"  class="bg-white border border-[#e6e6e6] dark:border-[#e6e6e6] text-stone-600  whitespace-nowrap dark:text-stone-600 dark:bg-white hover:bg-[#f2f2f2] dark:hover:bg-[#f2f2f2]">
                                <!-- <td class="w-4 p-4">
                                    <div class="flex items-center">
                                        <input id="checkbox-table-search-1" type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 border-zinc-300  dark:bg-gray-700 dark:border-zinc-300">
                                        <label for="checkbox-table-search-1" class="sr-only">checkbox</label>
                                    </div>
                                </td> -->
                                <td class="px-6 py-4 font-medium  border-r border-[#e6e6e6]">
                                    {{ item.title }}<br />
                                    {{ item.title_vi }}
                                </td>
                                <td class="px-6 py-4 font-medium  border-r border-[#e6e6e6]">
                                    <img :src="`s${ item.icon }`" class="w-[20px] h-[20px]" alt="" />
                                </td>
                                <td class="px-6 py-4 font-medium  border-r border-[#e6e6e6]">
                                    {{ item.introduction }}<br />
                                    {{ item.introduction_vi }}
                                </td>
                                <td class="px-6 py-4 font-medium  border-r border-[#e6e6e6]">
                                    {{ date(item.created_at) }}<br />
                                    {{ time(item.created_at) }}
                                </td>
                                <td class="flex items-center px-6 py-4">
                                    <a href="#" @click="switchBoxEdit(item)" class="font-medium text-blue-600 dark:text-blue-500 hover:underline  ms-3">编辑</a>
                                    <a href="#" @click="deleteId(item.sort_id)" class="font-medium text-red-600 dark:text-red-500 hover:underline ms-3">删除</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <!-- Row 分页条 -->
                    <div class="grid grid-cols-2 gap-0 pb-2 text-ms py-2 px-4">
                        <!-- Row Left -->
                        <div class="left text-left">
                            <span>{{ pageInfo(pageTotal, ps, p) }}</span>
                        </div>
                        <!-- Row Right -->
                        <div class="right text-right" v-show="showPageBtn">
                            <button @click="p=backPage(p);getData();">上一页</button>
                            <button
                                class="px-1"
                                    v-for="item in pageLinks"
                                    :key="item"
                                    @click="() => {p=item;getData();}"
                                >
                                {{ item }}
                            </button>
                            <button @click="p=nextPage(p,pt);getData();">下一页</button>
                        </div>
                    </div><!-- /End Row 分页条 -->

                </div><!-- /End 数据-->


                <CreateSort  ref="refSort" />
                <EditSort ref="refEdit" />
            </div>

        <!-- Main Footer -->
        <MainFooter />
        <!--  /End Main Footer -->

        </div>
    
    </div><!-- /End Main -->
</template>

<script>
import axios from 'axios'
import { layer } from "vue3-layer"
import SideSar from "@/components/SideSar.vue"
import MainHeader from "@/components/MainHeader.vue"
import MainFooter from "@/components/MainFooter.vue"
import CreateSort from "@/components/CreateSort.vue"
import EditSort from "@/components/EditSort.vue"

// page.a
import handlePagination from "@/assets/js/handlePagination"
import postPages from "@/assets/js/postPages"
import format from '@/assets/js/formatDate'

export default {
    components: {
        SideSar,
        MainHeader,
        MainFooter,
        layer,
        CreateSort,
        EditSort,
    },
    setup() {
        // page.b
        const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-cn'
        const handlePaginationValue = handlePagination()
        const formatFunc = format()
        return { ...handlePaginationValue, ...formatFunc, lang }
    },
    data() {
        return {
            showDropDown: false,
            showSideBar: true,
            visibleBox: false,
            // page.c
            showPageBtn: false,
            pageData: [],
            pageTotal: 0,
            ps: 10,
            p: 1, // 当前请求第几页的数据
            pt: 0, // 总页数
            pageLinks: [],
            searchParams: '',
            // 数据搜索条件
            search: {
                title: '',
            }
            
        }
    },
    provide() {
        return {
            fatherGetToggleSideBar: this.toggleSideBar,
            fatherGetData: this.getData,
        }
    },
    mounted() {
        // layer.msg("用户快捷菜单")
        // 获取数据，并构建分页
        this.getData()
        return {}
    },
    methods: {
        selectFile(event) {
            this.form.icon = event.target.files[0];
        },
        // hide show side bar 
        toggleSideBar() {
            // console.warn('侧边栏开关')
            // 如果 this.showSideBar 是真就取假，如果 this.showSideBar 是假就取真
            // this.showSideBar = !this.showSideBar
            // 这个方法已经移到子组件中了，直接调用子组件来完成。前提是在模板中使用组件时，在组件中，要先为组件声明 ref 才可以使用，比如这里声明为  refSideSar 通过 refSideSar 直接调用子组件中的方法。
            this.$refs.refSideSar.switchSideBar()
        },
        // toggle user
        toggleDrop() {
            // console.info('用户快捷菜单')
            // 如果 this.showDropDown 是真就取假，如果 this.showDropDown 是假就取真
            this.showDropDown = !this.showDropDown
        },
        switchBox() {
            // 用来确发子组件中的方法
            this.$refs.refSort.switchShow()
        },
        switchBoxEdit(id) {
            this.$refs.refEdit.switchShow(id)
        },
        getData()
        {
            // 如何打包搜索参数？  &p=${p}
            this.searchParams = `&title=${this.search.title}`

            // 发起请求时 要先隐藏分页按钮
            this.showPageBtn = false
            // positionPage 方法是通过 JS 文件导入的
            const page = postPages(this.lang, localStorage.getItem('token'), '/api/x/get/sorts', this.ps, this.p, this.searchParams)
            // 将异步获取的数据导入 VM 数据
            page.data.then(res =>{
                this.pageData = res.data.items
                this.pageTotal = res.data.total
                // 这是另一个文件导入的方法 在 setup() 中初始化的分页助手 帮我快速生成分页工具的函数 
                this.pageInfo(this.pageTotal, this.ps, this.p)
                this.pageLinks = this.pageLink(this.pageTotal, this.ps, this.p)
                this.pt = Math.ceil(this.pageTotal/this.ps)
                // 请求结果已经回来 才可以显示分页按钮
                this.showPageBtn = true
            })
        },
        deleteId(id)
        {
            layer.confirm(
                "删除操作比较危险，您确定要删除？",
                {
                    btn: ["确定删除", "放弃删除"],
                },
                () => {
                    axios.post(
                        '/api/x/del/sort', 
                        {
                            sort_id: id,
                        },
                        {
                            headers: {
                                // 'Content-Type': 'multipart/form-data'
                                'Content-Type': 'application/x-www-form-urlencoded',
                                'Accept-Language': this.lang,
                                'token': localStorage.getItem('token')
                            }
                        }
                    )
                    .then((response) =>  {
                        // console.log(response)
                        if (0===response.data.code) {
                            layer.msg(response.data.msg)
                        } else {
                            layer.msg(response.data.msg)
                        }
                        this.getData()
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
                },
                () => {
                    // 放弃后什么也不做
                }
            )
        }
    }
}
</script>

<style>

</style>