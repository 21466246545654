<template>
    <s3-layer 
        class="layerBox"
        v-model="visibleBox" 
        :title="title" 
        area="450px" 
        btnAlign="c"
        :btn="['保存数据', '取消编辑']"
        @yes="btnYes"
        @btn2="btnCancel"
    >
      

        <!-- number -->
        <div class="w-full  p-2">
            <div class="text-ms text-right text-red-700" v-show="show.number">
                {{ err.number }}
            </div>
            <div class="flex">
                <button class="w-[150px] text-right z-10  px-4 py-1 text-ms text-emerald-600 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    收款银行卡号
                </button>
                <input
                    type="text"
                    class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border border-zinc-200"
                    :placeholder="tip.number"
                    v-model="input.number"
                />
                <!-- disabled -->
            </div>
        </div><!-- /End number -->




        <!-- name -->
        <div class="w-full  p-2">
            <div class="text-ms text-right text-red-700" v-show="show.name">
                {{ err.name }}
            </div>
            <div class="flex">
                <button class="w-[150px] text-right z-10  px-4 py-1 text-ms text-emerald-600 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    收款人
                </button>
                <input
                    type="text"
                    class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border border-zinc-200"
                    :placeholder="tip.name"
                    v-model="input.name"
                />
                <!-- disabled -->
            </div>
        </div><!-- /End name -->




        <!-- title -->
        <div class="w-full  p-2">
            <div class="text-ms text-right text-red-700" v-show="show.title">
                {{ err.title }}
            </div>
            <div class="flex">
                <button class="w-[150px] text-right z-10  px-4 py-1 text-ms text-emerald-600 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    银行
                </button>
                <input
                    type="text"
                    class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border border-zinc-200"
                    :placeholder="tip.title"
                    v-model="input.title"
                />
                <!-- disabled -->
            </div>
        </div><!-- /End title -->



        <!-- /End status -->
        <div class="w-full p-2">
            <div class="text-ms text-right text-red-700" v-show="show.status">
                {{ err.status }}
            </div>
            <div class="flex">
                <button class="w-[140px] text-right z-10  px-4 py-1 text-ms text-emerald-600 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    状态
                </button>
                <select
                        v-model="input.status"
                        class="w-11/12  text-ms focus:outline-none focus:text-gray-600 py-2 pl-2 pr-6 border border-zinc-200"
                    >
                    <option value="0" class="text-ms">
                        待启用
                    </option>
                    <option value="1" class="text-ms">
                        启用
                    </option>
                    <option value="2" class="text-ms">
                        禁用
                    </option>
                </select>
            </div>
        </div><!-- /End is status -->

    </s3-layer>
</template>

<script>
import { useI18n } from 'vue-i18n'
import axios from 'axios'
import { layer } from "vue3-layer"

import postPages from "@/assets/js/postPages"
import { toRaw } from '@vue/reactivity'

export default {
    data() {
        return {
            lang: '',
            title: '添加收款银行',
            visibleBox: false,

            // 是否显示错误提示
            show: {
                number: false,
                name: false,
                title: false,
            },
            // 错误提示信息
            err: {
                number: '',
                name: '',
                title: '',
            },
            // 表单提示
            tip: {
                number: '请输入收款银行卡号',
                name: '请输入收款人姓名',
                title: '请输入银行名称',
            },
            // 表单数据
            input: {
                number: '',
                name: '',
                title: '',
                status: 0,
            },
        }
    },
    inject: ["fatherGetData"],
    mounted() {
        const { locale } = useI18n()
        this.lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-cn'
        locale.value = this.lang
        return {}
    },
    methods: {
        switchShow() {
            this.visibleBox = true
        },

        btnYes()
        {
            // this.visibleBox = false
            this.deoxidationErr()
            axios.post(
                '/api/x/add/banks', 
                {
                    number: this.input.number,
                    name: this.input.name,
                    title: this.input.title,
                    status: this.input.status,
                },
                {
                    headers: {
                        // 'Content-Type': 'multipart/form-data'
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': this.lang,
                        'token': localStorage.getItem('token')
                    }
                }
            )
            .then((response) =>  {
                // console.log(response)
                if (0===response.data.code) {
                    layer.msg(response.data.msg)
                    // 创建成功，可通还要创建，所以先清空。
                    this.deoxidation()
                    this.fatherGetData()
                } else if (2===response.data.code) {
                    layer.msg(response.data.msg)
                } else {
                    this.show[response.data.data.id] = true
                    this.err[response.data.data.id] = this.$t('filed.position.'+response.data.data.id) + ' ' + response.data.msg 
                }
            })
            .catch(function (error) {
                console.log(error)
            })
        },
        btnCancel()
        {
            this.visibleBox = false
        },

        // 提交后还原提交的表单信息
        deoxidation() {
            this.input.number = ''
            this.input.name = ''
            this.input.title = ''
            this.input.status = 0
        },
        // 提前时清空错误提示
        deoxidationTip() {

        },
        deoxidationErr() {
            this.show.number = false
            this.show.name = false
            this.show.title = false
            this.show.status = false
        }

    } // End methods(...)
}
</script>

<style>

</style>