
import { ref, computed } from "vue"
import axios from 'axios'


/**
 * 
 * @param {*} lang 语言
 * @param {*} token 令牌
 * @param {*} ps 每页显示多少条
 * @param {*} p 请求第几页的数据
 * @returns 
 */
export default function getPageData(lang, token, url, ps, p, params) {
    let page = ref(1)

    const data = axios.get(
        `${url}?ps=${ps}&p=${p}${params}`, 
        {
            headers: {
                // 'Content-Type': 'multipart/form-data'
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept-Language': lang,
                'token': token
            }
        }
    )
    .then((res) =>  {
        // 从 API 接口得到所有可用的权组
        // console.log(res.data.data.items) 
        return res.data
    })
    .catch((err) => {
        console.log(err)
    })

  return { data }
}
