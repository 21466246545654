<template>
    <div class="clear-both sticky z-50 h-[30px] p-1 w-full bg-gray-800 border-t border-slate-900 text-ms">
        
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>