<template>
    <div>
        <input type="hidden" id="idSearch" v-model="id"/>  
        <span id="1" @click="selectId(1)">icon-1</span>        
        <span id="2" @click="selectId(2)">icon-2</span>       
    </div>
  </template>
  
  <script>
  export default {
      data() {
          return {
            id: null
          }
      },
      methods: {
        selectId(id) {
            this.id = id
            console.log('我要选择')
        }
      }
  }
  </script>
  
  <style>
  .layui-layer-iframe .layui-layer-btn .layui-layer-btn0 {
    font-size: 12px;
    background-color: #009688;
    border-color: #1b813d;
  }
  .layui-layer-btn1 {
      font-size: 12px;
  }
  </style>