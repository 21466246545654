<template>
    <s3-layer 
        class="layerBox"
        v-model="visibleBox" 
        :title="title" 
        area="450px" 
        btnAlign="c"
        :btn="['保存数据', '取消编辑']"
        @yes="btnYes"
        @btn2="btnCancel"
    >
      
        <div class="w-full  p-2">
            <div class="text-ms text-right text-red-700" v-show="show.level">
                {{ err.level }}
            </div>
            <div class="flex">
                <button class="w-[140px] text-right z-10  px-4 py-1 text-ms text-zinc-700 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    层级
                </button>
                <select
                        v-model="input.level"
                        class="w-11/12  text-ms focus:outline-none focus:text-gray-600 py-2 pl-2 pr-6"
                    >
                    <option v-for="(value, key) in api.grade" v-bind:key="key" :value="value.grade_id" class="text-ms">
                        {{ value.name }}
                    </option>
                </select>
            </div>
        </div>


        <div class="w-full  p-2">
            <div class="text-ms text-right text-red-700" v-show="show.level">
                {{ err.level }}
            </div>
            <div class="flex">
                <button class="w-[140px] text-right z-10  px-4 py-1 text-ms text-zinc-700 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    商品
                </button>
                <select
                        v-model="input.commodity_id"
                        class="w-11/12  text-ms focus:outline-none focus:text-gray-600 py-2 pl-2 pr-6"
                    >
                    <option v-for="(value, key) in api.commodity" v-bind:key="key" :value="value.commodity_id" class="text-ms">
                        {{  value.commodity_id   }} - {{  value.title_vi   }} 
                    </option>
                </select>
            </div>
        </div>

        <div class="w-full  p-2">
            <div class="text-ms text-right text-red-700" v-show="show.price">
                {{ err.price }}
            </div>
            <div class="flex">
                <button class="w-[150px] text-right z-10  px-4 py-1 text-ms text-zinc-700 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    价格
                </button>
                <input
                    name="price"
                    type="text"
                    class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border-zinc-300 dark:border-zinc-300 dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-400 dark:text-zinc-700"
                    :placeholder="tip.price"
                    v-model="input.price"
                />
                <!-- disabled -->
            </div>
        </div>


        <div class="w-full  p-2">
            <div class="text-ms text-right text-red-700" v-show="show.amount">
                {{ err.amount }}
            </div>
            <div class="flex">
                <button class="w-[150px] text-right z-10  px-4 py-1 text-ms text-zinc-700 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    数量
                </button>
                <input
                    name="amount"
                    type="text"
                    class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border-zinc-300 dark:border-zinc-300 dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-400 dark:text-zinc-700"
                    :placeholder="tip.amount"
                    v-model="input.amount"
                />
                <!-- disabled -->
            </div>
        </div>

    </s3-layer>
</template>

<script>
import { useI18n } from 'vue-i18n'
import axios from 'axios'
import { layer } from "vue3-layer"

import postPages from "@/assets/js/postPages"
import { toRaw } from '@vue/reactivity'

export default {
    data() {
        return {
            lang: '',
            title: '添加会员层级',
            visibleBox: false,

            levels: [],
            // 是否显示错误提示
            show: {
                level: false,
                commodity: false,
                price: false,
                amount: false,
            },
            // 错误提示信息
            err: {
                level: '',
                commodity: '',
                price: '',
                amount: '',
            },
            // 表单提示
            tip: {
                level: '必须选择',
                commodity: '必须选择',
                price: '请输入价格',
                amount: '请输入数量',
            },
            // 表单数据
            input: {
                level: 1,
                commodity_id: 1,
                price: '',
                amount: '',
            },
            api: {
                commodity: [],
                grade: [],
            }
        }
    },
    inject: ["fatherGetData"],
    mounted() {
        const { locale } = useI18n()
        this.lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-cn'
        locale.value = this.lang
        return {}
    },
    methods: {
        switchShow() {
            this.visibleBox = true
            this.getSelect()
        },
        getSelect()
        {
            // 获取供选择的数据项
            // 如何打包搜索参数？  &p=${p}
            this.searchParams = `&commodity_id=${this.lang}`
            const page = postPages(this.lang, localStorage.getItem('token'), '/api/x/get/all/commodity', this.ps, this.p, this.searchParams)
            // 将异步获取的数据导入 VM 数据
            page.data.then(res =>{
                console.log( res )
                const data = toRaw(res)
                this.api.commodity = data.data.commodity
                data.data.grade.forEach((element) => {
                    if (1===element.level) element.name = '一级'
                    if (2===element.level) element.name = '二级'
                    if (3===element.level) element.name = '三级'
                    if (4===element.level) element.name = '四级'
                    if (5===element.level) element.name = '五级'
                    if (6===element.level) element.name = '六级'
                })
                this.api.grade = data.data.grade
            })
        },
        btnYes()
        {
            this.visibleBox = false
            axios.post(
                '/api/x/add/level', 
                {
                    level: this.input.level,
                    commodity_id: this.input.commodity_id,
                    price: this.input.price,
                    amount: this.input.amount,
                },
                {
                    headers: {
                        // 'Content-Type': 'multipart/form-data'
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': this.lang,
                        'token': localStorage.getItem('token')
                    }
                }
            )
            .then((response) =>  {
                // console.log(response)
                if (0===response.data.code) {
                    layer.msg(response.data.msg)
                    // 创建成功，可通还要创建，所以先清空。
                    this.deoxidation()
                    this.fatherGetData()
                } else {
                    this.visibleBox = true
                    // const word = response.data.data.id
                    // const capitalized = 'showTip' + word.charAt(0).toUpperCase() + word.slice(1)
                    // const tip = 'tip' + word.charAt(0).toUpperCase() + word.slice(1)
                    // // 转成国际化 后端转提示语 前端转表单名称
                    // this[tip] = this.$t('filed.position.'+response.data.data.id) + ' ' + response.data.msg 
                    // this[capitalized] = true
                }
            })
            .catch(function (error) {
                console.log(error)
            })
        },
        btnCancel()
        {
            this.visibleBox = false
        },

        // 提交后还原提交的表单信息
        deoxidation() {
            this.input.price = ''
            this.input.amount = ''
        },
        // 提前时清空错误提示
        deoxidationTip() {

        }

    } // End methods(...)
}
</script>

<style>

</style>