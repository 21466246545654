<template>
    <s3-layer 
        class="layerBox"
        v-model="visibleBox" 
        :title="title" 
        area="450px" 
        btnAlign="c"
        :btn="['保存数据', '取消编辑']"
        @yes="btnYes"
        @btn2="btnCancel"
    >
      
        <!-- username -->
        <div class="w-full  p-2">
            <div class="text-ms text-right text-red-700" v-show="show.username">
                {{ err.username }}
            </div>
            <div class="flex">
                <button class="w-[150px] text-right z-10  px-4 py-1 text-ms text-emerald-600 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    用户名
                </button>
                <input
                    type="text"
                    class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border border-zinc-200"
                    :placeholder="tip.username"
                    v-model="input.username"
                />
                <!-- disabled -->
            </div>
        </div><!-- /End username -->


        <!-- phone -->
        <div class="w-full  p-2">
            <div class="text-ms text-right text-red-700" v-show="show.phone">
                {{ err.phone }}
            </div>
            <div class="flex">
                <button class="w-[150px] text-right z-10  px-4 py-1 text-ms text-emerald-600 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    手机号码
                </button>
                <input
                    type="text"
                    class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border border-zinc-200"
                    :placeholder="tip.phone"
                    v-model="input.phone"
                />
                <!-- disabled -->
            </div>
        </div><!-- /End phone -->

        <!-- Password -->
        <div class="w-full  p-2">
            <div class="text-ms text-right text-red-700" v-show="show.password">
                {{ err.password }}
            </div>
            <div class="flex">
                <button class="w-[150px] text-right z-10  px-4 py-1 text-ms text-emerald-600 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    登录密码
                </button>
                <input
                    type="text"
                    class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border border-zinc-200"
                    :placeholder="tip.password"
                    v-model="input.password"
                />
                <!-- disabled -->
            </div>
        </div><!-- /End Password -->


        <div class="w-full  p-2">
            <div class="text-ms text-right text-red-700" v-show="show.sir_id">
                {{ err.sir_id }}
            </div>
            <div class="flex">
                <button class="w-[150px] text-right z-10  px-4 py-1 text-ms text-emerald-600 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    上级ID
                </button>
                <input
                    type="text"
                    class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border border-zinc-200"
                    :placeholder="tip.sir_id"
                    v-model="input.sir_id"
                />
                <!-- disabled -->
            </div>
        </div>

        <!-- /End agent -->
        <div class="w-full p-2">
            <div class="text-ms text-right text-red-700" v-show="show.is_agent">
                {{ err.is_agent }}
            </div>
            <div class="flex">
                <button class="w-[140px] text-right z-10  px-4 py-1 text-ms text-emerald-600 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    是否是代理
                </button>
                <select
                        v-model="input.is_agent"
                        class="w-11/12  text-ms focus:outline-none focus:text-gray-600 py-2 pl-2 pr-6 border border-zinc-200"
                    >
                    <option value="0" class="text-ms">
                        不是
                    </option>
                    <option value="1" class="text-ms">
                        是
                    </option>
                </select>
            </div>
        </div><!-- /End is agent -->

    </s3-layer>
</template>

<script>
import { useI18n } from 'vue-i18n'
import axios from 'axios'
import { layer } from "vue3-layer"

import postPages from "@/assets/js/postPages"
import { toRaw } from '@vue/reactivity'

export default {
    data() {
        return {
            lang: '',
            title: '添加会员',
            visibleBox: false,

            levels: [],
            // 是否显示错误提示
            show: {
                username: false,
                phone: false,
                password: false,
                sir_id: false,
                is_agent: false,
            },
            // 错误提示信息
            err: {
                username: '',
                phone: '',
                password: '',
                sir_id: '',
                is_agent: '',
            },
            // 表单提示
            tip: {
                username: '请输入用户名',
                phone: '请输入手机号',
                password: '请输入密码',
                sir_id: '请输入上级ID',
                is_agent: '',
            },
            // 表单数据
            input: {
                username: '',
                phone: '',
                password: '',
                sir_id: '',
                is_agent: '',
            },
            api: {
                commodity: [],
                grade: [],
            }
        }
    },
    inject: ["fatherGetData"],
    mounted() {
        const { locale } = useI18n()
        this.lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-cn'
        locale.value = this.lang
        return {}
    },
    methods: {
        switchShow() {
            this.visibleBox = true
            this.getSelect()
        },
        getSelect()
        {
            // 获取供选择的数据项
            // 如何打包搜索参数？  &p=${p}
            this.searchParams = `&commodity_id=${this.lang}`
            const page = postPages(this.lang, localStorage.getItem('token'), '/api/x/get/all/commodity', this.ps, this.p, this.searchParams)
            // 将异步获取的数据导入 VM 数据
            page.data.then(res =>{
                // console.log( res )
                const data = toRaw(res)
                this.api.commodity = data.data.commodity
                
                data.data.grade.forEach((element) => {
                    if (1===element.level) element.name = '一级'
                    if (2===element.level) element.name = '二级'
                    if (3===element.level) element.name = '三级'
                    if (4===element.level) element.name = '四级'
                    if (5===element.level) element.name = '五级'
                    if (6===element.level) element.name = '六级'
                })
                this.api.grade = data.data.grade
            })
        },
        btnYes()
        {
            // this.visibleBox = false
            this.deoxidationErr()
            axios.post(
                '/api/x/add/user', 
                {
                    username: this.input.username,
                    phone: this.input.phone,
                    password: this.input.password,
                    sir_id: this.input.sir_id ? this.input.sir_id : 0,
                    is_agent: this.input.is_agent,
                },
                {
                    headers: {
                        // 'Content-Type': 'multipart/form-data'
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': this.lang,
                        'token': localStorage.getItem('token')
                    }
                }
            )
            .then((response) =>  {
                // console.log(response)
                if (0===response.data.code) {
                    layer.msg(response.data.msg)
                    // 创建成功，可通还要创建，所以先清空。
                    this.deoxidation()
                    this.fatherGetData()
                } else {
                    // this.visibleBox = true
                    // const word = response.data.data.id
                    // const showId = 'show' + word.charAt(0).toUpperCase() + word.slice(1)
                    this.show[response.data.data.id] = true
                    // const tipId = 'tip' + word.charAt(0).toUpperCase() + word.slice(1)
                    // 转成国际化 后端转提示语 前端转表单名称
                    this.err[response.data.data.id] = this.$t('filed.position.'+response.data.data.id) + ' ' + response.data.msg 
                }
            })
            .catch(function (error) {
                console.log(error)
            })
        },
        btnCancel()
        {
            this.visibleBox = false
        },

        // 提交后还原提交的表单信息
        deoxidation() {
            this.input.username  = ''
            this.input.phone  = ''
            this.input.password  = ''
            this.input.sir_id = ''
            this.input.is_agent = ''
        },
        // 提前时清空错误提示
        deoxidationTip() {

        },
        deoxidationErr() {
            this.show.username = false
            this.show.phone = false
            this.show.password = false
            this.show.sir_id = false
            this.show.is_agent = false

            this.err.username = ''
            this.err.phone = ''
            this.err.password = ''
            this.err.sir_id = ''
            this.err.is_agent = ''
        }

    } // End methods(...)
}
</script>

<style>

</style>