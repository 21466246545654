<template>
    <!--  Main -->
    <div class="w-screen h-screen flex">

        <!-- Side bar 侧边栏 -->
        <!-- 侧边栏 w-[200px] 总固定宽度 200 px  -->
        <SideSar ref="refSideSar" />
        <!-- /End Side bar -->

        <!-- Main -->
        <div class="w-full h-full bg-gray-400">

            <!-- Main Header -->
            <MainHeader ref="refMainHeader" />
            <!--  /End Main Header -->

            <div class="h-[calc(100vh-80px)] bg-white">

                <!-- Row -->
                <div class="w-full h-[40px] bg-current">
                    <div class="grid grid-cols-2 gap-0 pb-2">
                        <!-- Row Left -->
                        <div class="left text-left">

                        </div>
                        <!-- Row Right -->
                        <div class="right text-right">
                           
                        </div>
                    </div>
                </div><!-- /End Row -->


                <!-- search -->
                <div class="w-full text-left mt-4 mx-4">

                </div><!-- /End search -->

                <!-- 数据 -->
                <div class="relative w-[calc(100vw-200px)] overflow-x-auto  p-2 mx-4 z-0 mb-10 h-[calc(100vh-120px)] overflow-y-scroll">
                    <table class="table-fixed text-ms text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr class="table-fixed whitespace-nowrap bg-[#f2f2f2] bg-[#f2f2f2] text-[#666] border border-[#e6e6e6] dark:border-[#e6e6e6]">
                                <th class="px-6 py-3 border-r border-[#e6e6e6]" >
                                    名称
                                </th>
                                <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">
                                    图标
                                </th>
                                <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">
                                    价格
                                </th>
                                <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">
                                    佣金比例
                                </th>
                                <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">
                                    抢单最小金额
                                </th>
                                <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">
                                    抢单最大金额
                                </th>
                                <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">
                                    接单次数
                                </th>
                                <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">
                                    提现次数
                                </th>
                                <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">
                                    提现最小金额
                                </th>
                                <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">
                                    提现最大金额
                                </th>
                                <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">
                                    层级任务
                                </th>
                                <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">
                                    自动升级需完成任务（单）
                                </th>
                                <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">
                                    冻结时间
                                </th>
                                <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">
                                    是否自动结算
                                </th>
                                <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">
                                    提现至少完成订单/天
                                </th>
                                <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">
                                    提现手续费
                                </th>
                                <th scope="col" class="px-6 py-3 text-right ">
                                    管理操作
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in grades" :key="item.grade_id" class="bg-white border border-[#e6e6e6] dark:border-[#e6e6e6] text-stone-600  whitespace-nowrap dark:text-stone-600 dark:bg-white hover:bg-[#f2f2f2] dark:hover:bg-[#f2f2f2]">
                                <td class="px-6 py-4 border-r border-[#e6e6e6]">
                                    {{ item.title }}<br />
                                    {{ item.title_vi }}
                                </td>
                                <td class="px-6 py-4  border-r border-[#e6e6e6]">
                                    {{ item.icon }}
                                </td>
                                <td class="px-6 py-4  border-r border-[#e6e6e6]">
                                    {{ item.price }}
                                </td>
                                <td class="px-6 py-4 font-medium border-r border-[#e6e6e6]">
                                    {{ item.rate }}
                                </td>
                                <td class="px-6 py-4 font-medium border-r border-[#e6e6e6]">
                                    {{ item.min }}
                                </td>
                                <td class="px-6 py-4 font-medium border-r border-[#e6e6e6]">
                                    {{ item.max }}
                                </td>
                                <td class="px-6 py-4 font-medium border-r border-[#e6e6e6]">
                                    {{ item.num_order }}
                                </td>
                                <td class="px-6 py-4 font-medium border-r border-[#e6e6e6]">
                                    {{ item.num_withdraw }}
                                </td>
                                <td class="px-6 py-4 font-medium border-r border-[#e6e6e6]">
                                    {{ item.min_withdraw }}
                                </td>
                                <td class="px-6 py-4 font-medium border-r border-[#e6e6e6]">
                                    {{ item.max_withdraw }}
                                </td>
                                <td class="px-6 py-4 font-medium border-r border-[#e6e6e6]">
                                    {{ item.level }}
                                </td>
                                <td class="px-6 py-4 font-medium border-r border-[#e6e6e6]">
                                    {{ item.tasks }}
                                </td>
                                <td class="px-6 py-4 font-medium border-r border-[#e6e6e6]">
                                    {{ item.freeze_time }}
                                </td>
                                <td class="px-6 py-4 font-medium border-r border-[#e6e6e6]">
                                    {{ item.is_automatically }}
                                </td>
                                <td class="px-6 py-4 font-medium border-r border-[#e6e6e6]">
                                    {{ item.tasks_withdraw }}
                                </td>
                                <td class="px-6 py-4 font-medium border-r border-[#e6e6e6]">
                                    {{ item.fee_withdrawal }}
                                </td>
                                <td class="px-6 py-4 float-right">
                                    <a href="#" @click="switchEdit(item)" :class="css.actionTeal">编辑</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div><!-- /End 数据-->

                <EditGrade  ref="refEditGrade" />
            </div>

        <!-- Main Footer -->
        <MainFooter />
        <!--  /End Main Footer -->

        </div>
    
    </div><!-- /End Main -->
</template>

<script>
import { layer } from "vue3-layer"
import SideSar from "@/components/SideSar.vue"
import MainHeader from "@/components/MainHeader.vue"
import MainFooter from "@/components/MainFooter.vue"
import EditGrade from "@/components/EditGrade.vue"

// page.a
import handlePagination from "@/assets/js/handlePagination"
import postPages from "@/assets/js/postPages"
import format from '@/assets/js/formatDate'

export default {
    components: {
        SideSar,
        MainHeader,
        MainFooter,
        layer,
        EditGrade,
    },
    setup() {
        // page.b
        const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-cn'
        const handlePaginationValue = handlePagination()
        const formatFunc = format()
        return { ...handlePaginationValue, ...formatFunc, lang }
    },
    data() {
        return {
            showDropDown: false,
            showSideBar: true,
            visibleBox: false,
            // page.c
            showPageBtn: false,
            pageData: [],
            pageTotal: 0,
            ps: 2,
            p: 1, // 当前请求第几页的数据
            pt: 0, // 总页数
            pageLinks: [],
            searchParams: '',
            // 数据搜索条件
            search: {
                title: '',
            },

            grades: [], 
            css: {
                main: 'h-full w-[calc(100vw-200px)] bg-gray-400',
                actionTeal: 'bg-teal-500 hover:bg-teal-600 rounded-sm transition-all text-white  inline-block text-xs p-1 font-semibold relative before:absolute before:bg-gradient-to-tr before:from-yellow-600 before:via-transparent before:to-green-700 before:inset-0 before:scale-110 before:rounded before:blur-md before:-z-10 ms-3',
                actionRed: 'bg-red-500 hover:bg-red-600 rounded-sm transition-all text-white  inline-block text-xs p-1 font-semibold relative before:absolute before:bg-gradient-to-tr before:from-yellow-600 before:via-transparent before:to-green-700 before:inset-0 before:scale-110 before:rounded before:blur-md before:-z-10 ms-3',
                actionGreen: 'bg-green-700 hover:bg-green-800 rounded-sm transition-all text-white  inline-block text-xs p-1 font-semibold relative before:absolute before:bg-gradient-to-tr before:from-yellow-600 before:via-transparent before:to-green-700 before:inset-0 before:scale-110 before:rounded before:blur-md before:-z-10 ms-3',
            }
        }
    },
    provide() {
        return {
            fatherGetToggleSideBar: this.toggleSideBar,
        }
    },
    mounted() {
        // layer.msg("用户快捷菜单")
        // 获取数据，并构建分页
        this.getData()
        return {}
    },
    methods: {
        // hide show side bar 
        toggleSideBar() {
            // console.warn('侧边栏开关')
            // 如果 this.showSideBar 是真就取假，如果 this.showSideBar 是假就取真
            // this.showSideBar = !this.showSideBar
            // 这个方法已经移到子组件中了，直接调用子组件来完成。前提是在模板中使用组件时，在组件中，要先为组件声明 ref 才可以使用，比如这里声明为  refSideSar 通过 refSideSar 直接调用子组件中的方法。
            this.$refs.refSideSar.switchSideBar()
        },
        // toggle user
        toggleDrop() {
            // console.info('用户快捷菜单')
            // 如果 this.showDropDown 是真就取假，如果 this.showDropDown 是假就取真
            this.showDropDown = !this.showDropDown
        },
        switchEdit(info) {
            // 用来确发子组件中的方法
            this.$refs.refEditGrade.switchShow(info)
        },
        getData()
        {
            // 如何打包搜索参数？  &p=${p}
            this.searchParams = `&title=${this.search.title}`

            // 发起请求时 要先隐藏分页按钮
            this.showPageBtn = false
            // positionPage 方法是通过 JS 文件导入的
            const page = postPages(this.lang, localStorage.getItem('token'), '/api/x/get/all/grades', this.ps, this.p, this.searchParams)
            // 将异步获取的数据导入 VM 数据
            page.data.then(res =>{
                if (0===res.code) {
                    this.grades = res.data.grade
                    // this.pageTotal = res.total
                    // // 这是另一个文件导入的方法 在 setup() 中初始化的分页助手 帮我快速生成分页工具的函数 
                    // this.pageInfo(this.pageTotal, this.ps, this.p)
                    // this.pageLinks = this.pageLink(this.pageTotal, this.ps, this.p)
                    // this.pt = Math.ceil(this.pageTotal/this.ps)
                    // 请求结果已经回来 才可以显示分页按钮
                    this.showPageBtn = true
                } else {
                    layer.msg(res.msg)
                }

            })
        },
    }
}
</script>

<style>

</style>