<template>
    <s3-layer 
        class="layerBox"
        v-model="visibleBox" 
        :title="title" 
        area="450px" 
        btnAlign="c"
        :btn="['保存数据', '取消编辑']"
        @yes="btnYes"
        @btn2="btnCancel"
    >
      
    

 


        <!-- level -->
        <div class="w-full  p-2">
            <div class="text-ms text-right text-red-700"  v-show="show.level">
                {{ err.level }}
            </div>
            <div class="flex">
                <button class="w-[140px] text-right z-10  px-4 py-1 text-ms text-emerald-600 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    层级
                </button>
                <select
                        v-model="input.level"
                        class="w-11/12  text-ms focus:outline-none focus:text-gray-600 py-2 pl-2 pr-6 border border-zinc-200 bg-gray-50"
                    >
                    <option v-for="(value, key) in api.grade" v-bind:key="key" :value="value.grade_id" class="text-ms">
                        {{ value.name }}
                    </option>
                </select>
            </div>
        </div><!-- /End level -->

        

        <!-- commodity_id -->
        <div class="w-full  p-2">
            <div class="text-ms text-right text-red-700"  v-show="show.commodity_id">
                {{ err.commodity_id }}
            </div>
            <div class="flex">
                <button class="w-[140px] text-right z-10  px-4 py-1 text-ms text-emerald-600 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    商品
                </button>
                <select
                        v-model="input.commodity_id"
                        class="w-11/12  text-ms focus:outline-none focus:text-gray-600 py-2 pl-2 pr-6 border border-zinc-200 bg-gray-50"
                    >
                    <option v-for="(value, key) in api.commodity" v-bind:key="key" :value="value.commodity_id" class="text-ms">
                        {{  value.commodity_id   }} - {{  value.title_vi   }} 
                    </option>
                </select>
            </div>
        </div><!-- /End commodity_id -->


        <!-- price -->
        <div class="w-full  p-2">
            <div class="text-ms text-right text-red-700"  v-show="show.price">
                {{ err.price }}
            </div>
            <div class="flex">
                <button class="w-[150px] text-right z-10  px-4 py-1 text-ms text-emerald-600 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    价格
                </button>
                <input
                    type="text"
                    class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border border-zinc-200"
                    v-model="input.price"
                />
                <!-- disabled -->
            </div>
        </div><!-- /End price -->


       


        <!-- amount -->
        <div class="w-full  p-2">
            <div class="text-ms text-right text-red-700"  v-show="show.amount">
                {{ err.amount }}
            </div>
            <div class="flex">
                <button class="w-[150px] text-right z-10  px-4 py-1 text-ms text-emerald-600 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    数量
                </button>
                <input
                    type="text"
                    class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border border-zinc-200"
                    v-model="input.amount"
                />
                <!-- disabled -->
            </div>
        </div><!-- /End amount -->

    </s3-layer>
</template>

<script>
import axios from 'axios'
import { useI18n } from 'vue-i18n'
import { layer } from "vue3-layer"
import postPages from "@/assets/js/postPages"
import { toRaw } from '@vue/reactivity'

export default {
    data() {
        return {
            lang: '',
            title: '编辑管理帐号',
            visibleBox: false,
            id: '',
            show: {
                pass_login: false,
                pass_pay: false,
            },
            err: {
                pass_login: '',
                pass_pay: '',
            },
            input: {
                level: '',
                commodity_id: '',
                price: '',
                amount: '',
            },
            api: {
                commodity: [],
                grade: [],
            }
        }
    },
    inject: ["fatherGetData"],
    mounted() {
        const { locale } = useI18n()
        this.lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-cn'
        locale.value = this.lang
        return {}
    },
    methods: {
        selectFile(event) {
            this.form.icon = event.target.files[0];
        },
        switchShow(info) {
            this.visibleBox = true
            this.input = info
            this.getSelect()
        },
        getSelect()
        {
            // 获取供选择的数据项
            // 如何打包搜索参数？  &p=${p}
            this.searchParams = `&commodity_id=${this.lang}`
            const page = postPages(this.lang, localStorage.getItem('token'), '/api/x/get/all/commodity', this.ps, this.p, this.searchParams)
            // 将异步获取的数据导入 VM 数据
            page.data.then(res =>{
                
                const obj = toRaw(res)
                this.api.commodity = obj.data.commodity
                obj.data.grade.forEach((element) => {
                    if (1===element.level) element.name = '一级'
                    if (2===element.level) element.name = '二级'
                    if (3===element.level) element.name = '三级'
                    if (4===element.level) element.name = '四级'
                    if (5===element.level) element.name = '五级'
                    if (6===element.level) element.name = '六级'
                })
                this.api.grade = obj.data.grade
                // console.log( this.api.grade )
            })
        },
        btnYes()
        {
            this.deoxidationTip()
            axios.post(
                '/api/x/set/user/level', 
                {
                    level_id: this.input.level_id,
                    level: this.input.level,
                    commodity_id: this.input.commodity_id,
                    price: this.input.price,
                    amount: this.input.amount,
                },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        //   'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': this.lang,
                        'token': localStorage.getItem('token')
                    }
                }
            )
            .then((response) =>  {
                // console.log(response)
                if (0===response.data.code) {
                    this.visibleBox = false
                    layer.msg(response.data.msg)
                    // 创建成功，可能还要创建，所以先清空。
                    this.deoxidation()
                    // 创建成功，父组件要重新获取数据。
                    this.fatherGetData()
                } else if (2===response.data.code) {
                    layer.msg(response.data.msg)
                } else {
                    const word = response.data.data.id
                    const err = 'msg.err' + word.charAt(0).toUpperCase() + word.slice(1)
                    const tip = 'show.err' + word.charAt(0).toUpperCase() + word.slice(1)
                    // 转成国际化 后端转提示语 前端转表单名称
                    this[err] = this.$t('filed.position.'+response.data.data.id) + ' ' + response.data.msg 
                    this[tip] = true
                }
            })
            .catch(function (error) {
                console.log(error)
            })
        },
        btnCancel()
        {
            this.visibleBox = false
        },
        // 提交后还原提交的表单信息
        deoxidation() {
            this.id = 0
        },
        // 提前时清空错误提示
        deoxidationTip() {

        }
    }
}
</script>

<style>

</style>