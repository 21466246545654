<template>

    <s3-layer 
        class="layerBox"
        v-model="visibleBox" 
        :title="title" 
        area="500px" 
        btnAlign="c"
        :btn="['保存数据', '取消编辑']"
        @yes="yesOnSubmit"
        @btn2="btn2OnClick"
    >

        <div class="md:w-2/3 max-w-sm mx-auto">

            <div class="text-ms ml-[58px] text-red-700" v-show="showTipAccount">
                {{ tipAccount }}
            </div>
            <div class="w-full inline-flex border">
                <div class="pt-1 w-1/12 bg-gray-100 bg-opacity-50 ml-3 mr-3">
                    <svg fill="#666666" class="w-6 text-gray-400 mx-auto" stroke="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm-11.3 240.2c-2.9 4.8-8.1 7.8-13.7 7.8h-.3c-4.2 0-8.3 1.7-11.3 4.7l-5.7 5.7c-3.1 3.1-3.1 8.2 0 11.3l5.7 5.7c3 3 4.7 7.1 4.7 11.3V304c0 8.8-7.2 16-16 16h-6.1c-6.1 0-11.6-3.4-14.3-8.9l-22.6-45.2c-2.4-4.9-9-5.9-12.8-2.1l-19.5 19.5c-3 3-7.1 4.7-11.3 4.7H50.8C49.1 277.6 48 266.9 48 256c0-110.3 89.7-200 200-200 21.5 0 42.2 3.5 61.6 9.8l-50.2 38.5c-5.1 3.4-4.6 11.1 .9 13.8l10.8 5.4c5.4 2.7 8.8 8.3 8.8 14.3V216c0 4.4-3.6 8-8 8h-3.1c-3 0-5.8-1.7-7.2-4.4-1.6-3.1-6-3.3-7.8-.3l-17.4 29zM408 358.4c0 4.2-1.7 8.3-4.7 11.3l-9.6 9.6c-3 3-7.1 4.7-11.3 4.7h-15.2c-4.2 0-8.3-1.7-11.3-4.7l-13-13a26.8 26.8 0 0 0 -25.4-7l-21.3 5.3c-1.3 .3-2.6 .5-3.9 .5h-10.3c-4.2 0-8.3-1.7-11.3-4.7l-11.9-11.9a8 8 0 0 1 -2.3-5.7v-10.2c0-3.3 2-6.2 5-7.4l39.3-15.7c2-.8 3.9-1.8 5.6-3.1l23.7-16.9a8 8 0 0 1 4.6-1.5h12.1c3.2 0 6.2 1.9 7.4 4.9l5.4 12.9a4 4 0 0 0 3.7 2.5h3.8c1.8 0 3.4-1.2 3.8-2.9l4.2-14.5c.5-1.7 2.1-2.9 3.8-2.9h6.1c2.2 0 4 1.8 4 4v12.9c0 2.1 .8 4.2 2.3 5.7l11.9 11.9c3 3 4.7 7.1 4.7 11.3v24.6z"/>
                    </svg>
                </div>
                <input
                    name="title"
                    type="text"
                    class="w-11/12 text-ms focus:outline-none focus:text-gray-600 p-1 pl-2"
                    :placeholder="$t('filed.admin.account')"
                    v-model="form.account"
                />
            </div>
            

            <div class="text-ms ml-[58px] text-red-700" v-show="showTipPosition_id">
                {{ tipPosition_id }}
            </div>
            <div class="w-full inline-flex border">
                <div class="pt-1 w-1/12 bg-gray-100 bg-opacity-50 ml-3 mr-3">
                    <svg fill="#666666" class="w-6 text-gray-400 mx-auto" stroke="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M572.5 241.4C518.3 135.6 410.9 64 288 64S57.7 135.6 3.5 241.4a32.4 32.4 0 0 0 0 29.2C57.7 376.4 165.1 448 288 448s230.3-71.6 284.5-177.4a32.4 32.4 0 0 0 0-29.2zM288 400a144 144 0 1 1 144-144 143.9 143.9 0 0 1 -144 144zm0-240a95.3 95.3 0 0 0 -25.3 3.8 47.9 47.9 0 0 1 -66.9 66.9A95.8 95.8 0 1 0 288 160z"/>
                    </svg>
                </div>

                <select
                    name="position_id"
                    v-model="form.position_id"
                    class="w-11/12 h-[32px] text-ms focus:outline-none focus:text-gray-600 p-1 pl-2 pr-6"
                >
                    <option value="" selected disabled hidden>{{ $t('filed.admin.position') }}</option>
                    <option v-for="(value, key) in positions" v-bind:key="key" :value="value.position_id" class="text-ms">
                        {{  value.title   }} 
                    </option>
                </select>
            </div>

           

        </div>        
    </s3-layer>
</template>

<script>
import { useI18n } from 'vue-i18n'
import axios from 'axios'
import { layer } from "vue3-layer"
import getAllPositions from "@/assets/js/getAllPositions"
import { toRaw } from '@vue/reactivity'

export default {
    components: {
        
    },
    data() {
        return {
            lang: '',
            title: '创建管理帐号',
            visibleBox: false,
            txt: {
                title: '职务名称(中文)',
                title_vi: '职务名称(越南文)',
                title_en: '职务名称(英文)',
            },
            form: {
                account: '',
                position_id: ''
            },
            showTipTitle: false,
            tipTitle: '',
            showTipTitle_vi: false,
            tipTitle_vi: '',
            showTipTitle_en: false,
            tipTitle_en: '',
            showPosition_id: false,
            tipPosition_id: '',

            positions: [],
        }
    },
    inject: ["fatherGetData"],
    mounted() {
        const { locale } = useI18n()
        this.lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-cn'
        locale.value = this.lang
        return {}
    },
    methods: {
        getData()
        {
            const mAPI = getAllPositions(this.lang, localStorage.getItem('token'))
            // 将异步获取的数据导入 VM 数据
            mAPI.data.then(res =>{
                const list = toRaw(res)
                // let oJson = JSON.parse(JSON.stringify(res))
                // this.mysql = oJson.data
                // this.powers = list.data.powers
                // this.own =  list.data.own
                // console.log(list)
                this.positions = list.data.position
            })
        },
        switchShow() {
            this.visibleBox = true
            this.getData()
        },
        yesOnSubmit() {
            this.deoxidationTip()
            axios.post(
                '/api/x/add/admin/account', 
                {
                    account: this.form.account,
                    position_id: this.form.position_id
                },
                {
                    headers: {
                        // 'Content-Type': 'multipart/form-data'
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': this.lang,
                        'token': localStorage.getItem('token')
                    }
                }
            )
            .then((response) =>  {
                // console.log(response)
                if (0===response.data.code) {
                    layer.msg(response.data.msg)
                    // 创建成功，可能还要创建，所以先清空。
                    this.deoxidation()
                    // 创建成功，父组件要重新获取数据。
                    this.fatherGetData()
                } else {
                    const word = response.data.data.id
                    const capitalized = 'showTip' + word.charAt(0).toUpperCase() + word.slice(1)
                    const tip = 'tip' + word.charAt(0).toUpperCase() + word.slice(1)
                    // 转成国际化 后端转提示语 前端转表单名称
                    this[tip] = this.$t('filed.position.'+response.data.data.id) + ' ' + response.data.msg 
                    this[capitalized] = true
                }
            })
            .catch(function (error) {
                console.log(error)
            })
            // layer.msg("按钮被点击了")
            // this.visibleBox = false
        },
        btn2OnClick(index, layero){
            this.deoxidationTip()
            this.deoxidation()
            this.visibleBox = false
        },
       
        // 提交后还原提交的表单信息
        deoxidation() {
            this.form.account = ''
            this.form.position_id = ''
        },
        // 提前时清空错误提示
        deoxidationTip() {
            this.showTipAccount = false
            this.tipAccount = ''
            this.showTipPosition_id = false
            this.tipPosition_id = ''
        }
    }
}
</script>

<style>

</style>