<template>
    <div class="h-[50px] bg-gray-800 flex items-center shadow-sm px-[20px] w-full py-[10px] z-10 border-b border-slate-900">
        <!-- hide show side bar but -->
        <div class="cursor-pointer w-[30px]" @click="toggleSideBar"> <!-- 事件绑定一个函数 -->
            <!-- class="w-3 h-3" 为什么这个 svg 是 12x12 -->
            <svg class="w-3 h-3 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
            </svg>
        </div><!-- /End hide show side bar but -->
        <!-- Search -->
        <div class="w-[calc(100%-30px)] flex">
            <div class="w-[calc(100%-200px)] flex justify-center">
            </div>
        </div>
        <!-- user box -->
        <div class="w-[200px]">
            <div class="flex items-center justify-start space-x-4" @click="toggleDrop">
                <!-- <img class="w-8 h-8 rounded-full border-2 border-gray-50" src="" /> -->
                <div class="font-semibold dark:text-white text-left">
                    <div class="text-sm font-bold">admin</div>
                    <div class="text-xs font-thin text-gray-500 dark:text-gray-400">管理员</div>
                </div>
            </div>
            <!-- 弹出用户帐号快捷菜单 -->
            <div v-show="showDropDown" @mouseleave="handleMouseLeave" class="absolute right-[10px] z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                <div class="py-1 text-left" role="none">
                    <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-0">{{ $t('user.basicInformation') }}</a>
                    <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-1">{{ $t('user.securitySettings') }}</a>
                    <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-2">{{ $t('user.compressedRelease') }}</a>
                    <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-2">{{ $t('user.clearCache') }}</a>
                    <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-2" @click="out">{{ $t('user.logOut') }}</a>
                </div>
            </div><!-- /End 弹出用户帐号快捷菜单 -->
        </div>
    </div>
</template>

<script>
import router from "../router/index.js"
import { useI18n } from 'vue-i18n'
import axios from 'axios'

export default {
    data() {
        return {
            showDropDown: false
        }
    },
    setup() {
        return {}
    },
    mounted() {
        // 切换语言
        const I18n = useI18n()
	    const { locale } = useI18n()
        locale.value = 'zh-cn'
        return {}
    },
    inject: ["fatherGetToggleSideBar"],
    methods: {
        handleMouseLeave()
        {
            this.showDropDown = !this.showDropDown
        },
        toggleSideBar()
        {
            // 这个事件，想要控制另一个组件的数据。
            // console.log( '这个事件，想要控制另一个组件的数据' )
            this.fatherGetToggleSideBar()
        },
        // toggle user
        toggleDrop() {
            // 如果 this.showDropDown 是真就取假，如果 this.showDropDown 是假就取真
            this.showDropDown = !this.showDropDown
        },
        out() {
            axios.post(
                '/api/x/out', 
                {
                    token: localStorage.getItem('token')
                },
                {
                    headers: {
                        // 'Content-Type': 'multipart/form-data'
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': this.lang
                    }
                }
            )
            .then(function (response) {
                // console.log(response)
                if (0===response.data.code) {
                    localStorage.removeItem('token')
                    router.push({
                        path: response.data.data.url,
                        // GET 发起的参数
                        // query: {
                        //     msg: 'reg account'
                        // }
                    })
                } else {
                    Message.error(response.data.msg)
                }
            })
            .catch(function (error) {
                console.log(error)
            })
        }
    }
}
</script>

<style>

</style>