import { createApp } from 'vue'
import App from './App.vue'
// 进口样式
import './assets/css/app.css'
// 进口路由
import router from './router'
// 引入 VueWechatTitle
import VueWechatTitle from 'vue-wechat-title'
// 国际化
import i18n from './language'

// 弹出层
import { s3Layer } from 'vue3-layer'
import 'vue3-layer/dist/s3Layer.css'

// import VueTailwindDatepicker from "vue-tailwind-datepicker"

const app = createApp(App)
    // app.use(VueTailwindDatepicker)
    // 第三方组件全局注册
    app.component('s3-layer', s3Layer)
    // 第三方全局注册
    app.use(i18n)
    app.use(VueWechatTitle)
    app.use(router) // 进口路由后要在应用程序中使用
    app.mount('#app')
