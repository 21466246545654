<template>
    <s3-layer 
        class="layerBox"
        v-model="visibleBox" 
        :title="title" 
        area="450px" 
        btnAlign="c"
        :btn="['保存数据', '取消编辑']"
        @yes="btnYes"
        @btn2="btnCancel"
    >
      
        <div class="w-full p-2">
            <div class="text-ms text-right text-red-700" v-show="show.errTitle">
                {{ msg.errTitle }}
            </div>
            <div class="flex">
                <button class="w-[200px] text-right z-10  px-4 py-1 text-ms text-zinc-700 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    分类名称(中文)
                </button>
                <input
                    name="title"
                    type="text"
                    class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border-zinc-300 dark:border-zinc-300 dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-400 dark:text-zinc-700"
                    :placeholder="msg.tipTitle"
                    v-model="form.title"
                />
            </div>
        </div>

        <div class="w-full p-2">
            <div class="text-ms text-right text-red-700" v-show="show.errIntroduction">
                {{ msg.errIntroduction }}
            </div>
            <div class="flex">
                <button class="w-[200px] text-right z-10  px-4 py-1 text-ms text-zinc-700 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    分类简介(中文)
                </button>
                <input
                    name="title"
                    type="text"
                    class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border-zinc-300 dark:border-zinc-300 dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-400 dark:text-zinc-700"
                    :placeholder="msg.tipIntroduction"
                    v-model="form.introduction"
                />
            </div>
        </div>

        <div class="w-full p-2">
            <div class="text-ms text-right text-red-700" v-show="show.errTitle_vi">
                {{ msg.errTitle_vi }}
            </div>
            <div class="flex">
                <button class="w-[200px] text-right z-10  px-4 py-1 text-ms text-zinc-700 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    分类名称(越文)
                </button>
                <input
                    name="title"
                    type="text"
                    class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border-zinc-300 dark:border-zinc-300 dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-400 dark:text-zinc-700"
                    :placeholder="msg.tipTitle_vi"
                    v-model="form.title_vi"
                />
            </div>
        </div>

        <div class="w-full p-2">
            <div class="text-ms text-right text-red-700" v-show="show.errIntroduction_vi">
                {{ msg.errIntroduction_vi }}
            </div>
            <div class="flex">
                <button class="w-[200px] text-right z-10  px-4 py-1 text-ms text-zinc-700 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    分类简介(越文)
                </button>
                <input
                    name="title"
                    type="text"
                    class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border-zinc-300 dark:border-zinc-300 dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-400 dark:text-zinc-700"
                    :placeholder="msg.tipIntroduction_vi"
                    v-model="form.introduction_vi"
                />
            </div>
        </div>


        <div class="w-full p-2">
            <img :src="`${ form.icon }`" class="w-[40px] h-[40px]" />
        </div>


        <div class="w-full p-2">
            <div class="text-ms text-right text-red-700" v-show="show.errIcon">
                {{ msg.errIcon }}
            </div>
            <div class="flex">
                <button class="w-[200px] text-right z-10  px-4 py-1 text-ms text-zinc-700 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    分类LOGO
                </button>
                <input
                    name="icon"
                    type="file"
                    @change="selectFile"
                    class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border-zinc-300 dark:border-zinc-300 dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-400 dark:text-zinc-700"
                />
                <!-- disabled -->
            </div>
        </div>

      

    </s3-layer>
</template>

<script>
import axios from 'axios'
import { useI18n } from 'vue-i18n'
import { layer } from "vue3-layer"

import getAllPositions from '@/assets/js/getAllPositions'
import { toRaw } from '@vue/reactivity'

export default {
    data() {
        return {
            lang: '',
            title: '编辑管理帐号',
            visibleBox: false,
            id: 0,
            account: '',
            positions: [],
            role: 0,
            show: {
                errTitle: false,
                errIntroduction: false,
                errTitle_vi: false,
                errIntroduction_vi: false,
            },
            msg: {
                errTitle: '',
                errTitle_vi: '',
                errIntroduction: '',
                errIntroduction_vi: '',

                tipTitle: '请输入中文的商品分类名称',
                tipTitle_vi: '请输入越文的商品分类名称',
                tipIntroduction: '请输入中文的商品分类描述',
                tipIntroduction_vi: '请输入越文的商品分类描述',
            },
            form: {
                sort_id: '',
                title: '',
                title_vi: '',
                introduction: '',
                introduction_vi: '',
                icon: '',
            }
        }
    },
    inject: ["fatherGetData"],
    mounted() {
        const { locale } = useI18n()
        this.lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-cn'
        locale.value = this.lang
        return {}
    },
    methods: {
        selectFile(event) {
            this.form.icon = event.target.files[0];
        },
        switchShow(item) {
            this.visibleBox = true
            this.form.sort_id = item.sort_id
            this.form.icon = item.icon
            this.form.title = item.title
            this.form.title_vi = item.title_vi
            this.form.introduction = item.introduction
            this.form.introduction_vi = item.introduction_vi
        },
        btnYes()
        {
            this.deoxidationTip()
            axios.post(
                '/api/x/edit/sort', 
                {
                    sort_id: this.form.sort_id,
                    title: this.form.title,
                    title_vi: this.form.title_vi,
                    introduction: this.form.introduction,
                    introduction_vi: this.form.introduction_vi,
                    icon: this.form.icon,
                },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        //   'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': this.lang,
                        'token': localStorage.getItem('token')
                    }
                }
            )
            .then((response) =>  {
                // console.log(response)
                if (0===response.data.code) {
                    this.visibleBox = false
                    layer.msg(response.data.msg)
                    // 创建成功，可能还要创建，所以先清空。
                    this.deoxidation()
                    // 创建成功，父组件要重新获取数据。
                    this.fatherGetData()
                } else {
                    const word = response.data.data.id
                    const err = 'msg.err' + word.charAt(0).toUpperCase() + word.slice(1)
                    const tip = 'show.err' + word.charAt(0).toUpperCase() + word.slice(1)
                    // 转成国际化 后端转提示语 前端转表单名称
                    this[err] = this.$t('filed.position.'+response.data.data.id) + ' ' + response.data.msg 
                    this[tip] = true
                }
            })
            .catch(function (error) {
                console.log(error)
            })
        },
        btnCancel()
        {
            this.visibleBox = false
        },
        // 提交后还原提交的表单信息
        deoxidation() {
            this.id = 0
            this.account = ''
            this.positions = []
            this.role = 0
        },
        // 提前时清空错误提示
        deoxidationTip() {
            this.show.tipAccount = true
            this.show.errAccount = false
            this.show.tipPosition = true
            this.show.errPosition = false
            this.msg.errAccount = ''
            this.msg.errPosition = ''
        }
    }
}
</script>

<style>

</style>