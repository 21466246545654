<template>
    <!--  Main -->
    <div class="w-screen h-screen flex">

        <!-- Side bar 侧边栏 -->
        <!-- 侧边栏 w-[200px] 总固定宽度 200 px  -->
        <SideSar ref="refSideSar" />
        <!-- /End Side bar -->

        <!-- Main -->
        <div :class="css.main">

            <!-- Main Header -->
            <MainHeader ref="refMainHeader" />
            <!--  /End Main Header -->

            <div class="h-[calc(100vh-80px)] bg-white z-0">

                <!-- Row -->
                <div class="w-full h-[40px] bg-current">
                    <div class="grid grid-cols-2 gap-0 pb-2">
                        <!-- Row Left -->
                        <div class="left text-left">

                        </div>
                        <!-- Row Right -->
                        <div class="right text-right">
                            
                        </div>
                    </div>
                </div><!-- /End Row -->


                <!-- search -->
                <div class="w-full text-left mt-4 mx-4 z-0">
                    <!-- search date -->
                    <div class="w-[200px] inline-block p-2">
                        <div class="flex">
                            <button id="states-button"  class="flex-shrink-0 z-0 inline-flex items-center border-r-0 bg-zinc-200 dark:bg-zinc-200 px-4 py-1 text-ms font-medium text-center text-zinc-700 bg-transparent border border-zinc-300 dark:border-zinc-300  hover:bg-transparent  dark:bg-transparent dark:hover:bg-transparent  dark:text-zinc-700 dark:border-gray-600" type="button">
                                注册时间 
                            </button>
                            <vue-tailwind-datepicker :formatter="formatter" v-model="dateValue" i18n="zh-cn"/>
                        </div>
                    </div><!-- /End search date -->

                    <div class="w-[200px] inline-block p-2">
                        <div class="flex">
                            <button id="states-button"  class="flex-shrink-0 z-10 inline-flex items-center border-r-0 bg-zinc-200 dark:bg-zinc-200 px-4 py-1 text-ms font-medium text-center text-zinc-700 bg-transparent border border-zinc-300 dark:border-zinc-300  hover:bg-transparent  dark:bg-transparent dark:hover:bg-transparent  dark:text-zinc-700 dark:border-gray-600" type="button">
                                用户名
                            </button>
                            <input
                                name="title"
                                type="text"
                                class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border-zinc-300 dark:border-zinc-300 dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-400 dark:text-zinc-700"
                                placeholder=""
                                v-model="username"
                            />
                        </div>
                    </div>

                    <div class="w-[200px] inline-block p-2">
                        <div class="flex">
                            <button id="states-button"  class="flex-shrink-0 z-10 inline-flex items-center border-r-0 bg-zinc-200 dark:bg-zinc-200 px-4 py-1 text-ms font-medium text-center text-zinc-700 bg-transparent border border-zinc-300 dark:border-zinc-300  hover:bg-transparent  dark:bg-transparent dark:hover:bg-transparent  dark:text-zinc-700 dark:border-gray-600" type="button">
                                手机号码
                            </button>
                            <input
                                name="title"
                                type="text"
                                class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border-zinc-300 dark:border-zinc-300 dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-400 dark:text-zinc-700"
                                placeholder=""
                                v-model="phone"
                            />
                        </div>
                    </div>

                    <div class="w-[200px] inline-block p-2">
                        <div class="flex">
                            <button id="states-button"  class="flex-shrink-0 z-10 inline-flex items-center border-r-0 bg-zinc-200 dark:bg-zinc-200 px-4 py-1 text-ms font-medium text-center text-zinc-700 bg-transparent border border-zinc-300 dark:border-zinc-300  hover:bg-transparent  dark:bg-transparent dark:hover:bg-transparent  dark:text-zinc-700 dark:border-gray-600" type="button">
                                订单号
                            </button>
                            <input
                                name="title"
                                type="text"
                                class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border-zinc-300 dark:border-zinc-300 dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-400 dark:text-zinc-700"
                                placeholder=""
                                v-model="number"
                            />
                        </div>
                    </div>

                    <div class="w-[200px] inline-block p-2">
                        <div class="flex">
                            <button id="states-button"  class="flex-shrink-0 z-10 inline-flex items-center border-r-0 bg-zinc-200 dark:bg-zinc-200 px-4 py-1 text-ms font-medium text-center text-zinc-700 bg-transparent border border-zinc-300 dark:border-zinc-300  hover:bg-transparent  dark:bg-transparent dark:hover:bg-transparent  dark:text-zinc-700 dark:border-gray-600" type="button">
                                状态 
                            </button>
                            <select v-model="states"  class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border-zinc-300 dark:border-zinc-300 dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-400 dark:text-zinc-700">
                                <option value="" selected>全部状态</option>
                                <option value="0">待支付</option>
                                <option value="1">支付成功</option>
                                <option value="2">支付失败</option>
                            </select>
                        </div>
                    </div>

                    <div class="w-[200px] inline-block p-2">
                        <div class="flex">
                            <button @click="search"  class="flex-shrink-0 z-0 inline-flex items-center  bg-zinc-200 dark:bg-zinc-200 px-4 py-1 text-ms font-medium text-center text-zinc-700 bg-transparent border border-zinc-300 dark:border-zinc-300  hover:bg-transparent  dark:bg-transparent dark:hover:bg-transparent  dark:text-zinc-700 dark:border-gray-600" type="button">
                                搜索
                            </button>
                        </div>
                    </div>
                </div><!-- /End search -->

                <!-- 数据 -->
                <div class="relative overflow-x-auto h-[calc(100vh-230px)] overflow-y-scroll p-2 mx-4">
                    <table class="text-ms w-screen text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr class="table-fixed whitespace-nowrap bg-[#f2f2f2] text-[#666] border border-[#e6e6e6] dark:border-[#e6e6e6]">
                                <th class="px-6 py-3  border-r border-[#e6e6e6]">
                                    订单号
                                </th>
                                <th class="px-6 py-3  border-r border-[#e6e6e6]">
                                    上级代理
                                </th>
                                <th class="px-6 py-3  border-r border-[#e6e6e6]">
                                    用户名
                                </th>
                                <th class="px-6 py-3  border-r border-[#e6e6e6]">
                                    提现金额
                                </th>
                                <th class="px-6 py-3  border-r border-[#e6e6e6]">
                                    银行
                                </th>
                                <th class="px-6 py-3  border-r border-[#e6e6e6]">
                                    开户名
                                </th>
                                <th class="px-6 py-3  border-r border-[#e6e6e6]">
                                    银行卡号
                                </th>
                                <th class="px-6 py-3  border-r border-[#e6e6e6]">
                                    添加时间
                                </th>
                                <th class="px-6 py-3  border-r border-[#e6e6e6]">
                                    处理时间
                                </th>
                                <th class="px-6 py-3  border-r border-[#e6e6e6]">
                                    操作人
                                </th>
                                <th class="px-6 py-3  border-r border-[#e6e6e6]">
                                    提醒
                                </th>
                                <th class="px-6 py-3  border-r border-[#e6e6e6]">
                                    状态
                                </th>
                                <th class="px-6 py-3 text-right ">
                                    管理操作
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in pageData" :key="item.withdraw_id" class="bg-white border border-[#e6e6e6] dark:border-[#e6e6e6] text-stone-600  whitespace-nowrap dark:text-stone-600 dark:bg-white hover:bg-[#f2f2f2] dark:hover:bg-[#f2f2f2]">
                                <td class="px-6 py-4  border-r border-[#e6e6e6]">
                                    {{ item.number }}
                                </td>
                                <td class="px-6 py-4  border-r border-[#e6e6e6]">
                                    <span v-if="item.sir_id">
                                        {{ item.agent['username'] }}
                                    </span>
                                </td>
                                <td class="px-6 py-4  border-r border-[#e6e6e6]">
                                    {{ item.user['username'] }}
                                </td>
                                <td class="px-6 py-4  border-r border-[#e6e6e6]">
                                    {{ item.amount }}
                                </td>
                                <td class="px-6 py-4  border-r border-[#e6e6e6]">
                                    {{ banks[item.bank_id] }}
                                </td>
                                <td class="px-6 py-4  border-r border-[#e6e6e6]">
                                    {{ item.bank_user }}
                                </td>
                                <td class="px-6 py-4  border-r border-[#e6e6e6]">
                                    {{ item.bank_card }}
                                </td>
                                <td class="px-6 py-4  border-r border-[#e6e6e6]">
                                    {{ date(item.created_at) }} &nbsp; {{ time(item.created_at) }}
                                </td>
                                <td class="px-6 py-4  border-r border-[#e6e6e6]">
                                    <span v-show="item.updated_at">
                                    {{ date(item.updated_at) }} &nbsp; {{ time(item.updated_at) }}
                                    </span>
                                </td>
                                <td class="px-6 py-4  border-r border-[#e6e6e6]">
                                    <span v-if="item.admin_id">
                                        {{ item.admin['account'] }}
                                    </span>
                                </td>
                                <td class="px-6 py-4  border-r border-[#e6e6e6]">
                                    <span v-show="0===item.is_again" class="text-slate-700">首次提款</span>
                                    <span v-show="0<item.is_again"  class="text-rose-500">多次提款</span>
                                </td>
                                <td class="px-6 py-4  border-r border-[#e6e6e6]">
                                    <span v-show="0===item.status" class="text-rose-700">待付款</span>
                                    <span v-show="1===item.status"  class="text-green-500">打款成功</span>
                                    <span v-show="2===item.status"  class="text-slate-600">打款失败</span>
                                </td>
                                <td class="flex py-4  px-6  float-right">
                                    <a v-show="!item.status && item.butPass && 1000000>item.amount" href="#" @click="switchStatus(item.withdraw_id, 1, index)" :class="item.status ? css.actionRed  : css.actionGreen">
                                        通过
                                    </a>
                                    <a v-show="!item.status && item.butPass && 1000000<item.amount && 3000000>item.amount" href="#" @click="switchStatus2(item.withdraw_id, 1, index)" :class="item.status ? css.actionRed  : css.actionGreen">
                                        通过
                                    </a>
                                    <a v-show="!item.status && item.butPass && 3000000<=item.amount" href="#" @click="switchStatus3(item.withdraw_id, 1, index)" :class="item.status ? css.actionRed  : css.actionGreen">
                                        通过
                                    </a>
                                    <a v-show="!item.status && item.butTurnDown" href="#" @click="switchStatus(item.withdraw_id, 2, index)" :class="item.status ? css.actionRed  : css.actionGreen">
                                        驳回
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <!-- Row 分页条 -->
                    <div class="grid grid-cols-2 gap-0 pb-2 text-ms py-2 px-4">
                        <!-- Row Left -->
                        <div class="left text-left">
                            <span>{{ pageInfo(pageTotal, ps, p) }}</span>
                        </div>
                        <!-- Row Right -->
                        <div class="right text-right" v-show="showPageBtn">
                            <button @click="p=backPage(p);getData();">上一页</button>
                            <button
                                class="px-1"
                                    v-for="item in pageLinks"
                                    :key="item"
                                    @click="() => {p=item;getData();}"
                                >
                                {{ item }}
                            </button>
                            <button @click="p=nextPage(p,pt);getData();">下一页</button>
                        </div>
                    </div><!-- /End Row 分页条 -->

                </div><!-- /End 数据-->


                <CreateBank ref="refCreateBank" />
                <EditAmount ref="refEditAmount" />
                <EditUser ref="refEditUser" />
                <EditBankCard ref="refEditBankCard" />
                <EditPass ref="refEditPass" />
            </div>

            <!-- <div class="h-[100px] p-10"></div> -->

            <!-- Main Footer -->
            <MainFooter />
            <!--  /End Main Footer -->

        </div>
    
    </div><!-- /End Main -->
</template>

<script>
import axios from 'axios'
import { toRaw } from '@vue/reactivity'
import { layer } from "vue3-layer"
import SideSar from "@/components/SideSar.vue"
import MainHeader from "@/components/MainHeader.vue"
import MainFooter from "@/components/MainFooter.vue"
import CreateBank from '@/components/CreateBank.vue'
import EditAmount from '@/components/EditAmount.vue'
import EditUser from '@/components/EditUser.vue'
import EditPass from '@/components/EditPass.vue'
import EditBankCard from '@/components/EditBankCard.vue'


// page.a
import handlePagination from "@/assets/js/handlePagination"
import postPages from "@/assets/js/postPages"
import format from '@/assets/js/formatDate'

// 日期选择件
// code.admin/node_modules/vue-tailwind-datepicker/dist/vue-tailwind-datepicker.js 编辑 删除掉 py-2.5 rounded-lg 加上 py-1 outline-none dark:outline-none
// 删除 focus:ring focus:ring-vtd-primary-500 focus:ring-opacity-10 dark:focus:ring-opacity-20
import { ref } from "vue"
import VueTailwindDatepicker from "vue-tailwind-datepicker"
const dateValue = ref([])
const formatter = ref({
  date: "YYYY-MM-DD",
  month: "MMM",
})


export default {
    components: {
        SideSar,
        MainHeader,
        MainFooter,
        layer,
        CreateBank,
        VueTailwindDatepicker,
        EditAmount,
        EditUser,
        EditPass,
        EditBankCard,
    },
    setup() {
        // page.b
        const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-cn'
        const handlePaginationValue = handlePagination()
        const formatFunc = format()
        return { ...handlePaginationValue, ...formatFunc, lang }
    },
    data() {
        return {
            showDropDown: false,
            showSideBar: true,
            visibleBox: false,
            // page.c
            showPageBtn: false,
            pageData: [],
            pageTotal: 0,
            ps: 15,
            p: 1, // 当前请求第几页的数据
            pt: 0, // 总页数
            pageLinks: [],
            
            // 数据搜索条件
            dateValue,
            formatter,
            start: '',
            end: '',
            username: '',
            states: '',
            phone: '',
            number: '',
            searchParams: '',

            banks: [],
            css: {
                main: 'h-full w-[calc(100vw-200px)] bg-gray-400',
                actionTeal: 'bg-teal-500 hover:bg-teal-600 rounded-sm transition-all text-white  inline-block text-xs p-1 font-semibold relative before:absolute before:bg-gradient-to-tr before:from-yellow-600 before:via-transparent before:to-green-700 before:inset-0 before:scale-110 before:rounded before:blur-md before:-z-10 ms-3',
                actionRed: 'bg-red-500 hover:bg-red-600 rounded-sm transition-all text-white  inline-block text-xs p-1 font-semibold relative before:absolute before:bg-gradient-to-tr before:from-yellow-600 before:via-transparent before:to-green-700 before:inset-0 before:scale-110 before:rounded before:blur-md before:-z-10 ms-3',
                actionGreen: 'bg-green-700 hover:bg-green-800 rounded-sm transition-all text-white  inline-block text-xs p-1 font-semibold relative before:absolute before:bg-gradient-to-tr before:from-yellow-600 before:via-transparent before:to-green-700 before:inset-0 before:scale-110 before:rounded before:blur-md before:-z-10 ms-3',
            },
            show: {
                butPass: true,
                butReject: true,
            }
            
        }
    },
    provide() {
        return {
            fatherGetToggleSideBar: this.toggleSideBar,
            fatherGetData: this.getData,
        }
    },
    mounted() {
        // layer.msg("用户快捷菜单")
        // 获取数据，并构建分页
        this.getData()
        this.getBanks()
        return {}
    },
    methods: {
        // hide show side bar 
        toggleSideBar() {
            // console.warn('侧边栏开关')
            // 如果 this.showSideBar 是真就取假，如果 this.showSideBar 是假就取真
            // this.showSideBar = !this.showSideBar
            // 这个方法已经移到子组件中了，直接调用子组件来完成。前提是在模板中使用组件时，在组件中，要先为组件声明 ref 才可以使用，比如这里声明为  refSideSar 通过 refSideSar 直接调用子组件中的方法。
            let res = this.$refs.refSideSar.switchSideBar()
            // 这里要怎么切换 Main 区的宽度大小呢？
            // console.log( ' 这里要怎么切换 Main 区的宽度大小呢？ ' )
            // console.log( res )
            this.showSideBar = res
            switch(res) {
                case true:
                    this.css.main = 'h-full w-[calc(100vw-200px)] bg-gray-400'
                    break
                case false:
                    this.css.main = 'h-full w-full bg-gray-400'
                    break
            }
        },
        // toggle user
        toggleDrop() {
            // console.info('用户快捷菜单')
            // 如果 this.showDropDown 是真就取假，如果 this.showDropDown 是假就取真
            this.showDropDown = !this.showDropDown
        },
        getBanks() {
            this.searchParams = `&lang=${this.lang}`
            const page = postPages(this.lang, localStorage.getItem('token'), '/api/x/all/bank', this.ps, this.p, this.searchParams)
            let db = {}
            // 将异步获取的数据导入 VM 数据
            page.data.then(res =>{
                const aRes = toRaw(res)
                // 回圈 将对象某键值为下标 另一键值为值 供其它数据调用。
                aRes.data.list.forEach((element) => {
                    db[element.bank_id] = element.name
                })
                this.banks = db
            })
        },
        switchStatus(id, status, i)
        {
            this.pageData[i].butPass = false
            this.pageData[i].butTurnDown = false

            axios.post(
                '/api/x/approval/withdraw', 
                {
                    withdraw_id: id,
                    status, status
                },
                {
                    headers: {
                        // 'Content-Type': 'multipart/form-data'
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': this.lang,
                        'token': localStorage.getItem('token')
                    }
                }
            )
            .then((response) =>  {
                if (0===response.data.code) {
                    layer.msg(response.data.msg)
                } else {
                    layer.msg(response.data.msg)
                }
                this.getData()
            })
            .catch(function (error) {
                console.log(error)
            })
        },
        search()
        {
            // 如何打包搜索参数？  &p=${p}
            if (undefined!=this.dateValue[0] && ''!=this.dateValue[0] && 2===this.dateValue.length) this.start = this.dateValue[0]
            else this.start = ''
            if (undefined!=this.dateValue[1] && ''!=this.dateValue[1] && 2===this.dateValue.length) this.end = this.dateValue[1]
            else this.end = ''
            this.searchParams = `&start=${this.start}&end=${this.end}&account=${this.username}&status=${this.states}&phone=${this.phone}&number=${this.number}`
            this.getData()
        },
        getData()
        {
            // 如何打包搜索参数？  &p=${p}
            // this.searchParams = `&title=${this.search.title}`
            // 发起请求时 要先隐藏分页按钮
            this.showPageBtn = false
            // positionPage 方法是通过 JS 文件导入的
            const page = postPages(this.lang, localStorage.getItem('token'), '/api/x/page/withdraw', this.ps, this.p, this.searchParams)
            // 将异步获取的数据导入 VM 数据
            page.data.then(res =>{
                if (0===res.code) {

                    // 这个数据更新 只更新数据 原来的按钮 不要更新
                    // 因为当有一记录行 有管理操作时 操作完成 数据更新 之前在处理中被隐藏的按钮就会出现
                    res.data.items.forEach((element, index) => {
                        if ( Object.prototype.hasOwnProperty.call(element, 'butAgent') ) { // 判断对象的属性值是否存在
                            element.butPass = this.pageData[index].butPass // 通过
                            element.butTurnDown = this.pageData[index].butTurnDown // 驳回
                        } else {
                            element.butPass = true
                            element.butTurnDown = true
                        }
                    })

                    this.pageData = res.data.items
                    this.pageTotal = res.data.total
                    // 这是另一个文件导入的方法 在 setup() 中初始化的分页助手 帮我快速生成分页工具的函数 
                    this.pageInfo(this.pageTotal, this.ps, this.p)
                    this.pageLinks = this.pageLink(this.pageTotal, this.ps, this.p)
                    this.pt = Math.ceil(this.pageTotal/this.ps)
                    // 请求结果已经回来 才可以显示分页按钮
                    this.showPageBtn = true
                } else {
                    layer.msg(res.msg)
                }
            })
        },
        // 如果提现金额超过 100 万
        switchStatus2(id, status, i)
        {
            layer.confirm(
                "提现金额超过 100 万，确定给用户出金？",
                {
                    btn: ["确定出金", "放弃出金"],
                },
                () => {
                    this.switchStatus(id, status, i)
                },
                () => {
                    // 放弃后什么也不做
                }
            )
        }, // End switchStatus2
        // 如果提现金额超过 100 万
        switchStatus3(id, status, i)
        {
            layer.confirm(
                "提现金额超过 300 万，确定给用户出金？",
                {
                    btn: ["确定出金", "放弃出金"],
                },
                () => {

                    layer.confirm(
                        "提现金额超过 300 万，再次确定给用户出金？",
                        {
                            btn: ["确定出金", "放弃出金"],
                        },
                        () => {
                            this.switchStatus(id, status, i)
                        },
                        () => {
                            // 放弃后什么也不做
                        }
                    )

                },
                () => {
                    // 放弃后什么也不做
                }
            )
        }, // End switchStatus3
    }
}
</script>

<style>

</style>