<template>
    <!-- 第一个页面：控制台首页-仪表盘 -->
    <!--  Main -->
    <div class="w-screen h-screen flex">

        <!-- Side bar 侧边栏 -->
        <!-- 侧边栏 w-[200px] 总固定宽度 200 px  -->
        <SideSar ref="refSideSar" />
        <!-- /End Side bar -->

        <!-- Main -->
        <div class="w-full h-full bg-gray-400">

            <!-- Main Header -->
            <MainHeader ref="refMainHeader" />
            <!--  /End Main Header -->

            <div class="h-[calc(100vh-80px)] bg-white">

                <!-- Row -->
                <div class="w-full h-[40px] bg-current">
                    <div class="grid grid-cols-2 gap-0 pb-2">
                        <!-- Row Left -->
                        <div class="left text-left">

                        </div>
                        <!-- Row Right -->
                        <div class="right text-right">
                            <button @click="switchBox" type="button" class="bg-white hover:bg-white hover:border-green-400  focus:ring-blue-300  border   font-medium text-ms text-gray-90 hover:text-lime-700 mt-3 mr-8 px-1 yt-1 text-center inline-flex items-center me-2    dark:bg-white dark:hover:border-green-400 dark:focus:border-green-400  dark:focus:border ">
                                <svg class="w-3.5 h-3.5 me-2" aria-hidden="true" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm144 276c0 6.6-5.4 12-12 12h-92v92c0 6.6-5.4 12-12 12h-56c-6.6 0-12-5.4-12-12v-92h-92c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h92v-92c0-6.6 5.4-12 12-12h56c6.6 0 12 5.4 12 12v92h92c6.6 0 12 5.4 12 12v56z"/>
                                </svg>
                                添加帐号
                            </button>
                            <button @click="switchLayerTeam" type="button" class="bg-white hover:bg-white hover:border-green-400  focus:ring-blue-300  border   font-medium text-ms text-gray-900 hover:text-lime-700 mt-3 mr-8 px-1 yt-1 text-center inline-flex items-center me-2    dark:bg-white dark:hover:border-green-400 dark:focus:border-green-400  dark:focus:border ">
                                <svg class="w-3.5 h-3.5 me-2" aria-hidden="true" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zM124 296c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h264c6.6 0 12 5.4 12 12v56c0 6.6-5.4 12-12 12H124z"/>
                                </svg>
                                删除帐号
                            </button>
                        </div>
                    </div>
                </div><!-- /End Row -->

                <!-- search -->
                <div class="w-full text-left mt-4 mx-4">

                    <!-- 每个搜索项目，占200它固定宽度，上下左右内边留2rem，行内块 -->
                    <div class="w-[200px] inline-block p-2">
                        <div class="flex">
                            <button id="states-button"  class="flex-shrink-0 z-10 inline-flex items-center border-r-0 bg-zinc-200 dark:bg-zinc-200 px-4 py-1 text-ms font-medium text-center text-zinc-700 bg-transparent border border-zinc-300 dark:border-zinc-300  hover:bg-transparent  dark:bg-transparent dark:hover:bg-transparent  dark:text-zinc-700 dark:border-gray-600" type="button">
                                创建时间 
                            </button>
                            <vue-tailwind-datepicker :formatter="formatter" v-model="dateValue" i18n="zh-cn"/>
                        </div>
                    </div>
                    <div class="w-[200px] inline-block p-2">
                        <div class="flex">
                            <button id="states-button"  class="flex-shrink-0 z-10 inline-flex items-center border-r-0 bg-zinc-200 dark:bg-zinc-200 px-4 py-1 text-ms font-medium text-center text-zinc-700 bg-transparent border border-zinc-300 dark:border-zinc-300  hover:bg-transparent  dark:bg-transparent dark:hover:bg-transparent  dark:text-zinc-700 dark:border-gray-600" type="button">
                                职务名称 
                            </button>
                            <label for="states" class="sr-only">全部状态</label>
                            <input
                                name="title"
                                type="text"
                                class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border-zinc-300 dark:border-zinc-300 dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-400 dark:text-zinc-700"
                                placeholder=""
                                v-model="account"
                            />
                        </div>
                    </div>
                    <div class="w-[200px] inline-block p-2">
                        <div class="flex">
                            <button id="states-button"  class="flex-shrink-0 z-10 inline-flex items-center border-r-0 bg-zinc-200 dark:bg-zinc-200 px-4 py-1 text-ms font-medium text-center text-zinc-700 bg-transparent border border-zinc-300 dark:border-zinc-300  hover:bg-transparent  dark:bg-transparent dark:hover:bg-transparent  dark:text-zinc-700 dark:border-gray-600" type="button">
                                职务状态 
                            </button>
                            <label for="states" class="sr-only">全部状态</label>
                            <select id="states" v-model="states"  class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border-zinc-300 dark:border-zinc-300 dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-400 dark:text-zinc-700">
                                <option value="" selected>全部状态</option>
                                <option value="0">启用状态</option>
                                <option value="1">禁用状态</option>
                            </select>
                        </div>
                    </div>
                    
                    <div class="w-[200px] inline-block p-2">
                        <div class="flex">
                            <button @click="search" id="states-button"  class="flex-shrink-0 z-10 inline-flex items-center  bg-zinc-200 dark:bg-zinc-200 px-4 py-1 text-ms font-medium text-center text-zinc-700 bg-transparent border border-zinc-300 dark:border-zinc-300  hover:bg-transparent  dark:bg-transparent dark:hover:bg-transparent  dark:text-zinc-700 dark:border-gray-600" type="button">
                                搜索
                            </button>
                        </div>
                    </div>
                </div>

                <!-- 数据 -->
                <div class="relative overflow-x-auto  p-2 mx-4">
                    <table class="w-full text-ms text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr class="bg-[#f2f2f2] bg-[#f2f2f2] text-[#666] border border-[#e6e6e6] dark:border-[#e6e6e6]">
                                <th scope="col" class="p-4  border-r border-[#e6e6e6]">
                                    <div class="flex items-center">
                                        <input id="checkbox-all-search" type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                        <label for="checkbox-all-search" class="sr-only">checkbox</label>
                                    </div>
                                </th>
                                <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">
                                    管理帐号
                                </th>
                                <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">
                                    登录次数
                                </th>
                                <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">
                                    状态
                                </th>
                                <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">
                                    关联代理用户
                                </th>
                                <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">
                                    创建时间
                                </th>
                                <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">
                                    最后登录时间
                                </th>
                                <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">
                                    最后登录IP
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    管理操作
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in pageData" :key="item.position_id" class="bg-white border border-[#e6e6e6] dark:border-[#e6e6e6] text-stone-600  whitespace-nowrap dark:text-stone-600 dark:bg-white hover:bg-[#f2f2f2] dark:hover:bg-[#f2f2f2]">
                                <td class="w-4 p-4 border-r border-[#e6e6e6]">
                                    <div class="flex items-center">
                                        <input id="checkbox-table-search-1" type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 border-zinc-300  dark:bg-gray-700 dark:border-zinc-300">
                                        <label for="checkbox-table-search-1" class="sr-only">checkbox</label>
                                    </div>
                                </td>
                                <th class="px-6 py-4 font-medium border-r border-[#e6e6e6]">
                                    {{ item.account }}
                                </th>
                                <th class="px-6 py-4 font-medium border-r border-[#e6e6e6]">
                                    <span v-if="0<item.login">
                                        {{ item.login }}
                                    </span>
                                </th>
                                <th class="px-6 py-4 text-slate-600 border-r border-[#e6e6e6]">
                                    <span v-if="0===item.status">
                                        使用中
                                    </span>
                                    <span v-else>
                                        已禁用
                                    </span>
                                </th>
                                <th class="px-6 py-4 font-medium border-r border-[#e6e6e6]">
                                    <span v-if="0<item.user_id">
                                        {{ item.user_id }}
                                    </span>
                                </th>
                                <td class="px-6 py-4 border-r border-[#e6e6e6]">
                                    {{ date(item.created_at) }}<br />
                                    {{ time(item.created_at) }}
                                </td>
                                <th class="px-6 py-4 font-medium border-r border-[#e6e6e6]">
                                    <span v-if="0<item.login_ip">
                                        {{ date(item.login_at) }}<br />
                                        {{ time(item.login_at) }}
                                    </span>
                                </th>
                                <th class="px-6 py-4 font-medium border-r border-[#e6e6e6]">
                                    <span v-if="0<item.login_ip">{{ item.login_ip }}</span>
                                </th>
                                
                                <td class="flex items-center px-6 py-4">
                                    <a href="#" @click="switchBoxEdit(item.admin_id, item.account)" class="font-medium text-blue-600 dark:text-blue-500 hover:underline  ms-3">密码</a>
                                    <a href="#" @click="switchEditAccount(item.admin_id, item.account, item.position_id)" class="font-medium text-blue-600 dark:text-blue-500 hover:underline  ms-3">编辑</a>
                                    <a href="#" @click="stopAccount(item.admin_id)" class="font-medium text-blue-600 dark:text-blue-600 hover:underline ms-3">禁用</a>
                                    <a href="#" @click="deleteAccount(item.admin_id)" class="font-medium text-red-600 dark:text-red-500 hover:underline ms-3">删除</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <!-- Row 分页条 -->
                    <div class="grid grid-cols-2 gap-0 pb-2 text-ms py-2 px-4">
                        <!-- Row Left -->
                        <div class="left text-left">
                            <span>{{ pageInfo(pageTotal, ps, p) }}</span>
                        </div>
                        <!-- Row Right -->
                        <div class="right text-right" v-show="showPageBtn">
                            <button @click="p=backPage(p);getData();">上一页</button>
                            <button
                                class="px-1"
                                    v-for="item in pageLinks"
                                    :key="item"
                                    @click="() => {p=item;getData();}"
                                >
                                {{ item }}
                            </button>
                            <button @click="p=nextPage(p,pt);getData();">下一页</button>
                        </div>
                    </div><!-- /End Row 分页条 -->

                </div><!-- /End 数据 -->

                <CreateAccount ref="refLayer" />
                <EditPassword ref="refLayerEdit" />
                <EditAccountPosition ref="refLayerAccount" />
            </div>

            <!-- Main Footer -->
            <MainFooter />
            <!--  /End Main Footer -->

        </div><!-- /End Main -->
    </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { layer } from "vue3-layer"
import axios from 'axios'
import SideSar from "../../components/SideSar.vue"
import MainHeader from "../../components/MainHeader.vue"
import MainFooter from "../../components/MainFooter.vue"
import CreateAccount from "../../components/CreateAccount.vue"
import EditPassword from "../../components/EditPassword.vue"
import EditAccountPosition from "../../components/EditAccountPosition.vue"
// import axios from 'axios'
// 通过 @ 相对路径加载自定义的 可重复应用的 JS 函数
import handlePagination from "@/assets/js/handlePagination"
import postPages from "@/assets/js/postPages"
import format from '@/assets/js/formatDate'

// 日期选择件
// code.admin/node_modules/vue-tailwind-datepicker/dist/vue-tailwind-datepicker.js 编辑 删除掉 py-2.5 rounded-lg 加上 py-1 outline-none dark:outline-none
// 删除 focus:ring focus:ring-vtd-primary-500 focus:ring-opacity-10 dark:focus:ring-opacity-20
import { ref } from "vue"
import VueTailwindDatepicker from "vue-tailwind-datepicker"
const dateValue = ref([])
const formatter = ref({
  date: "YYYY-MM-DD",
  month: "MMM",
})




export default {
    components: {
        SideSar,
        MainHeader,
        MainFooter,
        layer,
        CreateAccount,
        EditPassword,
        EditAccountPosition,
        VueTailwindDatepicker
    },
    setup() {
        const route = useRoute()
        const position_id = route.query.positionId
        const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-cn'
        // 初始化分页捉手
        const handlePaginationValue = handlePagination()
        const formatFunc = format()
        return { ...handlePaginationValue, ...formatFunc, lang }
    },
    // computed 与 setup 同级
    computed: {

    },
    data() {
        return {
            showDropDown: false,
            showSideBar: true,
            visibleBox: false,
            // 分页
            showPageBtn: false,
            pageData: [],
            pageTotal: 0,
            ps: 2,
            p: 1, // 当前请求第几页的数据
            pt: 0, // 总页数
            pageLinks: [],
            searchParams: '',
            // 数据搜索条件
            dateValue,
            formatter,
            account: '',
            states: '',
            start: '',
            end: '',


            positions: [],

        }
    },
    provide() {
        return {
            fatherGetToggleSideBar: this.toggleSideBar,
            fatherGetData: this.getData,
        }
    },
    mounted() {
        // 获取数据，并构建分页
        this.getData()
    },
    // 
    methods: {
        
        // hide show side bar 
        toggleSideBar() {
            // console.warn('侧边栏开关')
            // 如果 this.showSideBar 是真就取假，如果 this.showSideBar 是假就取真
            // this.showSideBar = !this.showSideBar
            // 这个方法已经移到子组件中了，直接调用子组件来完成。前提是在模板中使用组件时，在组件中，要先为组件声明 ref 才可以使用，比如这里声明为  refSideSar 通过 refSideSar 直接调用子组件中的方法。
            this.$refs.refSideSar.switchSideBar()
        },
        // toggle user
        toggleDrop() {
            // console.info('用户快捷菜单')
            // 如果 this.showDropDown 是真就取假，如果 this.showDropDown 是假就取真
            this.showDropDown = !this.showDropDown
        },
        switchBox() {
            // 用来确发子组件中的方法
            this.$refs.refLayer.switchShow()
        },
        switchEditAccount(id, account, role) {
            // 用来确发子组件中的方法
            this.$refs.refLayerAccount.switchShow(id, account, role)
        },
        switchBoxEdit(id, account) {
            // 用来确发子组件中的方法
            this.$refs.refLayerEdit.switchShow(id, account)
        },
        getData()
        {
            // 如何打包搜索参数？  &p=${p}
            if (undefined!=this.dateValue[0] && ''!=this.dateValue[0]) this.start = this.dateValue[0]
            if (undefined!=this.dateValue[1] && ''!=this.dateValue[1]) this.end = this.dateValue[1]
            this.searchParams = `&start=${this.start}&end=${this.end}&account=${this.account}&status=${this.states}`

            // 发起请求时 要先隐藏分页按钮
            this.showPageBtn = false
            // positionPage 方法是通过 JS 文件导入的
            const page = postPages(this.lang, localStorage.getItem('token'), '/api/x/get/admins', this.ps, this.p, this.searchParams)
            // 将异步获取的数据导入 VM 数据
            
            page.data.then(res =>{
                // console.log(res)
                if (0===res.code) {
                    this.pageData = res.data.items
                    this.pageTotal = res.data.total
                    // 这是另一个文件导入的方法 在 setup() 中初始化的分页助手 帮我快速生成分页工具的函数 
                    this.pageInfo(this.pageTotal, this.ps, this.p)
                    this.pageLinks = this.pageLink(this.pageTotal, this.ps, this.p)
                    this.pt = Math.ceil(this.pageTotal/this.ps)
                    // 请求结果已经回来 才可以显示分页按钮
                    this.showPageBtn = true
                } else {
                    layer.msg(res.msg)
                }
            })
        },
        search()
        {
            this.getData()
        },
        stopAccount(id) 
        { 
            axios.post(
                '/api/x/set/admin/stop', 
                {
                    id: id,
                },
                {
                    headers: {
                        // 'Content-Type': 'multipart/form-data'
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': this.lang,
                        'token': localStorage.getItem('token')
                    }
                }
            )
            .then((response) =>  {
                // console.log(response)
                if (0===response.data.code) {
                    layer.msg(response.data.msg)
                } else {
                    layer.msg(response.data.msg)
                }
                this.getData()
            })
            .catch(function (error) {
                console.log(error)
            })

        },
        deleteAccount(id)
        {
            layer.confirm(
                "删除操作比较危险，您确定要删除？",
                {
                    btn: ["确定删除", "放弃删除"],
                },
                () => {
                    axios.post(
                        '/api/x/set/admin/delete', 
                        {
                            id: id,
                        },
                        {
                            headers: {
                                // 'Content-Type': 'multipart/form-data'
                                'Content-Type': 'application/x-www-form-urlencoded',
                                'Accept-Language': this.lang,
                                'token': localStorage.getItem('token')
                            }
                        }
                    )
                    .then((response) =>  {
                        // console.log(response)
                        if (0===response.data.code) {
                            layer.msg(response.data.msg)
                        } else {
                            layer.msg(response.data.msg)
                        }
                        this.getData()
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
                },
                () => {
                    // 放弃后什么也不做
                }
            )
        }
    }
}
</script>

<style>
  .layui-layer-iframe .layui-layer-btn .layui-layer-btn0, .layui-layer-dialog  .layui-layer-btn .layui-layer-btn0 {
    font-size: 12px;
    background-color: #009688;
    border-color: #1b813d;
  }
  .layui-layer-btn1 {
      font-size: 12px;
  }
</style>