<template>
    <s3-layer 
        class="layerBox"
        v-model="visibleBox" 
        :title="title" 
        :area="['450px', '400px']" 
        btnAlign="c"
        :btn="['保存数据', '取消编辑']"
        @yes="btnYes"
        @btn2="btnCancel"
    >
      
        <!-- grade -->
        <div class="w-full  p-2">
            <div class="flex">
                <button class="w-[150px] text-right z-10  px-4 py-1 text-ms text-emerald-600 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    会员等级
                </button>
                <input
                    type="text"
                    class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border border-zinc-200"
                    v-model="input.grade"
                    disabled
                />
            </div>
        </div><!-- /End grade -->

        <!-- price -->
        <div class="w-full  p-2">
            <div class="text-ms text-right text-red-700"  v-show="show.price">
                {{ err.price }}
            </div>
            <div class="flex">
                <button class="w-[150px] text-right z-10  px-4 py-1 text-ms text-emerald-600 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    升级价格
                </button>
                <input
                    type="text"
                    class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border border-zinc-200"
                    v-model="input.price"
                />
                <!-- disabled -->
            </div>
        </div><!-- /End price -->


        <!-- min -->
        <div class="w-full  p-2">
            <div class="text-ms text-right text-red-700"  v-show="show.min">
                {{ err.min }}
            </div>
            <div class="flex">
                <button class="w-[150px] text-right z-10  px-4 py-1 text-ms text-emerald-600 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    抢单最小金额
                </button>
                <input
                    type="text"
                    class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border border-zinc-200"
                    v-model="input.min"
                />
                <!-- disabled -->
            </div>
        </div><!-- /End min -->

        <!-- max -->
        <div class="w-full  p-2">
            <div class="text-ms text-right text-red-700"  v-show="show.max">
                {{ err.max }}
            </div>
            <div class="flex">
                <button class="w-[150px] text-right z-10  px-4 py-1 text-ms text-emerald-600 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    抢单最大金额
                </button>
                <input
                    type="text"
                    class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border border-zinc-200"
                    v-model="input.max"
                />
                <!-- disabled -->
            </div>
        </div><!-- /End max -->

        <!-- min_withdraw -->
        <div class="w-full  p-2">
            <div class="text-ms text-right text-red-700"  v-show="show.min_withdraw">
                {{ err.min_withdraw }}
            </div>
            <div class="flex">
                <button class="w-[150px] text-right z-10  px-4 py-1 text-ms text-emerald-600 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    提现最小金额
                </button>
                <input
                    type="text"
                    class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border border-zinc-200"
                    v-model="input.min_withdraw"
                />
                <!-- disabled -->
            </div>
        </div><!-- /End min_withdraw -->


        <!-- max_withdraw -->
        <div class="w-full  p-2">
            <div class="text-ms text-right text-red-700"  v-show="show.max_withdraw">
                {{ err.max_withdraw }}
            </div>
            <div class="flex">
                <button class="w-[150px] text-right z-10  px-4 py-1 text-ms text-emerald-600 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    提现最大金额
                </button>
                <input
                    type="text"
                    class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border border-zinc-200"
                    v-model="input.max_withdraw"
                />
                <!-- disabled -->
            </div>
        </div><!-- /End max_withdraw -->


        
        <!-- tasks -->
        <div class="w-full  p-2">
            <div class="text-ms text-right text-red-700"  v-show="show.tasks">
                {{ err.tasks }}
            </div>
            <div class="flex">
                <button class="w-[150px] text-right z-10  px-4 py-1 text-ms text-emerald-600 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    自动升级需完成任务（单）
                </button>
                <input
                    type="text"
                    class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border border-zinc-200"
                    v-model="input.tasks"
                />
                <!-- disabled -->
            </div>
        </div><!-- /End tasks -->


        <!-- tasks_withdraw -->
        <div class="w-full  p-2">
            <div class="text-ms text-right text-red-700"  v-show="show.tasks_withdraw">
                {{ err.tasks_withdraw }}
            </div>
            <div class="flex">
                <button class="w-[150px] text-right z-10  px-4 py-1 text-ms text-emerald-600 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    提现至少完成订单/天
                </button>
                <input
                    type="text"
                    class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border border-zinc-200"
                    v-model="input.tasks_withdraw"
                />
                <!-- disabled -->
            </div>
        </div><!-- /End tasks_withdraw -->

        <!-- fee_withdrawal -->
        <div class="w-full  p-2">
            <div class="text-ms text-right text-red-700"  v-show="show.fee_withdrawal">
                {{ err.fee_withdrawal }}
            </div>
            <div class="flex">
                <button class="w-[150px] text-right z-10  px-4 py-1 text-ms text-emerald-600 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    提现手续费
                </button>
                <input
                    type="text"
                    class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border border-zinc-200"
                    v-model="input.fee_withdrawal"
                />
                <!-- disabled -->
            </div>
        </div><!-- /End fee_withdrawal -->

        <!-- freeze_time -->
        <div class="w-full  p-2">
            <div class="text-ms text-right text-red-700"  v-show="show.freeze_time">
                {{ err.freeze_time }}
            </div>
            <div class="flex">
                <button class="w-[150px] text-right z-10  px-4 py-1 text-ms text-emerald-600 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    冻结时间(小时)
                </button>
                <input
                    type="text"
                    class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border border-zinc-200"
                    v-model="input.freeze_time"
                />
                <!-- disabled -->
            </div>
        </div><!-- /End freeze_time -->

        
        <!-- is_automatically -->
        <div class="w-full  p-2">
            <div class="text-ms text-right text-red-700"  v-show="show.is_automatically">
                {{ err.is_automatically }}
            </div>
            <div class="flex">
                <button class="w-[150px] text-right z-10  px-4 py-1 text-ms text-emerald-600 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    是否自动结算
                </button>
                <input
                    type="text"
                    class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border border-zinc-200"
                    v-model="input.is_automatically"
                />
                <!-- disabled -->
            </div>
        </div><!-- /End is_automatically -->

    
    </s3-layer>
</template>

<script>
import axios from 'axios'
import { useI18n } from 'vue-i18n'
import { layer } from "vue3-layer"
import postPages from "@/assets/js/postPages"
import { toRaw } from '@vue/reactivity'

export default {
    data() {
        return {
            lang: '',
            title: '编辑管理帐号',
            visibleBox: false,
            id: '',
            show: {
                pass_login: false,
                pass_pay: false,
            },
            err: {
                pass_login: '',
                pass_pay: '',
            },
            input: {
            },
            info: []
        }
    },
    inject: ["fatherGetData"],
    mounted() {
        const { locale } = useI18n()
        this.lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-cn'
        locale.value = this.lang
        return {}
    },
    methods: {
        selectFile(event) {
            this.form.icon = event.target.files[0];
        },
        switchShow(info) {
            this.visibleBox = true
            this.input = info
            switch(this.input.grade_id){
                case 1: 
                    this.input.grade = '六级'
                    break
                case 2: 
                    this.input.grade = '五级'
                    break
                case 3: 
                    this.input.grade = '四级'
                    break
                case 4: 
                    this.input.grade = '三级'
                    break
                case 5: 
                    this.input.grade = '二级'
                    break
                case 6: 
                    this.input.grade = '一级'
                    break
                default: 
                    this.input.grade = '六级'
                    break
            }
        },
        btnYes()
        {
            this.deoxidationTip()
            axios.post(
                '/api/x/set/user/grade', 
                {
                    grade_id: this.input.grade_id,
                    price: this.input.price,
                    min: this.input.min,
                    max: this.input.max,
                    min_withdraw: this.input.min_withdraw,
                    max_withdraw: this.input.max_withdraw,
                    tasks: this.input.tasks,
                    tasks_withdraw: this.input.tasks_withdraw,
                    fee_withdrawal: this.input.fee_withdrawal,
                    freeze_time: this.input.freeze_time,
                    is_automatically: this.input.is_automatically,
                },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        //   'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': this.lang,
                        'token': localStorage.getItem('token')
                    }
                }
            )
            .then((response) =>  {
                // console.log(response)
                if (0===response.data.code) {
                    this.visibleBox = false
                    layer.msg(response.data.msg)
                    // 创建成功，可能还要创建，所以先清空。
                    this.deoxidation()
                    // 创建成功，父组件要重新获取数据。
                    this.fatherGetData()
                } else {
                    const word = response.data.data.id
                    const err = 'msg.err' + word.charAt(0).toUpperCase() + word.slice(1)
                    const tip = 'show.err' + word.charAt(0).toUpperCase() + word.slice(1)
                    // 转成国际化 后端转提示语 前端转表单名称
                    this[err] = this.$t('filed.position.'+response.data.data.id) + ' ' + response.data.msg 
                    this[tip] = true
                }
            })
            .catch(function (error) {
                console.log(error)
            })
        },
        btnCancel()
        {
            this.visibleBox = false
        },
        // 提交后还原提交的表单信息
        deoxidation() {
            this.id = 0
        },
        // 提前时清空错误提示
        deoxidationTip() {

        }
    }
}
</script>

<style>

</style>