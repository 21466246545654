<template>
    <div class="child">
        <div class="wrapper">

            <div class="relative">
                <input
                    id="account"
                    v-model="form.account"
                    type="text"
                    class="peer m-0 block h-[58px] w-full rounded border border-solid border-secondary-500 bg-stone-600 bg-clip-padding px-3 py-4 text-base font-normal leading-tight text-neutral-200 transition duration-200 ease-linear placeholder:text-transparent focus:border-primary focus:pb-[0.625rem] focus:pt-[1.625rem] focus:text-neutral-700 focus:outline-none peer-focus:text-primary dark:border-neutral-400 dark:text-white dark:autofill:shadow-autofill dark:focus:border-primary dark:peer-focus:text-primary [&:not(:placeholder-shown)]:pb-[0.625rem] [&:not(:placeholder-shown)]:pt-[1.625rem]"
                    placeholder="" />
                <label
                    for="account"
                    class="pointer-events-none absolute left-0 top-0 origin-[0_0] border border-solid border-transparent px-3 py-4  transition-[opacity,_transform] duration-200 ease-linear peer-focus:-translate-y-2 peer-focus:translate-x-[0.15rem] peer-focus:scale-[0.85] peer-focus:text-primary peer-[:not(:placeholder-shown)]:-translate-y-2 peer-[:not(:placeholder-shown)]:translate-x-[0.15rem] peer-[:not(:placeholder-shown)]:scale-[0.85] motion-reduce:transition-none dark:text-neutral-400 dark:peer-focus:text-primary"
                    >帐号</label>
            </div>
            <div class="mt-1 mb-3 text-left text-xs">
                <span v-show="showTipAccount">{{ tipAccount }}</span>
            </div>

            <div class="relative">
                <input
                    id="password"
                    v-model="form.password"
                    type="password"
                    class="peer m-0 block h-[58px] w-full rounded border border-solid border-secondary-500 bg-stone-600 bg-clip-padding px-3 py-4 text-base font-normal leading-tight text-neutral-700 transition duration-200 ease-linear placeholder:text-transparent focus:border-primary focus:pb-[0.625rem] focus:pt-[1.625rem] focus:text-neutral-700 focus:outline-none peer-focus:text-primary dark:border-neutral-400 dark:text-white dark:autofill:shadow-autofill dark:focus:border-primary dark:peer-focus:text-primary [&:not(:placeholder-shown)]:pb-[0.625rem] [&:not(:placeholder-shown)]:pt-[1.625rem]"
                    placeholder="" />
                <label
                    for="password"
                    class="pointer-events-none absolute left-0 top-0 origin-[0_0] border border-solid border-transparent px-3 py-4  transition-[opacity,_transform] duration-200 ease-linear peer-focus:-translate-y-2 peer-focus:translate-x-[0.15rem] peer-focus:scale-[0.85] peer-focus:text-primary peer-[:not(:placeholder-shown)]:-translate-y-2 peer-[:not(:placeholder-shown)]:translate-x-[0.15rem] peer-[:not(:placeholder-shown)]:scale-[0.85] motion-reduce:transition-none dark:text-neutral-400 dark:peer-focus:text-primary"
                    >密码</label>
            </div>
            <div class="mt-1 mb-3 text-left text-xs">
                <span v-show="showTipPassword">{{ tipPassword }}</span>
            </div>

            <button @click="loginSubmit" class="btn text-base" type="button"><i class="animation"></i>登录<i class="animation"></i></button>
        </div>
    </div>
</template>


<script>
import axios from 'axios'

export default {
    data() {
        return {
            showTipAccount: false,
            showTipPassword: false,
            tipAccount: '',
            tipPassword: '',
            form: {
                account: '',
                password: ''
            }
        }
    },
    methods: {
        loginSubmit() {
            this.tipAccount = ''
            this.tipPassword = ''
            this.showTipAccount = false
            this.showTipPassword = false
            axios.post(
                '/api/x/login', 
                {
                    account: this.form.account,
                    password: this.form.password
                },
                {
                    headers: {
                        // 'Content-Type': 'multipart/form-data'
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': this.lang
                    }
                }
            )
            // .then(function (response) {
            .then((response) =>  {
                if (0===response.data.code) {
                    localStorage.setItem('token', response.data.data.token)
                    router.push({
                        path: '/user',
                        query: {
                            msg: 'reg account'
                        }
                    })
                } else {
                    // 先将表单的 Name 转成首字母大写
                    const word = response.data.data.id
                    const capitalized = 'showTip' + word.charAt(0).toUpperCase() + word.slice(1)
                    const tip = 'tip' + word.charAt(0).toUpperCase() + word.slice(1)
                    this[tip] = response.data.data.msg
                    this[capitalized] = true

                    // console.log( this[capitalized] )
                }
            })
            .catch(function (error) {
                console.log(error)
            })
        }
    }

}


</script>

<style>
        * {box-sizing: border-box;}
	    html, body { 
			padding:0; margin:0; border:0; width:100%; height:100%; overflow:hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 100vh;
		}
        body {
            background-image: url('/src/assets/img/bg3.jpeg') ;
            background-size: cover;
        }
	    /* {{-- 居中层 --}} */
		.child {
			/* background-color: #000 !important; */
            border-radius: 8px;
            width: 300px;
			height: 340px;
			position: absolute;
			top: 50%;
			left: 50%;
			margin-left: -150px;
			margin-top: -170px;
			z-index: 1;
			/* background: url('/image/bg-login2.png') no-repeat; */
		}
     	/* {{-- 表单层 --}} */
		.wrapper {
			color: #FFF;
			border-radius: 8px;
			backdrop-filter: blur(1px); /* {{-- 给 div.child 背影图打马赛克 --}} */
			padding: 30px 40px;
            background-color: #333333;
            background: linear-gradient(270deg, #198794 0%, #333333 100%); 
            /* background: url('/src/assets/img/bg9.jpeg') no-repeat;; */
		}

        /* button style */
        .wrapper .btn {
            width: 100%;
			height: 45px;
            outline: 0;
            display: inline-flex;
            align-items: center;
            justify-content: space-between;
            background: #40B3A2;
            min-width: 200px;
            border: 0;
            border-radius: 4px;
            box-shadow: 0 4px 12px rgba(0, 0, 0, .1);
            box-sizing: border-box;
            padding: 16px 20px;
            color: #fff;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 1.2px;
            text-transform: uppercase;
            overflow: hidden;
            cursor: pointer;
        }

        .wrapper .btn:hover {
            opacity: .95;
        }

        .wrapper .btn .animation {
            border-radius: 100%;
            animation: ripple 0.6s linear infinite;
        }

        @keyframes ripple {
            0% {
                box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1), 0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 40px rgba(255, 255, 255, 0.1), 0 0 0 60px rgba(255, 255, 255, 0.1);
            }

            100% {
                box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 40px rgba(255, 255, 255, 0.1), 0 0 0 60px rgba(255, 255, 255, 0.1), 0 0 0 80px rgba(255, 255, 255, 0);
            }
        }

</style>