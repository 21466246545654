<template>
    <!--  Main -->
    <div class="w-screen h-screen flex">

        <!-- Side bar 侧边栏 -->
        <!-- 侧边栏 w-[200px] 总固定宽度 200 px  -->
        <SideSar ref="refSideSar" />
        <!-- /End Side bar -->

        <!-- Main -->
        <div :class="css.main">

            <!-- Main Header -->
            <MainHeader ref="refMainHeader" />
            <!--  /End Main Header -->

            <div class="h-[calc(100vh-80px)] bg-white z-0">

                <!-- Row -->
                <div class="w-full h-[40px] bg-current">
                    <div class="grid grid-cols-2 gap-0 pb-2">
                        <!-- Row Left -->
                        <div class="left text-left">
                            
                            <router-link to="#" @click.native="$router.go(-2)">
                                返回上一页 ...
                            </router-link>
                        </div>
                        <!-- Row Right -->
                        <div class="right text-right">
                            <button @click="switchAddUser" type="button" class="bg-white hover:bg-white hover:border-green-400  focus:ring-blue-300  border   font-medium text-ms text-gray-90 hover:text-lime-700 mt-3 mr-8 px-1 yt-1 text-center inline-flex items-center me-2    dark:bg-white dark:hover:border-green-400 dark:focus:border-green-400  dark:focus:border ">
                                <svg class="w-3.5 h-3.5 me-2" aria-hidden="true" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm144 276c0 6.6-5.4 12-12 12h-92v92c0 6.6-5.4 12-12 12h-56c-6.6 0-12-5.4-12-12v-92h-92c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h92v-92c0-6.6 5.4-12 12-12h56c6.6 0 12 5.4 12 12v92h92c6.6 0 12 5.4 12 12v56z"/>
                                </svg>
                                添加会员
                            </button>
                        </div>
                    </div>
                </div><!-- /End Row -->


                <!-- search -->
                <div class="w-full text-left mt-4 mx-4">

                    <!-- search date -->
                    <div class="w-[200px] inline-block p-2">
                        <div class="flex">
                            <button id="states-button"  class="flex-shrink-0 z-10 inline-flex items-center border-r-0 bg-zinc-200 dark:bg-zinc-200 px-4 py-1 text-ms font-medium text-center text-zinc-700 bg-transparent border border-zinc-300 dark:border-zinc-300  hover:bg-transparent  dark:bg-transparent dark:hover:bg-transparent  dark:text-zinc-700 dark:border-gray-600" type="button">
                                帐变时间 
                            </button>
                            <vue-tailwind-datepicker :formatter="formatter" v-model="dateValue" i18n="zh-cn"/>
                        </div>
                    </div><!-- /End search date -->

                    <!-- search online -->
                    <div class="w-[200px] inline-block p-2">
                        <div class="flex">
                            <button i class="flex-shrink-0 z-10 inline-flex items-center border-r-0 bg-zinc-200 dark:bg-zinc-200 px-4 py-1 text-ms font-medium text-center text-zinc-700 bg-transparent border border-zinc-300 dark:border-zinc-300  hover:bg-transparent  dark:bg-transparent dark:hover:bg-transparent  dark:text-zinc-700 dark:border-gray-600" type="button">
                                类型 
                            </button>
                            <select id="searchSort" v-model="search.online" class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border-zinc-300 dark:border-zinc-300 dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-400 dark:text-zinc-700">
                                <option v-for="(value, key) in online" v-bind:key="key" :value="key" class="text-ms">
                                    {{  value   }} 
                                </option>
                            </select>
                        </div>
                    </div><!-- /End online -->

               

                    <div class="w-[200px] inline-block p-2">
                        <div class="flex">
                            <button @click="search" id="states-button"  class="flex-shrink-0 z-10 inline-flex items-center  bg-zinc-200 dark:bg-zinc-200 px-4 py-1 text-ms font-medium text-center text-zinc-700 bg-transparent border border-zinc-300 dark:border-zinc-300  hover:bg-transparent  dark:bg-transparent dark:hover:bg-transparent  dark:text-zinc-700 dark:border-gray-600" type="button">
                                搜索
                            </button>
                        </div>
                    </div>

                </div><!-- /End search -->

                <!-- 数据 -->
                <div class="relative overflow-x-auto h-[calc(100vh-230px)] overflow-y-scroll  p-2 mx-4">
                    <table class="text-ms w-screen text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr  class="table-fixed whitespace-nowrap bg-[#f2f2f2] bg-[#f2f2f2] text-[#666] border border-[#e6e6e6] dark:border-[#e6e6e6]">
                                <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">
                                    帐号
                                </th>
                                <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">
                                    订单
                                </th>
                                <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">
                                    金额
                                </th>
                                <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">
                                    余额
                                </th>
                                <th scope="col" class="px-6 py-3 border-r border-[#e6e6e6]">
                                    类型
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    帐变时间
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in pageData" :key="item.user_id"  class="bg-white border border-[#e6e6e6] dark:border-[#e6e6e6] text-stone-600  whitespace-nowrap dark:text-stone-600 dark:bg-white hover:bg-[#f2f2f2] dark:hover:bg-[#f2f2f2]">
                    
                                <td class="px-6 py-4 font-medium border-r border-[#e6e6e6]">
                                    {{ item.user['username'] }}
                                </td>
                                <td class="px-6 py-4 font-medium border-r border-[#e6e6e6]">
                                    {{  item.order_on }}
                                </td>
                                <td class="px-6 py-4 font-medium border-r border-[#e6e6e6]">
                                    {{ item.amount }}
                                </td>
                                <td class="px-6 py-4 font-medium border-r border-[#e6e6e6]">
                                    {{ item.balance }}
                                </td>
                                <td class="px-6 py-4 font-medium border-r border-[#e6e6e6]">
                                    <span v-show="0==item.grade" class="text-stone-700">赠送</span>
                                    <span v-show="1==item.grade" class="text-stone-600">充值</span>
                                    <span v-show="2==item.grade" class="text-stone-600">扣除</span>
                                    <span v-show="3==item.grade" class="text-stone-600">返佣</span>
                                    <span v-show="4==item.grade" class="text-stone-600">推广返佣</span>
                                    <span v-show="5==item.grade" class="text-stone-600">下级交易返佣</span>
                                    <span v-show="6==item.grade" class="text-stone-600">提现</span>
                                </td>
                                <td class="px-6 py-4 font-medium">
                                    {{ date(item.created_at) }} &nbsp; {{ time(item.created_at) }}
                                </td>
                             
                            </tr>
                        </tbody>
                    </table>

                    <!-- Row 分页条 -->
                    <div class="grid grid-cols-2 gap-0 pb-2 text-ms py-2 px-4">
                        <!-- Row Left -->
                        <div class="left text-left">
                            <span>{{ pageInfo(pageTotal, ps, p) }}</span>
                        </div>
                        <!-- Row Right -->
                        <div class="right text-right" v-show="showPageBtn">
                            <button @click="p=backPage(p);getData();">上一页</button>
                            <button
                                class="px-1"
                                    v-for="item in pageLinks"
                                    :key="item"
                                    @click="() => {p=item;getData();}"
                                >
                                {{ item }}
                            </button>
                            <button @click="p=nextPage(p,pt);getData();">下一页</button>
                        </div>
                    </div><!-- /End Row 分页条 -->

                </div><!-- /End 数据-->


                <CreateUser ref="refCreateUser" />
                <EditAmount ref="refEditAmount" />
                <EditUser ref="refEditUser" />
                <EditBankCard ref="refEditBankCard" />
            </div>

            <!-- <div class="h-[100px] p-10"></div> -->

            <!-- Main Footer -->
            <MainFooter />
            <!--  /End Main Footer -->

        </div>
    
    </div><!-- /End Main -->
</template>

<script>
import axios from 'axios'
import { layer } from "vue3-layer"
import SideSar from "@/components/SideSar.vue"
import MainHeader from "@/components/MainHeader.vue"
import MainFooter from "@/components/MainFooter.vue"
import CreateUser from '@/components/CreateUser.vue'
import EditAmount from '@/components/EditAmount.vue'
import EditUser from '@/components/EditUser.vue'
import EditBankCard from '@/components/EditBankCard.vue'


// page.a
import handlePagination from "@/assets/js/handlePagination"
import postPages from "@/assets/js/postPages"
import format from '@/assets/js/formatDate'

// 日期选择件
// code.admin/node_modules/vue-tailwind-datepicker/dist/vue-tailwind-datepicker.js 编辑 删除掉 py-2.5 rounded-lg 加上 py-1 outline-none dark:outline-none
// 删除 focus:ring focus:ring-vtd-primary-500 focus:ring-opacity-10 dark:focus:ring-opacity-20
import { ref } from "vue"
import VueTailwindDatepicker from "vue-tailwind-datepicker"
const dateValue = ref([])
const formatter = ref({
  date: "YYYY-MM-DD",
  month: "MMM",
})


export default {
    components: {
        SideSar,
        MainHeader,
        MainFooter,
        layer,
        CreateUser,
        VueTailwindDatepicker,
        EditAmount,
        EditUser,
        EditBankCard,
    },
    setup() {
        // page.b
        const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-cn'
        const handlePaginationValue = handlePagination()
        const formatFunc = format()
        return { ...handlePaginationValue, ...formatFunc, lang }
    },
    data() {
        return {
            showDropDown: false,
            showSideBar: true,
            visibleBox: false,
            // page.c
            showPageBtn: false,
            pageData: [],
            pageTotal: 0,
            ps: 15,
            p: 1, // 当前请求第几页的数据
            pt: 0, // 总页数
            pageLinks: [],
            searchParams: '',
            // 数据搜索条件
            search: {
                title: '',
                status: '',
            },
            dateValue,
            formatter,
            status: {
                0 : '假人',
                1 : '真人',
            },
            online: {
                0 : '离线',
                1 : '在线',
            },
            css: {
                main: 'h-full w-[calc(100vw-200px)] bg-gray-400',
                actionRed: 'bg-red-500 hover:bg-red-600 rounded-sm transition-all text-white  inline-block text-xs p-1 font-semibold relative before:absolute before:bg-gradient-to-tr before:from-yellow-600 before:via-transparent before:to-green-700 before:inset-0 before:scale-110 before:rounded before:blur-md before:-z-10 ms-3',
                actionGreen: 'bg-green-700 hover:bg-green-800 rounded-sm transition-all text-white  inline-block text-xs p-1 font-semibold relative before:absolute before:bg-gradient-to-tr before:from-yellow-600 before:via-transparent before:to-green-700 before:inset-0 before:scale-110 before:rounded before:blur-md before:-z-10 ms-3',
            }
            
        }
    },
    provide() {
        return {
            fatherGetToggleSideBar: this.toggleSideBar,
            fatherGetData: this.getData,
        }
    },
    mounted() {
        // layer.msg("用户快捷菜单")
        // 获取数据，并构建分页
        this.getData()
        return {}
    },
    methods: {
        // hide show side bar 
        toggleSideBar() {
            // console.warn('侧边栏开关')
            // 如果 this.showSideBar 是真就取假，如果 this.showSideBar 是假就取真
            // this.showSideBar = !this.showSideBar
            // 这个方法已经移到子组件中了，直接调用子组件来完成。前提是在模板中使用组件时，在组件中，要先为组件声明 ref 才可以使用，比如这里声明为  refSideSar 通过 refSideSar 直接调用子组件中的方法。
            let res = this.$refs.refSideSar.switchSideBar()
            // 这里要怎么切换 Main 区的宽度大小呢？
            // console.log( ' 这里要怎么切换 Main 区的宽度大小呢？ ' )
            // console.log( res )
            this.showSideBar = res
            switch(res) {
                case true:
                    this.css.main = 'h-full w-[calc(100vw-200px)] bg-gray-400'
                    break
                case false:
                    this.css.main = 'h-full w-full bg-gray-400'
                    break
            }
        },
        // toggle user
        toggleDrop() {
            // console.info('用户快捷菜单')
            // 如果 this.showDropDown 是真就取假，如果 this.showDropDown 是假就取真
            this.showDropDown = !this.showDropDown
        },
        switchAddUser() {
            this.$refs.refCreateUser.switchShow()
        },
        switchAmountAdjustment(uid)
        {
            this.$refs.refEditAmount.switchShow(uid)
        },
        switchEditUser(info)
        {
            this.$refs.refEditUser.switchShow(info)
        },
        switchEditBankCard(id)
        {
            this.$refs.refEditBankCard.switchShow(id)
        },
        getData()
        {
            // 如何打包搜索参数？  &p=${p}
            this.searchParams = `&title=${this.search.title}`

            // 发起请求时 要先隐藏分页按钮
            this.showPageBtn = false
            // positionPage 方法是通过 JS 文件导入的
            const page = postPages(this.lang, localStorage.getItem('token'), '/api/x/page/amount', this.ps, this.p, this.searchParams)
            // 将异步获取的数据导入 VM 数据
            page.data.then(res =>{
                if (0===res.code) {
                    this.pageData = res.data.items
                    this.pageTotal = res.data.total
                    // 这是另一个文件导入的方法 在 setup() 中初始化的分页助手 帮我快速生成分页工具的函数 
                    this.pageInfo(this.pageTotal, this.ps, this.p)
                    this.pageLinks = this.pageLink(this.pageTotal, this.ps, this.p)
                    this.pt = Math.ceil(this.pageTotal/this.ps)
                    // 请求结果已经回来 才可以显示分页按钮
                    this.showPageBtn = true
                } else {
                    layer.msg(res.msg)
                }
            })
        },
        status(id) 
        { 
            axios.post(
                '/api/x/status/level', 
                {
                    id: id,
                },
                {
                    headers: {
                        // 'Content-Type': 'multipart/form-data'
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': this.lang,
                        'token': localStorage.getItem('token')
                    }
                }
            )
            .then((response) =>  {
                // console.log(response)
                if (0===response.data.code) {
                    layer.msg(response.data.msg)
                } else {
                    layer.msg(response.data.msg)
                }
                this.getData()
            })
            .catch(function (error) {
                console.log(error)
            })

        },
        deleteLevel(id)
        {
            console.log( id )
            layer.confirm(
                "删除操作比较危险，您确定要删除？",
                {
                    btn: ["确定删除", "放弃删除"],
                },
                () => {
                    axios.post(
                        '/api/x/delete/level', 
                        {
                            id: id,
                        },
                        {
                            headers: {
                                // 'Content-Type': 'multipart/form-data'
                                'Content-Type': 'application/x-www-form-urlencoded',
                                'Accept-Language': this.lang,
                                'token': localStorage.getItem('token')
                            }
                        }
                    )
                    .then((response) =>  {
                        // console.log(response)
                        if (0===response.data.code) {
                            layer.msg(response.data.msg)
                            this.getData()
                        } else {
                            layer.msg(response.data.msg)
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                },
                () => {
                    // 放弃后什么也不做
                }
            )

            
        }, // End deleteLevel
    }
}
</script>

<style>

</style>