<template>
        <!-- Side bar 侧边栏 -->
        <!-- 侧边栏 w-[200px] 总固定宽度 200 px  -->
        <div class="w-[200px] h-full bg-gray-200 border-e border-slate-900" v-show="showSideBar">
            <div class="h-[50px] bg-gray-900 flex justify-star items-center">
                <div class="px-[20px]">
                    <h3 class="text-sm font-bold">YMF SHOP</h3>
                </div>
            </div>

            <!-- 主菜单树 -->
            <div class="h-[calc(100vh-50px)] bg-gray-800 py-[20px] overflow-auto">
                <div>
                    <ul class="flex flex-col space-y-2 px-2">
                        <!-- 首页 -->
                        <li class="">
                            <div class="relative text-gray-500 hover:text-white flex justify-between" @click="toggleMenu($event, 'main'); $router.push('/system')">
                                <div class="flex w-full justify-between">
                                    <div class="absolute inset-y-0 left-0 flex items-center pl-2 pt-1 pt-[5px] pointer-events-none">
                                        <svg class="w-[20px] h-[20px] stroke-current" fill="none" viewBox="0 0 24 24">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
                                        </svg>
                                    </div>
                                    <!-- v-if 是用来切换节点是否被渲染的  用动态 :class 来实现样式的条件判断 -->
                                    <a href="#" :class="showMenu.main ? 'inline-block w-full pr-4 py-1 bg-green-300 hover:bg-yellow-600 text-sm text-left px-8 text-white' : 'inline-block w-full pr-4 py-1  hover:bg-yellow-600 text-sm text-left px-8 text-white'">{{ $t('side.main') }}</a>
                                </div>
                            </div>
                        </li>
                        <!-- 交易管理 -->
                        <li class="">
                            <div class="relative text-gray-500 hover:text-white flex justify-between"  @click="toggleMenu($event, 'trade')">
                                <div class="flex  w-full justify-between">
                                    <div class="absolute inset-y-0 left-0 flex items-center pl-2 pt-1 pt-[5px] pointer-events-none">
                                        <svg class="w-[20px] h-[20px] stroke-current" fill="none" viewBox="0 0 24 24">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
                                        </svg>
                                    </div>
                                    <a href="#" :class="showMenu.trade ? 'inline-block w-full pr-4 py-1 bg-[#009688] hover:bg-yellow-600 text-sm text-left px-8 text-white' : 'inline-block w-full pr-4 py-1 bg-gray-800 hover:bg-yellow-600 text-sm text-left px-8 text-white'">{{ $t('side.trade') }}</a>
                                </div>
                                <button class="absolute right-0 p-1 py-[10px] flex items-center">
                                    <svg class="w-2 h-2 stroke-current" fill="none" viewBox="0 0 16 16">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
                                    </svg>
                                </button>
                            </div>
                            <div class="pt-4 pl-4" v-show="showMenu.trade">
                                <ul class="flex flex-col pl-2 text-gray-500 border-l border-gray-700">
                                    <li><a href="#"  :class="url == '/trade/order' ? 'inline-block w-full my-px px-4 py-2 rounded text-xs bg-[#009688] hover:bg-yellow-600 hover:text-white text-left text-white' : 'inline-block w-full my-px px-4 py-2 rounded text-xs hover:bg-yellow-600 hover:text-white text-left text-white'"   @click="$router.push('/trade/order')">{{ $t('side.tradeOrder') }}</a></li>
                                    <li><a href="#"  :class="url == '/trade/recharge' ? 'inline-block w-full my-px px-4 py-2 rounded text-xs bg-[#009688] hover:bg-yellow-600 hover:text-white text-left text-white' : 'inline-block w-full my-px px-4 py-2 rounded text-xs hover:bg-yellow-600 hover:text-white text-left text-white'"   @click="$router.push('/trade/recharge')">{{ $t('side.tradeRecharge') }}</a></li>
                                    <li><a href="#"  :class="url == '/trade/withdraw' ? 'inline-block w-full my-px px-4 py-2 rounded text-xs bg-[#009688] hover:bg-yellow-600 hover:text-white text-left text-white' : 'inline-block w-full my-px px-4 py-2 rounded text-xs hover:bg-yellow-600 hover:text-white text-left text-white'"   @click="$router.push('/trade/withdraw')">{{ $t('side.tradeWithdraw') }}</a></li>
                                    <li><a href="#"  :class="url == '/trade/control' ? 'inline-block w-full my-px px-4 py-2 rounded text-xs bg-[#009688] hover:bg-yellow-600 hover:text-white text-left text-white' : 'inline-block w-full my-px px-4 py-2 rounded text-xs hover:bg-yellow-600 hover:text-white text-left text-white'"   @click="$router.push('/trade/control')">{{ $t('side.tradeControl') }}</a></li>
                                    <li><a href="#"  :class="url == '/trade/amount' ? 'inline-block w-full my-px px-4 py-2 rounded text-xs bg-[#009688] hover:bg-yellow-600 hover:text-white text-left text-white' : 'inline-block w-full my-px px-4 py-2 rounded text-xs hover:bg-yellow-600 hover:text-white text-left text-white'"   @click="$router.push('/trade/amount')">{{ $t('side.tradeAmount') }}</a></li>
                                    <li><a href="#"  :class="url == '/trade/statistics' ? 'inline-block w-full my-px px-4 py-2 rounded text-xs bg-[#009688] hover:bg-yellow-600 hover:text-white text-left text-white' : 'inline-block w-full my-px px-4 py-2 rounded text-xs hover:bg-yellow-600 hover:text-white text-left text-white'"   @click="$router.push('/trade/statistics')">{{ $t('side.tradeStatistics') }}</a></li>
                                    <li><a href="#"  :class="url == '/trade/report' ? 'inline-block w-full my-px px-4 py-2 rounded text-xs bg-[#009688] hover:bg-yellow-600 hover:text-white text-left text-white' : 'inline-block w-full my-px px-4 py-2 rounded text-xs hover:bg-yellow-600 hover:text-white text-left text-white'"   @click="$router.push('/trade/report')">{{ $t('side.tradeReport') }}</a></li>
                                    <li><a href="#"  :class="url == '/trade/report/daily' ? 'inline-block w-full my-px px-4 py-2 rounded text-xs bg-[#009688] hover:bg-yellow-600 hover:text-white text-left text-white' : 'inline-block w-full my-px px-4 py-2 rounded text-xs hover:bg-yellow-600 hover:text-white text-left text-white'"   @click="$router.push('/trade/report/daily')">{{ $t('side.tradeReportDaily') }}</a></li>
                                    <li><a href="#"  :class="url == '/trade/report/monthly' ? 'inline-block w-full my-px px-4 py-2 rounded text-xs bg-[#009688] hover:bg-yellow-600 hover:text-white text-left text-white' : 'inline-block w-full my-px px-4 py-2 rounded text-xs hover:bg-yellow-600 hover:text-white text-left text-white'"   @click="$router.push('/trade/report/monthly')">{{ $t('side.tradeReportMonthly') }}</a></li>
                                </ul>
                            </div>
                        </li>
                        <!-- 商品管理 -->
                        <li class="">
                            <div class="relative text-gray-500 hover:text-white flex justify-between"  @click="toggleMenu($event,'commodity')">
                                <div class="flex  w-full justify-between">
                                    <div class="absolute inset-y-0 left-0 flex items-center pl-2 pt-1 pt-[5px] pointer-events-none">
                                        <svg class="w-[20px] h-[20px] stroke-current" fill="none" viewBox="0 0 24 24">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
                                        </svg>
                                    </div>
                                    <a href="#" :class="showMenu.commodity ? 'inline-block w-full pr-4 py-1 bg-[#009688]  hover:bg-yellow-600 text-sm text-left px-8 text-white' : 'inline-block w-full pr-4 py-1 bg-gray-800  hover:bg-yellow-600 text-sm text-left px-8 text-white'">{{ $t('side.commodity') }}</a>
                                </div>
                                <button class="absolute right-0 p-1 py-[10px] flex items-center">
                                    <svg class="w-2 h-2 stroke-current" fill="none" viewBox="0 0 16 16">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
                                    </svg>
                                </button>
                            </div>
                            <div class="pt-4 pl-4" v-show="showMenu.commodity">
                                <ul class="flex flex-col pl-2 text-gray-500 border-l border-gray-700">
                                    <li><a href="#"  :class="url == '/commodity/list' ? 'inline-block w-full my-px px-4 py-2 rounded text-xs bg-[#009688] hover:bg-yellow-600 hover:text-white text-left text-white' : 'inline-block w-full my-px px-4 py-2 rounded text-xs hover:bg-yellow-600 hover:text-white text-left text-white'"  @click="$router.push('/commodity/list')">{{ $t('side.commodityList') }}</a></li>
                                    <li><a href="#"  :class="url == '/commodity/sort' ? 'inline-block w-full my-px px-4 py-2 rounded text-xs bg-[#009688] hover:bg-yellow-600 hover:text-white text-left text-white' : 'inline-block w-full my-px px-4 py-2 rounded text-xs hover:bg-yellow-600 hover:text-white text-left text-white'"  @click="$router.push('/commodity/sort')">{{ $t('side.commoditySort') }}</a></li>
                                </ul>
                            </div>
                        </li>
                        <!-- 会员管理 -->
                        <li class="">
                            <div class="relative text-gray-500 hover:text-white flex justify-between"  @click="toggleMenu($event,'member')">
                                <div class="flex  w-full justify-between">
                                    <div class="absolute inset-y-0 left-0 flex items-center pl-2 pt-1 pt-[5px] pointer-events-none">
                                        <svg class="w-[20px] h-[20px] stroke-current" fill="none" viewBox="0 0 24 24">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
                                        </svg>
                                    </div>
                                    <a href="#" :class="showMenu.member ? 'inline-block w-full pr-4 py-1 bg-[#009688]  hover:bg-yellow-600 text-sm text-left px-8 text-white' : 'inline-block w-full pr-4 py-1 bg-gray-800  hover:bg-yellow-600 text-sm text-left px-8 text-white'">{{ $t('side.member') }}</a>
                                </div>
                                <button class="absolute right-0 p-1 py-[10px] flex items-center">
                                    <svg class="w-2 h-2 stroke-current" fill="none" viewBox="0 0 16 16">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
                                    </svg>
                                </button>
                            </div>
                            <div class="pt-4 pl-4" v-show="showMenu.member">
                                <ul class="flex flex-col pl-2 text-gray-500 border-l border-gray-700">
                                    <li><a href="#"  :class="url == '/member/list' ? 'inline-block w-full my-px px-4 py-2 rounded text-xs bg-[#009688] hover:bg-yellow-600 hover:text-white text-left text-white' : 'inline-block w-full my-px px-4 py-2 rounded text-xs hover:bg-yellow-600 hover:text-white text-left text-white'"  @click="$router.push('/member/list')">{{ $t('side.memberList') }}</a></li>
                                    <li><a href="#"  :class="url == '/member/grade' ? 'inline-block w-full my-px px-4 py-2 rounded text-xs bg-[#009688] hover:bg-yellow-600 hover:text-white text-left text-white' : 'inline-block w-full my-px px-4 py-2 rounded text-xs hover:bg-yellow-600 hover:text-white text-left text-white'"  @click="$router.push('/member/grade')">{{ $t('side.memberSort') }}</a></li>
                                    <li><a href="#"  :class="url == '/member/level' ? 'inline-block w-full my-px px-4 py-2 rounded text-xs bg-[#009688] hover:bg-yellow-600 hover:text-white text-left text-white' : 'inline-block w-full my-px px-4 py-2 rounded text-xs hover:bg-yellow-600 hover:text-white text-left text-white'"  @click="$router.push('/member/level')">{{ $t('side.memberLevel') }}</a></li>
                                </ul>
                            </div>
                        </li>
                        <!-- 内容管理 -->
                        <li class="">
                            <div class="relative text-gray-500 hover:text-white flex justify-between"  @click="toggleMenu($event,'content')">
                                <div class="flex  w-full justify-between">
                                    <div class="absolute inset-y-0 left-0 flex items-center pl-2 pt-1 pt-[5px] pointer-events-none">
                                        <svg class="w-[20px] h-[20px] stroke-current" fill="none" viewBox="0 0 24 24">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
                                        </svg>
                                    </div>
                                    <a href="#" :class="showMenu.content ? 'inline-block w-full pr-4 py-1 bg-[#009688]  hover:bg-yellow-600 text-sm text-left px-8 text-white' : 'inline-block w-full pr-4 py-1 bg-gray-800  hover:bg-yellow-600 text-sm text-left px-8 text-white'">{{ $t('side.content') }}</a>
                                </div>
                                <button class="absolute right-0 p-1 py-[10px] flex items-center">
                                    <svg class="w-2 h-2 stroke-current" fill="none" viewBox="0 0 16 16">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
                                    </svg>
                                </button>
                            </div>
                            <div class="pt-4 pl-4" v-show="showMenu.content">
                                <ul class="flex flex-col pl-2 text-gray-500 border-l border-gray-700">
                                    <!-- <li><a href="#"  :class="url == '/content/notice' ? 'inline-block w-full my-px px-4 py-2 rounded text-xs bg-[#009688] hover:bg-yellow-600 hover:text-white text-left text-white' : 'inline-block w-full my-px px-4 py-2 rounded text-xs hover:bg-yellow-600 hover:text-white text-left text-white'"  @click="$router.push('/content/notice')">{{ $t('side.contentNotice') }}</a></li>
                                    <li><a href="#"  :class="url == '/content/text' ? 'inline-block w-full my-px px-4 py-2 rounded text-xs bg-[#009688] hover:bg-yellow-600 hover:text-white text-left text-white' : 'inline-block w-full my-px px-4 py-2 rounded text-xs hover:bg-yellow-600 hover:text-white text-left text-white'"  @click="$router.push('/content/text')">{{ $t('side.contentText') }}</a></li> -->
                                    <li><a href="#"  :class="url == '/content/ad' ? 'inline-block w-full my-px px-4 py-2 rounded text-xs bg-[#009688] hover:bg-yellow-600 hover:text-white text-left text-white' : 'inline-block w-full my-px px-4 py-2 rounded text-xs hover:bg-yellow-600 hover:text-white text-left text-white'"  @click="$router.push('/content/ad')">{{ $t('side.contentAd') }}</a></li>
                                    <!-- <li><a href="#"  :class="url == '/content/logo' ? 'inline-block w-full my-px px-4 py-2 rounded text-xs bg-[#009688] hover:bg-yellow-600 hover:text-white text-left text-white' : 'inline-block w-full my-px px-4 py-2 rounded text-xs hover:bg-yellow-600 hover:text-white text-left text-white'"  @click="$router.push('/content/logo')">{{ $t('side.contentLogo') }}</a></li> -->
                                </ul>
                            </div>
                        </li>
                        <!-- 权限管理 -->
                        <li class="">
                            <div class="relative text-gray-500 hover:text-white flex justify-between"  @click="toggleMenu($event,'power')">
                                <div class="flex  w-full justify-between">
                                    <div class="absolute inset-y-0 left-0 flex items-center pl-2 pt-1 pt-[5px] pointer-events-none">
                                        <svg class="w-[20px] h-[20px] stroke-current" fill="none" viewBox="0 0 24 24">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
                                        </svg>
                                    </div>
                                    <a href="#" :class="showMenu.power ? 'inline-block w-full pr-4 py-1 bg-[#009688] hover:bg-yellow-600 text-sm text-left px-8 text-white' : 'inline-block w-full pr-4 py-1 bg-gray-800  hover:bg-yellow-600 text-sm text-left px-8 text-white'">{{ $t('side.power') }}</a>
                                </div>
                                <button class="absolute right-0 p-1 py-[10px] flex items-center">
                                    <svg class="w-2 h-2 stroke-current" fill="none" viewBox="0 0 16 16">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
                                    </svg>
                                </button>
                            </div>
                            <div class="pt-4 pl-4" v-show="showMenu.power">
                                <ul class="flex flex-col pl-2 text-gray-500 border-l border-gray-700">
                                    <!-- <li><a href="#"  :class="url == '/power/list' ? 'inline-block w-full my-px px-4 py-2 rounded text-xs bg-[#009688] hover:bg-yellow-600 hover:text-white text-left text-white' : 'inline-block w-full my-px px-4 py-2 rounded text-xs hover:bg-yellow-600 hover:text-white text-left text-white'"  @click="$router.push('/power/list')">{{ $t('side.powerList') }}</a></li> -->
                                    <li><a href="#" @click="$router.push('/system/account')"        :class="url == '/system/account' ? 'inline-block w-3/4 my-px px-4 py-1 rounded text-xs bg-[#009688] hover:bg-yellow-600 hover:text-white text-left text-black' : 'inline-block  w-3/4 my-px px-4 py-1 rounded text-xs hover:bg-yellow-600 hover:text-white text-left text-white'">{{ $t('side.powerAdmin') }}</a></li>
                                    <li><a href="#" @click="$router.push('/system/acl/position')"   :class="url == '/system/acl/position' ? 'inline-block w-3/4 my-px px-4 py-1 rounded text-xs bg-[#009688] hover:bg-yellow-600 hover:text-white text-left text-black' : 'inline-block  w-3/4 my-px px-4 py-1 rounded text-xs hover:bg-yellow-600 hover:text-white text-left text-white'">职务管理</a></li>
                                    <li><a href="#" @click="$router.push('/system/acl/team')"       :class="url == '/system/acl/team' ? 'inline-block w-3/4 my-px px-4 py-1 rounded text-xs bg-[#009688] hover:bg-yellow-600 hover:text-white text-left text-black' : 'inline-block  w-3/4 my-px px-4 py-1 rounded text-xs hover:bg-yellow-600 hover:text-white text-left text-white'">权组管理</a></li>
                                    <li><a href="#" @click="$router.push('/system/acl/power')"      :class="url == '/system/acl/power' ? 'inline-block w-3/4 my-px px-4 py-1 rounded text-xs bg-[#009688] hover:bg-yellow-600 hover:text-white text-left text-black' : 'inline-block  w-3/4 my-px px-4 py-1 rounded text-xs hover:bg-yellow-600 hover:text-white text-left text-white'">权限管理</a></li>
                                </ul>
                            </div>
                        </li>
                        <!-- 系统配置 -->
                        <li class="">
                            <div class="relative text-gray-500 hover:text-white flex justify-between"  @click="toggleMenu($event,'system')">
                                <div class="flex  w-full justify-between">
                                    <div class="absolute inset-y-0 left-0 flex items-center pl-2 pt-1 pt-[5px] pointer-events-none">
                                        <svg class="w-[20px] h-[20px] stroke-current" fill="none" viewBox="0 0 24 24">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
                                        </svg>
                                    </div>
                                    <a href="#" :class="showMenu.system ? 'inline-block w-full pr-4 py-1 bg-[#009688]  hover:bg-yellow-600 text-sm text-left px-8 text-white' : 'inline-block w-full pr-4 py-1  bg-gray-800  hover:bg-yellow-600 text-sm text-left px-8 text-white'">{{ $t('side.system') }}</a>
                                </div>
                                <button class="absolute right-0 p-1 py-[10px] flex items-center">
                                    <svg class="w-2 h-2 stroke-current" fill="none" viewBox="0 0 16 16">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
                                    </svg>
                                </button>
                            </div>
                            <div class="pt-4 pl-4" v-show="showMenu.system">
                                <ul class="flex flex-col pl-2 text-gray-500 border-l border-gray-700">
                                    <!-- <li><a href="#" :class="url == '/system/parameter' ? 'inline-block w-full my-px px-4 py-2 rounded text-xs bg-[#009688] hover:bg-yellow-600 hover:text-white text-left text-white' : 'inline-block w-full my-px px-4 py-2 rounded text-xs hover:bg-yellow-600 hover:text-white text-left text-white'" @click="$router.push('/system/parameter')">{{ $t('side.systemMenu') }}</a></li>
                                    <li><a href="#" :class="url == '/system/menu' ? 'inline-block w-full my-px px-4 py-2 rounded text-xs bg-[#009688] hover:bg-yellow-600 hover:text-white text-left text-white' : 'inline-block w-full my-px px-4 py-2 rounded text-xs hover:bg-yellow-600 hover:text-white text-left text-white'"  @click="$router.push('/system/menu')">{{ $t('side.systemParameter') }}</a></li> -->
                                    <li><a href="#" :class="url == '/system/log' ? 'inline-block w-full my-px px-4 py-2 rounded text-xs bg-[#009688] hover:bg-yellow-600 hover:text-white text-left text-white' : 'inline-block w-full my-px px-4 py-2 rounded text-xs hover:bg-yellow-600 hover:text-white text-left text-white'"  @click="$router.push('/system/log')">{{ $t('side.systemLog') }}</a></li>
                                </ul>
                            </div>
                        </li>
                        <!-- ACL 权限 -->
                        <!-- <li class="">
                            <div class="relative text-gray-500 hover:text-white flex justify-between"  @click="toggleMenu($event,'acl')">
                                <div class="flex  w-full justify-between">
                                    <div class="absolute inset-y-0 left-0 flex items-center pl-2 pt-1 pt-[5px] pointer-events-none">
                                        <svg class="w-[20px] h-[20px] stroke-current" fill="none" viewBox="0 0 24 24">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
                                        </svg>
                                    </div>
                                    <a href="#" :class="showMenu.acl ? 'inline-block w-full pr-4 py-1  bg-[#009688]  hover:bg-yellow-600 text-sm text-left px-8 text-black' : 'inline-block w-full pr-4 py-1  bg-gray-800  hover:bg-yellow-600 text-sm text-left px-8 text-white'">ACL 权限</a>
                                </div>
                                <button class="absolute right-0 p-1 py-[10px] flex items-center">
                                    <svg class="w-2 h-2 stroke-current" fill="none" viewBox="0 0 16 16">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
                                    </svg>
                                </button>
                            </div>
                            <div class="pt-4 pl-4" v-show="showMenu.acl">
                                <ul class="flex flex-col pl-2 text-gray-500 border-l border-gray-700">
                                    <li><a href="#" @click="$router.push('/system/acl/position')" :class="url == '/system/acl/position' ? 'inline-block w-3/4 my-px px-4 py-1 rounded text-xs bg-green-300 hover:bg-yellow-600 hover:text-white text-left text-black' : 'inline-block  w-3/4 my-px px-4 py-1 rounded text-xs hover:bg-yellow-600 hover:text-white text-left text-white'">职务管理</a></li>
                                    <li><a href="#" @click="$router.push('/system/acl/team')" :class="url == '/system/acl/team' ? 'inline-block w-3/4 my-px px-4 py-1 rounded text-xs bg-green-300 hover:bg-yellow-600 hover:text-white text-left text-black' : 'inline-block  w-3/4 my-px px-4 py-1 rounded text-xs hover:bg-yellow-600 hover:text-white text-left text-white'">权组管理</a></li>
                                    <li><a href="#" @click="$router.push('/system/acl/power')" :class="url == '/system/acl/power' ? 'inline-block w-3/4 my-px px-4 py-1 rounded text-xs bg-green-300 hover:bg-yellow-600 hover:text-white text-left text-black' : 'inline-block  w-3/4 my-px px-4 py-1 rounded text-xs hover:bg-yellow-600 hover:text-white text-left text-white'">权限管理</a></li>
                                </ul>
                            </div>
                        </li> -->
                    </ul>
                </div>
            </div><!-- /End  主菜单树 -->

        </div><!-- /End Side bar -->

</template>

<script>
import { getCurrentInstance } from "vue"
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

export default {
    data() {
        return {
            showSideBar: true,
            // 定义一个模型，数据结果是一个 JS 对象，每一个对象代表一个菜单集合。
            showMenu: {
                main : false,
                trade: false,
                content: false,
                commodity: false,
                power: false,
                member: false,
                system: false
            },
            url: '/'
        }
    },
    setup() {
        // 我发现 Vue 3.0 中通过 getCurrentInstance 方法获取当前组件的实例，然后通过 ctx 属性获得当前上下文中，关没有路由相关的属性信息。
        // const current = getCurrentInstance()
        // console.log("current =>", current)
       

        // 我要通过路由，为菜单设置展示样式，哪项菜单当前为选中状态。
        return {}
    },
    mounted() {
        const router = useRouter()
        // console.log("url =>", router.currentRoute.value.path)
        // 如果路由地址与对应的菜单匹配上，就将 showMenu.trade 的值设置为真。
        this.url = router.currentRoute.value.path
        // console.log(this.url)
        // console.log(this.showMenu)
        // console.log(localStorage.getItem("menuKey"))
        this.showMenu[localStorage.getItem("menuKey")] = true // 这个菜单项是开启状态
        return {}
    },
    methods: {
        // hide show side bar 
        switchSideBar() {
            // console.warn('侧边栏开关')
            // 如果 this.showSideBar 是真就取假，如果 this.showSideBar 是假就取真
            this.showSideBar = !this.showSideBar
            // console.log( this.showSideBar )
            return this.showSideBar
        },
        toggleMenu(e, id) {
            // console.info(e)
            // console.info('展开与收超菜单' + id)
            // 如果 this.showMenu[id] 是真就取假，如果 this.showMenu[id] 是假就取真
            this.showMenu[id] = !this.showMenu[id]

            localStorage.setItem("menuKey", id) // 存储
            // let data = localStorage.getItem("token") // 获取
            // localStorage.removeItem("token")  //  删除

            // 遍历对象，设置成全局假。
            const keys = Object.keys(this.showMenu)
            keys.forEach(key => {
                // console.log(`${key}: ${this.showMenu[key]}`)
                if (key != id) this.showMenu[key] = false // 但是不要处理 刚刚切换过的菜单
            })
        }
    }
}
</script>

<style>

</style>