<template>
    <!-- 第一个页面：控制台首页-仪表盘 -->
    <div class="w-screen h-screen flex">
        <!-- Side bar 侧边栏 -->
        <!-- 侧边栏 w-[200px] 总固定宽度 200 px  -->
        <SideSar ref="refSideSar" /><!-- /End Side bar -->
        <!-- Main -->
        <div class="w-full h-full bg-gray-400">
            <!-- Main Header -->
            <MainHeader ref="refMainHeader" />
            <!--  /End Main Header -->
            <div class="h-[calc(100vh-80px)] bg-white">
                main
            </div>
            
            <!-- Main Footer -->
            <MainFooter />
            <!--  /End Main Footer -->
            
        </div><!-- /End Main -->
    </div>
</template>

<script>
import SideSar from "../../../components/SideSar.vue"
import MainHeader from "../../../components/MainHeader.vue"
import MainFooter from "../../../components/MainFooter.vue"
export default {
    name: "首页",
    components: {
        SideSar,
        MainHeader,
        MainFooter,
    },
    data() {
        return {
            showDropDown: false,
            showSideBar: true,
        }
    },
    provide() {
        return {
            fatherGetToggleSideBar: this.toggleSideBar,
        }
    },
    methods: {
        // hide show side bar 
        toggleSideBar() {
            // console.warn('侧边栏开关')
            // 如果 this.showSideBar 是真就取假，如果 this.showSideBar 是假就取真
            // this.showSideBar = !this.showSideBar
            // 这个方法已经移到子组件中了，直接调用子组件来完成。前提是在模板中使用组件时，在组件中，要先为组件声明 ref 才可以使用，比如这里声明为  refSideSar 通过 refSideSar 直接调用子组件中的方法。
            this.$refs.refSideSar.switchSideBar()
        },
        // toggle user
        toggleDrop() {
            console.info('用户快捷菜单')
            // 如果 this.showDropDown 是真就取假，如果 this.showDropDown 是假就取真
            this.showDropDown = !this.showDropDown
        }
    }
}
</script>

<style>

</style>