<template>
    <!--  Main -->
    <div class="w-screen h-screen flex">

        <!-- Side bar 侧边栏 -->
        <!-- 侧边栏 w-[200px] 总固定宽度 200 px  -->
        <SideSar ref="refSideSar" />
        <!-- /End Side bar -->

        <!-- Main -->
        <div class="w-full h-full bg-gray-400">

            <!-- Main Header -->
            <MainHeader ref="refMainHeader" />
            <!--  /End Main Header -->

            <div class="h-[calc(100vh-80px)] bg-white">

                <!-- Row -->
                <div class="w-full h-[40px] bg-current">
                    <div class="grid grid-cols-2 gap-0 pb-2">
                        <!-- Row Left -->
                        <div class="left text-left">

                        </div>
                        <!-- Row Right -->
                        <div class="right text-right">
                            
                        </div>
                    </div>
                </div><!-- /End Row -->


                <!-- Row Add -->
                <div class="w-full h-[calc(100vh-120px)] overflow-y-scroll text-left mt-4 mx-4">


                    <div class="w-[400px]  p-2">
                        <div class="text-ms text-right text-red-700" v-show="show.errTitle">
                            {{ msg.errTitle }}
                        </div>
                        <div class="flex">
                            <button class="w-[150px] text-right z-10  px-4 py-2 text-ms text-zinc-700 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                                商品名称.中文
                            </button>
                            <input
                                name="title"
                                type="text"
                                class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border-zinc-300 dark:border-zinc-300 dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-400 dark:text-zinc-700"
                                :placeholder="msg.tipTitle"
                                v-model="form.title"
                            />
                            <!-- disabled -->
                        </div>
                    </div>

                    <div class="w-[400px]  p-2">
                        <div class="text-ms text-right text-red-700" v-show="show.errTitle_vi">
                            {{ msg.errTitle_vi }}
                        </div>
                        <div class="flex">
                            <button class="w-[150px] text-right z-10  px-4 py-2 text-ms text-zinc-700 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                                商品名称.越文
                            </button>
                            <input
                                name="title"
                                type="text"
                                class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border-zinc-300 dark:border-zinc-300 dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-400 dark:text-zinc-700"
                                :placeholder="msg.tipTitle_vi"
                                v-model="form.title_vi"
                            />
                            <!-- disabled -->
                        </div>
                    </div>

                    

                    <div class="w-[400px]  p-2">
                        <div class="text-ms text-right text-red-700" v-show="show.errSort_id">
                            {{ msg.errSort_id }}
                        </div>
                        <div class="flex">
                            <button class="w-[140px] text-right z-10  px-4 py-2 text-ms text-zinc-700 border border-r-0 border-zinc-300 bg-zinc-200" type="button">商品分类</button>
                            <select
                                name="sort_id"
                                v-model="form.sort_id"
                                class="w-11/12  text-ms focus:outline-none focus:text-gray-600 py-2 pl-2 pr-6"
                                >
                                <option value="" selected disabled hidden>请选择</option>
                                <option v-for="(value, key) in sorts" v-bind:key="key" :value="value.sort_id" class="text-ms">
                                    {{  value.title   }} 
                                </option>
                            </select>
                        </div>
                    </div>


                    <div class="w-[400px]  p-2">
                        <div class="text-ms text-right text-slate-400" v-show="show.tipShop">
                            {{ msg.tipShop }}
                        </div>
                        <div class="text-ms text-right text-red-700" v-show="show.errShop">
                            {{ msg.errShop }}
                        </div>
                        <div class="flex">
                            <button class="w-[150px] text-right z-10  px-4 py-2 text-ms text-zinc-700 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                                店铺名称.中文
                            </button>
                            <input
                                name="shop"
                                type="text"
                                class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border-zinc-300 dark:border-zinc-300 dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-400 dark:text-zinc-700"
                                :placeholder="msg.tipShop"
                                v-model="form.shop"
                            />
                            <!-- disabled -->
                        </div>
                    </div>


                    <div class="w-[400px]  p-2">
                        <div class="text-ms text-right text-slate-400" v-show="show.tipShop_vi">
                            {{ msg.tipShop_vi }}
                        </div>
                        <div class="text-ms text-right text-red-700" v-show="show.errShop_vi">
                            {{ msg.errShop_vi }}
                        </div>
                        <div class="flex">
                            <button class="w-[150px] text-right z-10  px-4 py-2 text-ms text-zinc-700 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                                店铺名称.越文
                            </button>
                            <input
                                name="shop_vi"
                                type="text"
                                class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border-zinc-300 dark:border-zinc-300 dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-400 dark:text-zinc-700"
                                :placeholder="msg.tipShop_vi"
                                v-model="form.shop_vi"
                            />
                            <!-- disabled -->
                        </div>
                    </div>


                    <div class="w-[400px] p-2">
                        <div class="text-ms text-right text-slate-400" v-show="show.tipPrice">
                            {{ msg.tipPrice }}
                        </div>
                        <div class="text-ms text-right text-red-700" v-show="show.errPrice">
                            {{ msg.errPrice }}
                        </div>
                        <div class="flex">
                            <button class="w-[150px] text-right z-10  px-4 py-2 text-ms text-zinc-700 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                                商品价格
                            </button>
                            <input
                                name="title"
                                type="text"
                                class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border-zinc-300 dark:border-zinc-300 dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-400 dark:text-zinc-700"
                                :placeholder="msg.tipPrice"
                                v-model="form.price"
                            />
                            <!-- disabled -->
                        </div>
                    </div>


                    <div class="w-[400px] p-2">
                        <div class="text-ms text-right text-slate-400" v-show="show.tipIcon">
                            {{ msg.tipIcon }}
                        </div>
                        <div class="text-ms text-right text-red-700" v-show="show.errIcon">
                            {{ msg.errIcon }}
                        </div>
                        <div class="flex z-0">
                            <button class="w-[150px] text-right px-4 py-2 text-ms text-zinc-700 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                                商品图片
                            </button>
                            <input
                                name="icon"
                                type="file"
                                @change="selectFile"
                                class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border-zinc-300 dark:border-zinc-300 dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-400 dark:text-zinc-700"
                            />
                            <!-- disabled -->
                        </div>
                    </div>

                    

                    <div class="w-[650px] p-2">
                        <span class="text-ms">商品详情 : </span>
                        <quill-editor v-model:content="editor" contentType="html" theme="snow" class="h-[250px] z-0"></quill-editor>
                    </div>


                    <div class="w-[650px] p-2 text-right mb-[150px]">
                        <button @click="postData" class="w-[150px] z-10  px-4 py-1 text-ms text-zinc-700 border  border-zinc-300 bg-zinc-200 btn-submit" type="button">
                            确定
                        </button>
                    </div>



                </div><!-- /End Row Add -->

            </div>

            <!-- Main Footer -->
            <MainFooter />
            <!--  /End Main Footer -->

        </div>
    </div><!-- /End Main -->
</template>

<!-- npm install @tiptap/core @tiptap/pm @tiptap/starter-kit -->

<script>
import axios from 'axios'
import { useI18n } from 'vue-i18n'
import { layer } from "vue3-layer"
import SideSar from "@/components/SideSar.vue"
import MainHeader from "@/components/MainHeader.vue"
import MainFooter from "@/components/MainFooter.vue"


// page.a
import handlePagination from "@/assets/js/handlePagination"
import postPages from "@/assets/js/postPages"
import format from '@/assets/js/formatDate'
// import ckeditor from '@/assets/js/ckeditor' 

// npm install @vueup/vue-quill@latest --save

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import router from "@/router/index.js"

export default {
    components: {
        SideSar,
        MainHeader,
        MainFooter,
        layer,
        QuillEditor,
    },
    setup() {
        // page.b
        const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-cn'
        const handlePaginationValue = handlePagination()
        const formatFunc = format()
        return { ...handlePaginationValue, ...formatFunc, lang}
    },
    data() {
        return {
            showDropDown: false,
            showSideBar: true,
            // ajax params
            lang: '',
            searchParams: '',
            ps: 15,
            p: 1,
            sorts: [],

            show: {
                errSort_id: false,
                tipTitle: false,
                errTitle: false,
                tipTitle_vi: false,
                errTitle_vi: false,
                tipShop: false,
                errShop: false,
                tipShop_vi: false,
                errShop_vi: false,
                tipPrice: false,
                errPrice: false,
                tipIcon: false,
                errIcon: false,
            },
            msg: {
                errSort_id: '必须选择',
                tipTitle: '请输入中文的商品分类名称',
                errTitle: '',
                tipTitle_vi: '请输入越文的商品分类名称',
                errTitle_vi: '',
                tipShop: '请输入中文的店铺名称',
                errShop: '',
                tipShop_vi: '请输入越文的店铺名称',
                errShop_vi: '',
                tipPrice: '请输入商品的价格',
                errPrice: '',
                tipIcon: '请选择分类的LOGO',
                errIcon: '',
            },
            editor: '',
            form: {
                sort_id: '',
                title: '',
                title_vi: '',
                shop: '',
                shop_vi: '',
                icon: '',
                price: '',
            }
            
        }
    },
    provide() {
        return {
            fatherGetToggleSideBar: this.toggleSideBar
        }
    },
    mounted() {
        this.getData()
        return {}
    },
    methods: {
        selectFile(event) {
            this.form.icon = event.target.files[0];
        },
        // hide show side bar 
        toggleSideBar() {
            // console.warn('侧边栏开关')
            // 如果 this.showSideBar 是真就取假，如果 this.showSideBar 是假就取真
            // this.showSideBar = !this.showSideBar
            // 这个方法已经移到子组件中了，直接调用子组件来完成。前提是在模板中使用组件时，在组件中，要先为组件声明 ref 才可以使用，比如这里声明为  refSideSar 通过 refSideSar 直接调用子组件中的方法。
            this.$refs.refSideSar.switchSideBar()
        },
        // toggle user
        toggleDrop() {
            // console.info('用户快捷菜单')
            // 如果 this.showDropDown 是真就取假，如果 this.showDropDown 是假就取真
            this.showDropDown = !this.showDropDown
        },
        getData()
        {
            // 如何打包搜索参数？  &p=${p}
            this.searchParams = ``
            const page = postPages(this.lang, localStorage.getItem('token'), '/api/x/get/sorts', this.ps, this.p, this.searchParams)
            // 将异步获取的数据导入 VM 数据
            page.data.then(res =>{
                // console.log( res )
                this.sorts = res.data.items
            })
        },
        postData()
        {
            this.deoxidationTip()
            axios.post(
                '/api/x/add/commodity', 
                {
                    sort_id: this.form.sort_id,
                    title: this.form.title,
                    title_vi: this.form.title_vi,
                    shop: this.form.shop,
                    shop_vi: this.form.shop_vi,
                    icon: this.form.icon,
                    price: this.form.price,
                    introduction: this.editor,
                },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        // 'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': this.lang,
                        'token': localStorage.getItem('token')
                    }
                }
            )
            .then((response) =>  {
                // console.log(response)
                if (0===response.data.code) {
                    layer.msg(response.data.msg)
                    // 创建成功，可能还要创建，所以先清空。
                    this.deoxidation()

                    router.push({
                        path: '/commodity/list',
                        query: {
                            msg: 'reg account'
                        }
                    })

                } else {
                    const word = response.data.data.id
                    const tip = 'err' + word.charAt(0).toUpperCase() + word.slice(1)
                    this.msg[tip] = this.$t('filed.commodity.'+response.data.data.id) + ' ' + response.data.msg  // 转成国际化 后端转提示语 前端转表单名称
                    const err = 'err' + word.charAt(0).toUpperCase() + word.slice(1)
                    this.show[err] = true
                }
            })
            .catch(function (error) {
                console.log(error)
            })
        }, // End postData(...)

        // 提交后还原提交的表单信息
        deoxidation() {
            this.editor = ''
            this.form.sort_id = ''
            this.form.title = ''
            this.form.title_vi = ''
            this.form.shop = ''
            this.form.shop_vi = ''
            this.form.icon = ''
            this.form.price = ''
        },
        // 提前时清空错误提示
        deoxidationTip() {
            this.show.errTitle_vi = false
            this.show.errSort_id = false
            this.show.errTitle = false
            this.show.errTitle_vi = false
            this.show.errShop = false
            this.show.errShop_vi = false
            this.show.errPrice = false
            this.show.errIcon = false
        }

    }
}
</script>

<style>
/* @import url(https://cdnjs.cloudflare.com/ajax/libs/MaterialDesign-Webfont/5.3.45/css/materialdesignicons.min.css); */
.ql-editor {
    height: 220px;
}
.ql-toolbar, .ql-blank {
    z-index: 0;
}
</style>