export default {
    side: {
        main: '首页',
        trade: '交易管理',
        tradeOrder: '订单列表',
        tradeRecharge: '充值管理',
        tradeWithdraw: '提现管理',
        tradeControl: '交易控制',
        tradeAmount: '金额调整',
        tradeStatistics: '统计报表',
        tradeReport: '代理报表',
        tradeReportDaily: '代理日报表',
        tradeReportMonthly: '代理月报表',
        commodity: '商品管理',
        commodityList: '商品列表',
        commoditySort: '商品分类',
        member: '会员管理',
        memberList: '会员列表',
        memberSort: '会员等级',
        memberLevel: '会员层级',
        content: '内容管理',
        contentNotice: '公告管理',
        contentText: '文本管理',
        contentAd: '首页轮播图',
        contentLogo: 'LOGO',
        power: '权限管理',
        powerList: '访问权限',
        powerAdmin: '系统用户',
        systemAccount: '系统帐号管理',
        system: '系统管理',
        systemMenu: '系统菜单管理',
        systemParameter: '系统参数配置',
        systemLog: '系统操作日志'
    },
    user: {
        basicInformation: '基本资料',
        securitySettings: '安全设置',
        compressedRelease: '压缩发布',
        clearCache: '清理缓存',
        logOut: '退出登录',
    },
    footer: {
        homePage: '主页',
        product: '产品',
        mine: '矿',
    },
    filed: {
        position: {
            title : '职务名称 中文',
            title_vi : '职务名称 越南文',
            title_en : '职务名称 英文',
            username: '用户名',
            confirm : '确认密码',
            password : '密码',
            phone: '手机号码',
            sir_id: '上级ID',
            is_agent: '是否是代理',
            grade: '类型',
            bank_phone: '开户电话',
            bank_id: '开户银行',
            bank_user: '开户人',
            bank_card: '银行卡号',
            bank_addr: '开户地址',
            number: '收款银行卡号',
            name: '收款人',
        },
        admin: {
            account: '管理帐号',
            position: '职务',
            password: '密码',
            passwordConfirm: '确认密码',
        },
        commodity: {
            title : '商品名称 中文',
            title_vi : '商品名称 越南文',
            shop: '店铺名称 中文',
            shop_vi: '店铺名称 越南文',
            sort_id: '商品分类',
            price: '商品价格',
            icon: '商品图片',
        }
    }
}