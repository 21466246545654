<template>
    <s3-layer 
        class="layerBox"
        v-model="visibleBox" 
        :title="title" 
        area="350px" 
        btnAlign="c"
        :btn="['保存数据', '取消编辑']"
        @yes="btnYes"
        @btn2="btnCancel"
    >
      
        <div class="w-full  p-2">
            <div class="text-ms text-right text-red-700" v-show="show.errTitle">
                {{ msg.errTitle }}
            </div>
            <div class="flex">
                <button class="w-[150px] text-right z-10  px-4 py-1 text-ms text-zinc-700 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    商品名称
                </button>
                <input
                        name="title"
                        type="text"
                        class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border-zinc-300 dark:border-zinc-300 dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-400 dark:text-zinc-700"
                        :placeholder="tip.title"
                        v-model="account"
                    /><!-- disabled -->
            </div>
        </div>

        <div class="w-full  p-2">
            <div class="text-ms text-right text-red-700" v-show="show.errPosition">
                {{ msg.errPosition }}
            </div>
            <div class="flex">
                <button class="w-[150px] text-right z-10  px-4 py-1 text-ms text-zinc-700 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    职务
                </button>
                <label for="states" class="sr-only">全部状态</label>
                <select id="states" v-model="role" class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border-zinc-300 dark:border-zinc-300 dark:bg-transparent dark:border-gray-600 dark:placeholder-gray-400 dark:text-zinc-700">
                    <option value="" selected disabled hidden>{{ $t('filed.admin.position') }}</option>
                    <option v-for="(value, key) in positions" v-bind:key="key" :value="value.position_id"  class="text-ms">
                        {{  value.title   }} 
                    </option>
                </select>
            </div>
        </div>

  </s3-layer>
</template>

<script>
import { useI18n } from 'vue-i18n'
import axios from 'axios'
import { layer } from "vue3-layer"
import getAllPositions from "@/assets/js/getAllPositions"
import { toRaw } from '@vue/reactivity'

export default {
    components: {
        
    },
    data() {
        return {
            lang: '',
            title: '创建管理帐号',
            visibleBox: false,
            txt: {
                title: '职务名称(中文)',
                title_vi: '职务名称(越南文)',
                title_en: '职务名称(英文)',
            },
            form: {
                sort_id: '',
                title: '',
                title_vi: '',
                shop: '',
                shop_vi: '',
                icon: '',
                price: '',
            },
            show: {
                errTitle: false,
            },
            msg: {
                errTitle: '',
            },
            tip: {
                title: '',
            },
            item: [],
        }
    },
    inject: ["fatherGetData"],
    mounted() {
        const { locale } = useI18n()
        this.lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-cn'
        locale.value = this.lang
        return {}
    },
    methods: {
        getData()
        {
            const mAPI = getAllPositions(this.lang, localStorage.getItem('token'))
            // 将异步获取的数据导入 VM 数据
            mAPI.data.then(res =>{
                const list = toRaw(res)
                // let oJson = JSON.parse(JSON.stringify(res))
                // this.mysql = oJson.data
                // this.powers = list.data.powers
                // this.own =  list.data.own
                // console.log(list)
                this.positions = list.data.position
            })
        },
        switchShow(item) {
            this.visibleBox = true
            console.log( item )
            // this.getData()
        },
        yesOnSubmit() {
            this.deoxidationTip()
            axios.post(
                '/api/x/add/admin/account', 
                {
                    account: this.form.account,
                    position_id: this.form.position_id
                },
                {
                    headers: {
                        // 'Content-Type': 'multipart/form-data'
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': this.lang,
                        'token': localStorage.getItem('token')
                    }
                }
            )
            .then((response) =>  {
                // console.log(response)
                if (0===response.data.code) {
                    layer.msg(response.data.msg)
                    // 创建成功，可能还要创建，所以先清空。
                    this.deoxidation()
                    // 创建成功，父组件要重新获取数据。
                    this.fatherGetData()
                } else {
                    const word = response.data.data.id
                    const capitalized = 'showTip' + word.charAt(0).toUpperCase() + word.slice(1)
                    const tip = 'tip' + word.charAt(0).toUpperCase() + word.slice(1)
                    // 转成国际化 后端转提示语 前端转表单名称
                    this[tip] = this.$t('filed.position.'+response.data.data.id) + ' ' + response.data.msg 
                    this[capitalized] = true
                }
            })
            .catch(function (error) {
                console.log(error)
            })
            // layer.msg("按钮被点击了")
            // this.visibleBox = false
        },
        btn2OnClick(index, layero){
            this.deoxidationTip()
            this.deoxidation()
            this.visibleBox = false
        },
       
        // 提交后还原提交的表单信息
        deoxidation() {
            this.form.account = ''
            this.form.position_id = ''
        },
        // 提前时清空错误提示
        deoxidationTip() {
            this.showTipAccount = false
            this.tipAccount = ''
            this.showTipPosition_id = false
            this.tipPosition_id = ''
        }
    }
}
</script>

<style>

</style>