export default {
    side: {
        main: '首页',
        trade: '交易管理',
        tradeOrder: '订单列表',
        tradeRecharge: '充值管理',
        tradeWithdraw: '提现管理',
        tradeControl: '交易控制',
        tradeAmount: '金额调整',
        tradeStatistics: '统计报表',
        tradeReport: '代理报表',
        tradeReportDaily: '代理日报表',
        tradeReportMonthly: '代理月报表',
        commodity: '商品管理',
        commodityList: '商品列表',
        commoditySort: '商品分类',
        member: '会员管理',
        memberList: '会员列表',
        memberSort: '会员等级',
        memberLevel: '会中层级',
        content: '内容管理',
        contentNotice: '公告管理',
        contentText: '文本管理',
        contentAd: '首页轮播图',
        contentLogo: 'LOGO',
        power: '权限管理',
        powerList: '访问权限',
        powerAdmin: '系统用户',

        system: '系统管理',
        systemAccount: 'System account management',
        systemMenu: '系统菜单管理',
        systemParameter: '系统参数配置',
        systemLog: '系统操作日志'
    },
    user: {
        basicInformation: '基本资料',
        securitySettings: '安全设置',
        compressedRelease: '压缩发布',
        clearCache: '清理缓存',
        logOut: '退出登录',
    },
    footer: {
        homePage: 'Home',
        product: 'Product',
        mine: 'Mine',
    }
}