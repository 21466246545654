<template>
    <s3-layer 
        class="layerBox"
        v-model="visibleBox" 
        :title="title" 
        area="450px" 
        btnAlign="c"
        :btn="['保存数据', '取消编辑']"
        @yes="btnYes"
        @btn2="btnCancel"
    >
      
        <!-- username -->
        <div class="w-full  p-2">
            <div class="flex">
                <button class="w-[150px] text-right z-10  px-4 py-1 text-ms text-emerald-600 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    用户名
                </button>
                <input
                    type="text"
                    class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border border-zinc-200"
                    :placeholder="params.username"
                    disabled
                />
            </div>
        </div><!-- /End username -->

        <!-- phone -->
        <div class="w-full  p-2">
            <div class="text-ms text-right text-red-700"  v-show="show.phone">
                {{ err.phone }}
            </div>
            <div class="flex">
                <button class="w-[150px] text-right z-10  px-4 py-1 text-ms text-emerald-600 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    手机号码
                </button>
                <input
                    type="text"
                    class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border border-zinc-200"
                    v-model="params.phone"
                />
                <!-- disabled -->
            </div>
        </div><!-- /End phone -->


        <!-- balance -->
        <div class="w-full  p-2">
            <div class="text-ms text-right text-red-700"  v-show="show.balance">
                {{ err.balance }}
            </div>
            <div class="flex">
                <button class="w-[150px] text-right z-10  px-4 py-1 text-ms text-emerald-600 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    账号余额
                </button>
                <input
                    type="text"
                    class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border border-zinc-200"
                    v-model="params.balance"
                />
                <!-- disabled -->
            </div>
        </div><!-- /End balance -->

        <!-- frozen -->
        <div class="w-full  p-2">
            <div class="text-ms text-right text-red-700"  v-show="show.frozen">
                {{ err.frozen }}
            </div>
            <div class="flex">
                <button class="w-[150px] text-right z-10  px-4 py-1 text-ms text-emerald-600 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    冻结金额
                </button>
                <input
                    type="text"
                    class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border border-zinc-200"
                    v-model="params.frozen"
                />
                <!-- disabled -->
            </div>
        </div><!-- /End frozen -->

        
        <!-- /End grade -->
        <div class="w-full p-2">
            <div class="text-ms text-right text-red-700" v-show="show.grade">
                {{ err.grade }}
            </div>
            <div class="flex">
                <button class="w-[140px] text-right z-10  px-4 py-1 text-ms text-emerald-600 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    会员等级
                </button>
                <select v-model="params.grade"
                        class="w-11/12  text-ms focus:outline-none focus:text-gray-600 py-2 pl-2 pr-6 border border-zinc-200"
                    >
                    <option v-for="(value, key) in api.grade" v-bind:key="key" :value="value.grade_id" class="text-ms">
                        {{ value.name }}
                    </option>
                </select>
            </div>
        </div><!-- /End grade -->

        <!-- /End status -->
        <div class="w-full p-2">
            <div class="text-ms text-right text-red-700" v-show="show.status">
                {{ err.status }}
            </div>
            <div class="flex">
                <button class="w-[140px] text-right z-10  px-4 py-1 text-ms text-emerald-600 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    状态
                </button>
                <select
                        v-model="params.status"
                        class="w-11/12  text-ms focus:outline-none focus:text-gray-600 py-2 pl-2 pr-6 border border-zinc-200"
                    >
                    <option value="0" class="text-ms">
                        正常
                    </option>
                    <option value="0" class="text-ms">
                        冻结
                    </option>
                </select>
            </div>
        </div><!-- /End status -->

        <!-- sir_id -->
        <div class="w-full  p-2">
            <div class="text-ms text-right text-red-700"  v-show="show.sir_id">
                {{ err.sir_id }}
            </div>
            <div class="flex">
                <button class="w-[150px] text-right z-10  px-4 py-1 text-ms text-emerald-600 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    上级ID
                </button>
                <input
                    type="text"
                    class="outline-none bg-gray-50 border border-l-0 border-gray-300 text-gray-900 text-ms  px-4 py-1 block w-full  border border-zinc-200"
                    v-model="params.sir_id"
                />
                <!-- disabled -->
            </div>
        </div><!-- /End sir_id -->


        <!-- /End is_agent -->
        <div class="w-full p-2">
            <div class="text-ms text-right text-red-700" v-show="show.is_agent">
                {{ err.is_agent }}
            </div>
            <div class="flex">
                <button class="w-[140px] text-right z-10  px-4 py-1 text-ms text-emerald-600 border border-r-0 border-zinc-300 bg-zinc-200" type="button">
                    是否是代理
                </button>
                <select
                        v-model="params.is_agent"
                        class="w-11/12  text-ms focus:outline-none focus:text-gray-600 py-2 pl-2 pr-6 border border-zinc-200"
                    >
                    <option value="1" class="text-ms">
                        是
                    </option>
                    <option value="0" class="text-ms">
                        不是
                    </option>
                </select>
            </div>
        </div><!-- /End is_agent -->

    </s3-layer>
</template>

<script>
import axios from 'axios'
import { useI18n } from 'vue-i18n'
import { layer } from "vue3-layer"
import postPages from "@/assets/js/postPages"
import { toRaw } from '@vue/reactivity'

export default {
    data() {
        return {
            lang: '',
            title: '编辑管理帐号',
            visibleBox: false,
            params: [],
            show: {
                phone: false,
                is_agent: false,
            },
            err: {
                phone: '',
                is_agent: '',
            },
            api: {
                grade: [],
            }
        }
    },
    inject: ["fatherGetData"],
    mounted() {
        const { locale } = useI18n()
        this.lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh-cn'
        locale.value = this.lang
        return {}
    },
    methods: {
        getSelect()
        {
            // 获取供选择的数据项
            // 如何打包搜索参数？  &p=${p}
            this.searchParams = `&commodity_id=${this.lang}`
            const page = postPages(this.lang, localStorage.getItem('token'), '/api/x/get/all/commodity', this.ps, this.p, this.searchParams)
            // 将异步获取的数据导入 VM 数据
            page.data.then(res =>{
                const data = toRaw(res)
                data.data.grade.forEach((element) => {
                    if (1===element.level) element.name = '六级'
                    if (2===element.level) element.name = '五级'
                    if (3===element.level) element.name = '四级'
                    if (4===element.level) element.name = '三级'
                    if (5===element.level) element.name = '二级'
                    if (6===element.level) element.name = '一级'
                })
                this.api.grade = data.data.grade
            })
        },
        selectFile(event) {
            this.form.icon = event.target.files[0];
        },
        switchShow(params) {
            this.visibleBox = true
            this.params = params
            console.log( this.params )

            this.getSelect()
        },
        btnYes()
        {
            this.deoxidationTip()
            axios.post(
                '/api/x/set/user/info', 
                {
                    user_id: this.params.user_id,
                    phone: this.params.phone,
                    balance: this.params.balance,
                    frozen: this.params.frozen,
                    grade: this.params.grade,
                    status: this.params.status,
                    sir_id: this.params.sir_id,
                    is_agent: this.params.is_agent,
                },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        //   'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': this.lang,
                        'token': localStorage.getItem('token')
                    }
                }
            )
            .then((response) =>  {
                // console.log(response)
                if (0===response.data.code) {
                    this.visibleBox = false
                    layer.msg(response.data.msg)
                    // 创建成功，可能还要创建，所以先清空。
                    this.deoxidation()
                    // 创建成功，父组件要重新获取数据。
                    this.fatherGetData()
                } else {
                    const word = response.data.data.id
                    const err = 'msg.err' + word.charAt(0).toUpperCase() + word.slice(1)
                    const tip = 'show.err' + word.charAt(0).toUpperCase() + word.slice(1)
                    // 转成国际化 后端转提示语 前端转表单名称
                    this[err] = this.$t('filed.position.'+response.data.data.id) + ' ' + response.data.msg 
                    this[tip] = true
                }
            })
            .catch(function (error) {
                console.log(error)
            })
        },
        btnCancel()
        {
            this.visibleBox = false
        },
        // 提交后还原提交的表单信息
        deoxidation() {
            this.id = 0
        },
        // 提前时清空错误提示
        deoxidationTip() {

        }
    }
}
</script>

<style>

</style>