export default {
    side: {
        main: 'Nhà',
        trade: 'Quản lý thương mại',
        tradeOrder: 'Danh sách đơn hàng',
        tradeRecharge: 'Quản lý nạp tiền',
        tradeWithdraw: 'Quản lý rút tiền',
        tradeControl: 'Kiểm soát Thương mại',
        tradeAmount: 'Điều chỉnh số lượng',
        tradeStatistics: 'báo cáo thống kê',
        tradeReport: 'Báo cáo đại lý',
        tradeReportDaily: 'Báo cáo hàng ngày của đại lý',
        tradeReportMonthly: 'Báo cáo hàng tháng của đại lý',
        commodity: 'quản lý hàng hóa',
        commodityList: 'Danh sách hàng hóa',
        commoditySort: 'Phân loại hàng hóa',
        member: 'Quản lý thành viên',
        memberList: 'danh sách thành viên',
        memberSort: 'Cấp thành viên',
        memberLevel: 'Mức trung bình',
        content: 'Quản lý nội dung',
        contentNotice: 'Quản lý thông báo',
        contentText: 'Quản lý văn bản',
        contentAd: 'Băng chuyền trang chủ',
        contentLogo: 'LOGO',
        power: 'Quản lý quyền',
        powerList: 'Quyền truy cập',
        powerAdmin: 'người dùng hệ thống',
        systemAccount: 'Quản lý tài khoản hệ thống',
        system: 'quản lý hệ thống',
        systemMenu: 'Quản lý menu hệ thống',
        systemParameter: 'Cấu hình tham số hệ thống',
        systemLog: 'Nhật ký vận hành hệ thống'
    },
    user: {
        basicInformation: 'Thông tin cơ bản',
        securitySettings: 'Cài đặt bảo mật',
        compressedRelease: 'Bản phát hành nén',
        clearCache: 'Xóa bộ nhớ đệm',
        logOut: 'Đăng xuất',
    },
    footer: {
        homePage: 'Trang Chủ',
        product: 'Sản phẩm',
        mine: 'Của tôi',
        password: 'mật khẩu',
        confirm: 'Xác nhận mật khẩu',
    },
    filed: {
        position: {
            title : 'Chức danh tiếng Trung',
            title_vi : 'Chức danh Tiếng Việt',
            title_en : 'Chức danh tiếng Anh',
            username: 'tên tài khoản',
            password : 'mật khẩu',
            confirm : 'Xác nhận mật khẩu',
            phone: 'số điện thoại',
            sir_id: 'ID cấp trên',
            is_agent: 'Có phải là một đại lý?',
            grade: 'kiểu',
            bank_phone: 'Số điện thoại mở tài khoản',
            bank_id: 'Ngân hàng tiền gửi',
            bank_user: 'Chủ tài khoản',
            bank_card: 'Số thẻ ngân hàng',
            bank_addr: 'Địa chỉ mở tài khoản',
            number: 'Số thẻ ngân hàng',
            name: 'Người nhận thanh toán',
        },
        admin: {
            account: 'Quản lý tài khoản',
            position: 'Chức vụ',
            password: 'mật khẩu',
            passwordConfirm: 'Xác nhận mật khẩu',
        },
        commodity: {
            title : '职务名称 中文',
            title_vi : '职务名称 越南文',
            shop: '店铺名称 中文',
            shop_vi: '店铺名称 越南文',
            sort_id: '商品分类',
            price: '商品价格',
            icon: '商品图片',
        }
    }
}