
import axios from 'axios'

/**
 * 
 * @param {*} lang 语言
 * @param {*} token 令牌
 * @param {*} ps 每页显示多少条
 * @param {*} p 请求第几页的数据
 * @returns 
 */
export default function func(lang, token) {
    const data = axios.get(
        // `/api/x/get/position/powers?position_id=${position_id}`, 
        '/api/x/get/all/position',
        {
            headers: {
                // 'Content-Type': 'multipart/form-data'
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept-Language': lang,
                'token': token
            }
        }
    )
    .then((res) =>  {
        return res.data
    })
    .catch((err) => {
        console.log(err)
    })

  return { data }
}
