<template>
    <!-- 第一个页面：控制台首页-仪表盘 -->
    <div class="w-screen h-screen flex">
        <!-- Side bar 侧边栏 -->
        <!-- 侧边栏 w-[200px] 总固定宽度 200 px  -->
        <SideSar ref="refSideSar" /><!-- /End Side bar -->
        <!-- Main -->
        <div class="w-full h-full bg-gray-400">

            <!-- Main Header -->
            <MainHeader ref="refMainHeader" />
            <!--  /End Main Header -->

            <div class="h-[calc(100vh-80px)] p-1 bg-white">
                <div class="tabBox">
                    <div class="tabBoxSon tab1">
                        <span>商品总量</span>
                        <span>{{ info.iTotal }}</span>
                        <div>今日新增商品<span class="bold">{{ info.iCommodityToday }}</span></div>
                        <div>昨日新增商品<span class="pl-2">{{ info.iCommodityYesterday }}</span></div>
                    </div>
                </div>
                <div class="tabBox">
                    <div class="tabBoxSon tab2">
                        <span>用户总量</span>
                        <span>{{ info.iUserTotal }}</span>
                        <div>今日新增用户<span class="bold">{{ info.iUserToday }}</span></div>
                        <div>昨日新增用户<span class="pl-2">{{ info.iUserYesterday }}</span></div>
                    </div>
                </div>
                <div class="tabBox">
                    <div class="tabBoxSon tab3">
                        <span>订单总量</span>
                        <span>{{ info.iOrderTotal }}</span>
                        <div>今日新增订单<span class="bold">{{ info.iOrderToday }}</span></div>
                        <div>昨日新增订单<span class="pl-2">{{ info.iOrderYesterday }}</span></div>
                    </div>
                </div>
                <div class="tabBox">
                    <div class="tabBoxSon tab4">
                        <span>订单总金额</span>
                        <span>{{ info.sumOrderTotal }}</span>
                        <div>今日新增订单总金额<span class="bold">{{ info.sumOrderToday }}</span></div>
                        <div>昨日新增订单总金额<span class="pl-2">{{ info.sumOrderYesterday }}</span></div>
                    </div>
                </div>
                <div class="tabBox">
                    <div class="tabBoxSon tab5">
                        <span>用户充值</span>
                        <span>{{ info.sumRechargeTotal }}</span>
                        <div>今日新增充值<span class="bold">{{ info.sumRechargeToday }}</span></div>
                        <div>昨日新增充值<span class="pl-2">{{ info.sumRechargeYesterday }}</span></div>
                    </div>
                </div>
                <div class="tabBox">
                    <div class="tabBoxSon tab6">
                        <span>用户提现</span>
                        <span>{{ info.sumWithdrawTotal }}</span>
                        <div>今日新增提现<span class="bold">{{ info.sumWithdrawToday }}</span></div>
                        <div>昨日新增提现<span class="pl-2">{{ info.sumWithdrawYesterday }}</span></div>
                    </div>
                </div>
                <div class="tabBox">
                    <div class="tabBoxSon tab7">
                        <span>抢单佣金</span>
                        <span>{{ info.sumCommissionTotal }}</span>
                        <div>今日新增佣金<span class="bold">{{ info.sumOrderCommissionToday }}</span></div>
                        <div>昨日新增佣金<span class="pl-2">{{ info.sumOrderCommissionYesterday }}</span></div>
                    </div>
                </div>
                <div class="tabBox">
                    <div class="tabBoxSon tab8">
                        <span>用户总余额</span>
                        <span>{{ info.sumUser }}</span>
                        <div><span class="bold"></span></div>
                        <div><span class="pl-2"></span></div>
                    </div>
                </div>
                <div class="tabBox">
                    <div class="tabBoxSon tab1">
                        <span>今日</span>
                        <span>待派单 {{ order }} 笔</span>
                        <div>首次充值金额<span class="bold">{{ info.iTodayFistRecharge }}</span></div>
                        <div>首次充值用户<span class="pl-2">{{ info.iTodayFistRr }}</span></div>
                    </div>
                </div>
            </div>
            
            <!-- Main Footer -->
            <MainFooter />
            <!--  /End Main Footer -->

        </div><!-- /End Main -->
    </div>
</template>

<script>
import SideSar from "../../components/SideSar.vue"
import MainHeader from "../../components/MainHeader.vue"
import MainFooter from "../../components/MainFooter.vue"
import postPages from "@/assets/js/postPages"
export default {
    name: "首页",
    components: {
        SideSar,
        MainHeader,
        MainFooter,
    },
    data() {
        return {
            showDropDown: false,
            showSideBar: true,
            info: {
                iTotal: '',
                iUserTotal: '',
                iOrderTotal: '',
                sumOrderTotal: '',
                sumWithdrawTotal: '',
                sumRechargeTotal: '',
                sumCommissionTotal: '',
                sumUser: '',
                iCommodityToday: '',
                iCommodityYesterday: '',
                iOrderToday: '',
                iOrderYesterday: '',
                iUserToday: '',
                iUserYesterday: '',
                sumOrderToday: '',
                sumOrderYesterday: '',
                sumRechargeToday: '',
                sumRechargeYesterday: '',
                sumWithdrawToday: '',
                sumWithdrawYesterday: '',
                sumOrderCommissionToday: '',
                sumOrderCommissionYesterday: '',
            },
            order: ''
        }
    },
    // ##  再授权给子孙
    // ##  子孙调用的别名为 fatherGetToggleSideBar
    provide() {
        return {
            fatherGetToggleSideBar: this.toggleSideBar,
        }
    },
    mounted() {
        this.getData()
        return {}
    },
    methods: {
        // hide show side bar 
        toggleSideBar() {
            // console.warn('侧边栏开关')
            // 如果 this.showSideBar 是真就取假，如果 this.showSideBar 是假就取真
            // this.showSideBar = !this.showSideBar
            // 这个方法已经移到子组件中了，直接调用子组件来完成。前提是在模板中使用组件时，在组件中，要先为组件声明 ref 才可以使用，比如这里声明为  refSideSar 通过 refSideSar 直接调用子组件中的方法。
            this.$refs.refSideSar.switchSideBar()
        },
        // toggle user
        toggleDrop() {
            console.info('用户快捷菜单')
            // 如果 this.showDropDown 是真就取假，如果 this.showDropDown 是假就取真
            this.showDropDown = !this.showDropDown
        },
        getData()
        {
            // positionPage 方法是通过 JS 文件导入的
            const page = postPages(this.lang, localStorage.getItem('token'), '/api/x/get/info', this.ps, this.p, this.searchParams)
            // 将异步获取的数据导入 VM 数据
            page.data.then(res =>{
                if (res && 0===res.code) {
                    this.info = res.data.info
                    this.order = res.data.order
                } 
                if (res && 1<=res.code) {
                    layer.msg(res.msg)
                }
                
            })
        },
    } // End methods{...}
}
</script>

<style>
    /** 结构 */
    .tabBox {
        @apply w-1/4 h-[160px] inline-block p-1;
    }
    .tabBoxSon {
        @apply w-auto h-full p-5 text-white text-left ;
    }
    /** 样式 */
    .tabBoxSon span {
        @apply text-sm text-left;
    }
    .tabBoxSon span:nth-child(2) {
        @apply text-2xl text-left block font-bold;
    }
    .tabBoxSon div {
        @apply text-sm;
    }
    .tabBoxSon .bold {
        @apply text-xl pl-2 font-bold;
    }

    /** 样式 */
    .tab1 {
        background:linear-gradient(-125deg,#57bdbf,#2f9de2)
    }
    .tab2 {
        background:linear-gradient(-125deg,#ff7d7d,#fb2c95)
    }
    .tab3 {
        background:linear-gradient(-113deg,#c543d8,#925cc3)
    }
    .tab4 {
        background: linear-gradient(-113deg,#8e8cb3,#2219d0);
    }
    .tab5 {
        background:linear-gradient(-141deg,#ecca1b,#f39526)
    }
    .tab6 {
        background: linear-gradient(-141deg,#ec4b1b,#f39526);
    }
    .tab7 {
        background: linear-gradient(-141deg,#963064,#8a0920);
    }
    .tab8 {
        background: linear-gradient(-141deg,#2f3331,#565115);
    }
    
</style>